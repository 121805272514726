import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import RegisterForm from "./RegisterForm";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { setUser } from "../../../shared/services/actions/user-action";
import { bindActionCreators } from "redux";
import { get } from "../../../shared/services/rest-service";
import SubHeader from "../../sub-header/sub-header";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import "./Register.scss";
import { ToastsStore } from "react-toasts";
import "react-loadingmask/dist/react-loadingmask.css";
import { confirmAlert } from "react-confirm-alert";

var firstCall = true;
class RegisterUser extends React.Component {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      displayMenu: false,
      displayCol: "col-lg-9",
      userDetail: {},
      tempUserDetails: {},
      personalDetails: {}
    };
    if (
      this.props.location.pathname === "/" &&
      localStorage.getItem("isLoggedIn") == "true"
    ) {
      this.getUserDetails();
    }
    if (localStorage.getItem("token")) {
      localStorage.setItem("isLoggedIn", true);
    } else {
      localStorage.setItem("isLoggedIn", false);
    }
  }

  async getUserDetails() {
    let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));
    const getUserDetail = await get(
      "users/getUserDetails/" + currentUserDetails.id,
      {}
    );
    const data = this.props;
    this.props.data["personalDetails"] = JSON.parse(
      JSON.stringify(getUserDetail.result)
    );
    this.props.data.personalDetails.isEmail = true;
    this.props.data.personalDetails.path = "/";

    this.setState({ personalDetails: this.props.data.personalDetails });
  }
  test = async ({ data }) => {
    debugger;
    this.props.data.personalDetails = data;
    this.state.tempUserDetails = data;
  };
  submitFormData = async ({ data }) => {
    debugger;

    firstCall = !firstCall;
    let getReferrerName;
    if (window.location.search) {
      getReferrerName = window.location.search.slice(1).split("=")[1];
    }
    if (this.props.location.pathname === "/") {
      this.props.setUser(data);
      if (firstCall) {
        if (!localStorage.getItem("token")) {
          const checkUserExit = await get(
            "checkUserExist/" + data.username,
            {}
          );

          if (checkUserExit.msg) {
            if (getReferrerName !== undefined) {
              const getStatusReferrer = await get(
                "checkReferrer?referrer=" + getReferrerName,
                {}
              );
              if (JSON.stringify(getStatusReferrer.msg).includes("valid")) {
                localStorage.setItem("referrer", getReferrerName);
                this.props.history.push("company-details");
                this.validateData(data);
              } else {
                ToastsStore.success(JSON.stringify(getStatusReferrer.msg));
              }
            } else {
              delete data.referrer;
              this.props.history.push("company-details");
              this.validateData(data);
            }
          } else {
            confirmAlert({
              title: "Confirmation",
              message: "Username already exists. Do you want to login?",

              buttons: [
                {
                  label: "Yes",
                  onClick: async () => {
                    this.props.history.push("/login");
                  }
                },
                {
                  label: "No"
                }
              ]
            });
            //
          }
        }
      } else {
        delete data.referrer;
        this.props.history.push("company-details");
        this.validateData(data);
        // this.props.location.tabdsbaled("aaa");
      }
    } else {
      Object.assign(
        this.props.personalDetails,
        this.props.data.personalDetails
      );
      this.props.setUser(data);
      let selectedTab = 2;
      this.props.nextTabs(selectedTab);
    }
  };

  async componentDidMount() {
    let companyId;

    if (window.location.search) {
      companyId = window.location.search.slice(1).split("=")[1];
    }
    localStorage.setItem("CompanyId", companyId);
    debugger;
    if (this.props.location.pathname === "/") {
      this.setState({ displayMenu: true });
      this.setState({ displayCol: "col-lg-9" });
    } else {
      debugger;
      this.setState({ displayMenu: false });
      this.setState({ displayCol: "col-lg-12" });

      if (this.props.location.state) {
        const getUserDetail = await get(
          "users/getUserDetails/" + this.props.location.state.user_id,
          {}
        );
        if (
          getUserDetail.result.role !== "ROLE_USER" &&
          getUserDetail.result.role !== "ROLE_REVIEWER"
        ) {
          this.props.personalDetails.isEdit = true;
        }
        // if(getUserDetail.result.role !== "ROLE_USER"){

        //   this.props.personalDetails.isEdit = true
        // }
        if (getUserDetail.result.role == "ROLE_USER") {
          if (this.props.status == "APPROVED") {
            this.props.personalDetails.isEdit = true;
          }
        }
      }
    }
  }

  // handleCustomEvent = (event) => {
  //   if (event.type === "continue") {
  // 	this.validateData(event.data);
  //   }
  // };

  validateData = async data => {
    debugger;
    const tempData = JSON.parse(JSON.stringify(this.state.tempUserDetails));
    const dataTovalidate = this.props.data;
    if (localStorage.getItem("CompanyId") != "undefined") {
      tempData.referrer = localStorage.getItem("CompanyId");
    }
    dataTovalidate["personalDetails"] = tempData;
    // if (!localStorage.getItem('token')) {
    //   try {
    //    await register(dataTovalidate.userDetails);
    //   } catch (error) {
    //     console.log(error);
    //     alert('Some Error');
    //   }
    // }
    try {
      const dataToSave = new FormData();
      dataToSave.append("data", JSON.stringify(dataTovalidate));
      dataToSave.append("isValid", "true");
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <section className="clearfix">
        {this.state.displayMenu ? <SubHeader /> : null}
        <div className="row no-gutters align-items-center">
          <div className={`mainbox ${this.state.displayCol}`}>
            <div className="mg-lft-100 pad-top-30 headline">
              <h3 className="head-tittle pad-top-30">
                {t("Personal Details")}
              </h3>
            </div>
            <div className="register-form g-pa-10 g-mx-30--xl">
              {this.props.location.pathname === "/" ? (
                <Form
                  submission={{
                    data: this.props.data.personalDetails
                      ? this.props.data.personalDetails
                      : ""
                  }}
                  form={RegisterForm(this.state.personalDetails)}
                  onChange={event => this.test(event)}
                  onSubmit={event => this.submitFormData(event)}
                  onCustomEvent={this.handleCustomEvent}
                  options={{
                    language: localStorage.getItem("language")
                      ? localStorage.getItem("language")
                      : "",
                    languages: ["en", "spa"],
                    i18n: {
                      en: languageEN.en.translation,
                      spa: languageSPA.spa.translation
                    }
                  }}
                />
              ) : (
                <Form
                  submission={{
                    data: this.props.personalDetails
                      ? this.props.personalDetails
                      : ""
                  }}
                  form={RegisterForm(this.props)}
                  onSubmit={this.submitFormData}
                  onCustomEvent={this.handleCustomEvent}
                  options={{
                    language: localStorage.getItem("language")
                      ? localStorage.getItem("language")
                      : "",
                    languages: ["en", "spa"],
                    i18n: {
                      en: languageEN.en.translation,
                      spa: languageSPA.spa.translation
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProops = dispatch => {
  return bindActionCreators({ setUser: setUser }, dispatch);
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProops
  )(withRouter(RegisterUser))
);
