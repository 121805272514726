import React from "react";
import { Form } from "react-formio";
import PatternForm from "./patternForm";
import ChangePasswordForm from "./changePasswordForm";
import { post } from "../../shared/services/rest-service";
import languageEN from "../../translate/languages/english";
import languageSPA from "../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import { ToastsStore } from "react-toasts";
import { confirmAlert } from "react-confirm-alert";

class ChangePassword extends React.Component {
  onChangePassword = async ({ data }) => {
    if (data.newPassword === data.confirmPassword) {
      confirmAlert({
        title: "Confirm to Change Password",
        message: "Are you sure to Change Password of this User.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const test = await post("users/changePassword", {
                oldPassword: data.oldPassword,
                password: data.newPassword,
                confirmPassword: data.confirmPassword
              });
              ToastsStore.success(test.msg);
              localStorage.removeItem("token");
              localStorage.setItem("isLoggedIn", false);

              this.setState({ loggedIn: false });
              localStorage.removeItem("currentUser");
              this.props.history.push("/login");
            }
          },
          {
            label: "No"
          }
        ]
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <section className="clearfix g-pa-30">
        <div className="row no-gutters align-items-center">
          <div className="mainbox col-lg-9 mt-2">
            <div className="mg-lft-100 pad-top-30 headline">
              <h3 className="head-tittle pad-top-30">{t("Change password")}</h3>
            </div>
            <div className="register-form g-pa-10 g-mx-30--xl">
              <div style={{ width: " 50%", float: "left", padding: "20px" }}>
                <Form
                  form={ChangePasswordForm(this.props)}
                  onSubmit={this.onChangePassword}
                  options={{
                    language: localStorage.getItem("language")
                      ? localStorage.getItem("language")
                      : "",
                    languages: ["en", "spa"],
                    i18n: {
                      en: languageEN.en.translation,
                      spa: languageSPA.spa.translation
                    }
                  }}
                />
              </div>
              <div style={{ width: " 50%", float: "left", padding: "20px" }}>
                <Form form={PatternForm(this.props)} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ChangePassword);
