import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
import "../assets/vendor/bootstrap/bootstrap.min.css";
import "../assets/vendor/icon-awesome/css/font-awesome.min.css";
import "../assets/vendor/icon-line/css/simple-line-icons.css";
import "../assets/vendor/icon-etlinefont/style.css";
import "../assets/vendor/icon-line-pro/style.css";
import "../assets/vendor/icon-hs/style.css";
import "../assets/vendor/dzsparallaxer/dzsparallaxer.css";
import "../assets/vendor/dzsparallaxer/dzsscroller/scroller.css";
import "../assets/vendor/dzsparallaxer/advancedscroller/plugin.css";
import "../assets/vendor/slick-carousel/slick/slick.css";
import "../assets/vendor/animate.css";
import "../assets/vendor/hs-megamenu/src/hs.megamenu.css";
import "../assets/vendor/hamburgers/hamburgers.min.css";

import "../assets/css/unify-core.css";
import "../assets/css/unify-components.css";
import "../assets/css/unify-globals.css";
import "../assets/css/custom.css";

import Header from "./modules/shared/app-header/Header";
import Footer from "./modules/shared/app-footer/Footer";
import Login from "./modules/user/login/page/Login";
import Register from "./modules/user/register/page/Register";
import UserList from "./modules/user/user-list/user-list";
import CreateUser from "./modules/user/create-user/create-user";
import Welcome from "./modules/user/welcome/welcome";
import CompanyDetails from "./modules/loan/company-details/page/CompanyDetails";
import LoanDetails from "./modules/loan/loan-details/page/LoanDetails";
import FinancilDetails from "./modules/loan/financial-details/page/FinancialDetails";
import CreditReference from "./modules/loan/credit-reference/components/CreditReference";
import LedgerExperience from "./modules/loan/ledger-experience/page/LedgerExperience";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import ResetPassword from "./modules/user/reset-password/reset-password";
import LoanList from "./modules/loan/loan-details/page/loan-list/loan-list";
import LoanSummary from "./modules/loan/loan-details/page/loan-summary";
import ChangePassword from "./modules/user/change-password/changePassword";
import ReactDOM from "react-dom";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      if (localStorage.getItem("token")) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
    }
    if (localStorage.getItem("token")) {
      this.setTimeout();
    }
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 14 * 60000);

    this.logoutTimeout = setTimeout(this.logout, 15 * 60000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    alert("You will be logged out automatically in 1 minute.");
  }

  logout() {
    localStorage.clear();
    window.open("/login", "_self");
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/users" component={UserList} />
            <Route path="/user" component={CreateUser} />
            <Route path="/user/:id" component={CreateUser} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/company-details" component={CompanyDetails} />
            <Route path="/loan-details" component={LoanDetails} />
            <Route path="/financial-details" component={FinancilDetails} />
            <Route path="/credit-reference" component={CreditReference} />
            <Route path="/ledger-experience" component={LedgerExperience} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/loans" component={LoanList} />
            <Route path="/loan-summary/:id" component={LoanSummary} />
            <Route path="/change-password" component={ChangePassword} />
          </Switch>
          <Footer />
        </Router>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
      </div>
    );
  }
}

export default App;
