export const companyData = [
  {
    id: 1,
    company: "comapny1",
    src: "https://expcdn.co.uk/www/images/experian_full_colour.svg"
  },
  {
    id: 2,
    company: "company2",
    src:
      "https://www.pmloans.co.uk/wp-content/themes/adm/images/new-logo-text.svg"
  },
  {
    id: 3,
    company: "company3",
    src:
      "https://www.everyday-loans.co.uk/assets/images/everyday-loans-logo.svg"
  }
];
