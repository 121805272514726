import React from "react";
import { Form } from "react-formio";
import patternForm from "./patternForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { post } from "../../shared/services/rest-service";
import languageEN from "../../translate/languages/english";
import languageSPA from "../../translate/languages/spanish";
import { withTranslation } from "react-i18next";

class ResetPassword extends React.Component {
  onResetPassword = async ({ data }) => {
    if (data.newPassword === data.confirmPassword) {
      await post(`resetPassword${this.props.location.search}`, {
        password: data.newPassword
      });
      window.open("/login", "_self");

      localStorage.removeItem("token");
      localStorage.setItem("isLoggedIn", false);

      this.setState({ loggedIn: false });

      localStorage.removeItem("currentUser");
    }
  };

  render() {
    const { t } = this.props;
    return (
      <section className="clearfix g-pa-30">
        <div className="row no-gutters align-items-center">
          <div className="mainbox col-lg-9 mt-2">
            <div className="mg-lft-100 pad-top-30 headline">
              <h3 className="head-tittle pad-top-30">{t("Change password")}</h3>
            </div>
            <div className="register-form g-pa-10 g-mx-30--xl">
              <div style={{ width: " 50%", float: "left", padding: "20px" }}>
                <Form
                  form={ResetPasswordForm(this.props)}
                  onSubmit={this.onResetPassword}
                  options={{
                    language: localStorage.getItem("language")
                      ? localStorage.getItem("language")
                      : "",
                    languages: ["en", "spa"],
                    i18n: {
                      en: languageEN.en.translation,
                      spa: languageSPA.spa.translation
                    }
                  }}
                />
              </div>
              <div style={{ width: " 50%", float: "left", padding: "20px" }}>
                <Form form={patternForm(this.props)} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ResetPassword);
