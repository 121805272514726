import { useTranslation } from 'react-i18next';

const SummaryForm = (summaryStatus) => {
//  let { t } = useTranslation();
  return {
        "_id": "5e9effb284e04dced6874358",
        "type": "form",
        "tags": [],
        "owner": "5e5687252ea8e6f750d0f9f9",
        "components": [
            {
                "clearOnHide": false,
                "label": "Columns",
                "input": false,
                "tableView": false,
                "key": "columns1",
                "columns": [
                    {
                        "components": [
                            {
                                "autofocus": false,
                                "input": true,
                                "tableView": true,
                                "label": "Status",
                                "key": "status",
                                "placeholder": "",
                                "data": {
                                    "values": summaryStatus,
                                    "json": "",
                                    "url": "",
                                    "resource": "",
                                    "custom": ""
                                },
                                "dataSrc": "values",
                                "valueProperty": "",
                                "defaultValue": "",
                                "refreshOn": "",
                                "filter": "",
                                "authenticate": false,
                                "template": "<span>{{ item.label }}</span>",
                                "multiple": false,
                                "protected": false,
                                "unique": false,
                                "persistent": true,
                                "hidden": false,
                                "clearOnHide": true,
                                "validate": {
                                    "required": true
                                },
                                "type": "select",
                                "labelPosition": "top",
                                "tags": [],
                                "conditional": {
                                    "show": "",
                                    "when": null,
                                    "eq": ""
                                },
                                "properties": {},
                                "lockKey": true
                            }
                        ],
                        "width": 8,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                    },  
                ],
                "type": "columns",
                "hideLabel": true,
                "tags": [],
                "conditional": {
                    "show": "",
                    "when": null,
                    "eq": ""
                },
                "properties": {}
            },
            {
                "clearOnHide": false,
                "label": "Columns",
                "input": false,
                "tableView": false,
                "key": "columns2",
                "columns": [
                    {
                        "components": [
                            {
                                "autofocus": false,
                                "input": true,
                                "tableView": true,
                                "inputType": "text",
                                "inputMask": "",
                                "label": "Comment",
                                "key": "comment",
                                "placeholder": "",
                                "prefix": "",
                                "suffix": "",
                                "multiple": false,
                                "defaultValue": "",
                                "protected": false,
                                "unique": false,
                                "persistent": true,
                                "hidden": false,
                                "clearOnHide": true,
                                "spellcheck": true,
                                "validate": {
                                    "required": true,
                                    "minLength": "",
                                    "maxLength": "",
                                    "pattern": "",
                                    "custom": "",
                                    "customPrivate": false
                                },
                                "conditional": {
                                    "show": "",
                                    "when": null,
                                    "eq": ""
                                },
                                "type": "textarea",
                                "labelPosition": "top",
                                "inputFormat": "plain",
                                "tags": [],
                                "properties": {},
                                "lockKey": true
                            }
                        ],
                        "width": 8,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                    },
                ],
                "type": "columns",
                "hideLabel": true,
                "tags": [],
                "conditional": {
                    "show": "",
                    "when": null,
                    "eq": ""
                },
                "properties": {}
            },

            {
                "autofocus": false,
                "input": true,
                "label": "Submit",
                "tableView": false,
                "key": "submit",
                "size": "md",
                "leftIcon": "",
                "rightIcon": "",
                "block": false,
                "action": "submit",
                "disableOnInvalid": false,
                "theme": "success",
                "type": "button",
                "tags": [],
                "conditional": {
                  "show": "",
                  "when": null,
                  "eq": ""
                },
                "properties": {}
            }
        ],
        "controller": "",
        "revisions": "",
        "_vid": 0,
        "title": "Loan",
        "display": "form"
    }
}

export default SummaryForm;