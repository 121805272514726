import { post } from "./rest-service";

const getData = async (data) => {
    delete data["submit"];
    return await validateDetails(data);
}

const validateDetails = async (data) => {
    const res = await post('register', data);
    setToken(res);
    return res;
}

const setToken = (res) => {
    localStorage.setItem('token', res.token);
}

export default getData;