import React from "react";
import LoanSummaryOverview from "./summary";
import LoanEligibility from "./credit-score/loan-eligibility";
import FileUploadStatus from "./credit-score/file-upload-status";
import EligibilityStatus from "./credit-score/eligibility-status";
import RegisterUser from "../../../../user/register/page/Register";
import LoanDetails from "../LoanDetails";
import FinancialSummary from "./financial-details/financial-summary";
import FinancialRatios from "./financial-details/financial-ratios";
import FinancialDetails from "../../../financial-details/page/FinancialDetails";
import CompanyDetails from "../../../company-details/page/CompanyDetails";
import CreditReferenceFileUpload from "./credit-reference";
import CreditReference from "../../../credit-reference/components/CreditReference";
import AuditHistory from "./audit-history";
import { connect } from "react-redux";
import { get } from "../../../../shared/services/rest-service";
import "./loan-summary.scss";

var t1 = {};
class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected || 0,
      displayMenu: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.selected });
  }

  handleChange(index) {
    this.setState({ selected: index });
  }

  render() {
    return (
      <div>
        <ul className="inline">
          {this.props.children.map((elem, index) => {
            let style = index === this.state.selected ? "selected" : "";
            return (
              <li
                className={style}
                key={index}
                onClick={this.handleChange.bind(this, index)}
              >
                {elem.props.title}
              </li>
            );
          })}
        </ul>
        <div className="tab">{this.props.children[this.state.selected]}</div>
      </div>
    );
  }
}

class Panel extends React.PureComponent {
  render() {
    return <div>{this.props.children}</div>;
  }
}

class LoanSummary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loanDetails: {},
      tabSelected: 0,
      selectedRegTab: 0,
      status: "",
      t1: {},
      loanId: ""
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      debugger;
      var t1 = {};
      var loanID;
      if (this.props.loan == undefined) {
        loanID = localStorage.getItem("LoanId");
      } else {
        loanID = this.props.loan.loan_id;
      }
      const getLoanDetails = await get(`loan/getLoanDetails/${loanID}`, {});
      this.setState({
        loanDetails: getLoanDetails,
        status: getLoanDetails.loanDetails.status
      });

      if (
        getLoanDetails.loanDetails.termId == 1 &&
        getLoanDetails.loanDetails.amount < 300000
      ) {
        debugger;

        this.setState({ t1: { hideBank1: true, hideTrade22: true } });
      } else if (
        getLoanDetails.loanDetails.termId == 1 &&
        getLoanDetails.loanDetails.amount >= 300001 &&
        getLoanDetails.loanDetails.amount <= 1000000
      ) {
        this.setState({
          t1: { hideBank1: true, hideTrade22: false, hideTrade11: false }
        });
      } else if (
        getLoanDetails.loanDetails.termId == 1 &&
        getLoanDetails.loanDetails.amount >= 1000001 &&
        getLoanDetails.loanDetails.amount <= 10000000
      ) {
        this.setState({
          t1: { hideBank1: false, hideTrade22: false, hideTrade11: false }
        });
      } else if (getLoanDetails.loanDetails.termId != 1) {
        if (getLoanDetails.loanDetails.amount >= 500000) {
          this.setState({
            t1: { hideTrade22: true, hideTrade11: true, hidePersonal: true }
          });
        } else {
          this.setState({
            t1: { hideTrade22: true, hideTrade11: true }
          });
        }
      }
    }
  }

  componentDidUpdate() {
    this.renderTabs(this.state.tabSelected);
  }

  nextTabs(data) {
    this.setState({ tabSelected: data });
  }

  renderTabs(tabSelected) {
    return (
      <div className="loan-summary-tabs mt-5">
        <Tabs selected={tabSelected}>
          <Panel title="Credit Score">
            <LoanEligibility loan={this.props.location.state} />
            <FileUploadStatus loan={this.props.location.state} />
            <EligibilityStatus loan={this.props.location.state} />
          </Panel>
          <Panel title="Personal Details">
            <div className="detail-content">
              <RegisterUser
                personalDetails={this.state.loanDetails.personalDetails}
                loan={this.props.location.state}
                status={this.state.status}
                nextTabs={this.nextTabs.bind(this)}
              />
            </div>
          </Panel>
          <Panel title="Company Details">
            <div className="detail-content">
              <CompanyDetails
                companyDetails={this.state.loanDetails.companyDetails}
                status={this.state.status}
                nextTabs={this.nextTabs.bind(this)}
              />
            </div>
          </Panel>
          <Panel title="Loan Details">
            <div className="detail-content">
              <LoanDetails
                loanDetails={this.state.loanDetails.loanDetails}
                status={this.state.status}
                nextTabs={this.nextTabs.bind(this)}
              />
            </div>
          </Panel>
          <Panel title="Financial Details">
            <div className="detail-content">
              <FinancialSummary loan={this.props.location.state} />
              <FinancialRatios loan={this.props.location.state} />
              <FinancialDetails
                financialDetails={this.state.loanDetails}
                status={this.state.status}
                nextTabs={this.nextTabs.bind(this)}
              />
            </div>
          </Panel>
          <Panel title="Credit References">
            <div className="detail-content">
              <CreditReferenceFileUpload
                fileStatus={this.state.loanDetails.creditReferenceDetails}
                loanDetails={this.state.loanDetails.loanDetails}
              />
              <CreditReference
                creditReferenceDetails={this.state.loanDetails}
                status={this.state.status}
                tradeReference1={this.state.t1}
                nextTabs={this.nextTabs.bind(this)}
              />
            </div>
          </Panel>
          <Panel title="Audit History">
            <div className="detail-content">
              <AuditHistory auditReport={this.state.loanDetails.AuditHistory} />
            </div>
          </Panel>
        </Tabs>
      </div>
    );
  }

  render() {
    return (
      <div className="mt-5">
        <div className="container">
          <div className="summary-overview">
            <LoanSummaryOverview loan={this.props.location.state} />
          </div>
          {this.renderTabs(this.state.tabSelected)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

export default connect(
  mapStateToProps,
  null
)(LoanSummary);
