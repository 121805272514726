import { get, post } from "../../shared/services/rest-service";
import { lte } from "lodash-es";

export const getCreditTerm = async () => {
  const data = await get("taxonomy/COMPANY_CREDIT_TYPE");
  return data;
};

// export const getLoanDetails = async () => {
//     const data = await get('loan/getDetails');
//     return data;
// }

export const getLoans = async (
  offset = 0,
  limit = 10,
  id = "",
  borrower = "",
  amount = "",
  companyName = "",
  product = "",
  referrer = "",
  approvalStatus = ""
) => {
  let response = await get(
    `loan/getAllLoans?pagesize=${limit}&pagenumber=${offset}&id=${id}&borrower=${borrower}&loanAmount=${amount}&companyName=${companyName}&productName=${product}&referrer=${referrer}&approvalStatus=${approvalStatus}`
  );
  return response;
};

export const getLoanStatus = async () => {
  let response = await get(`users/getLoanStatus`);
  return response;
};
// Loan Summary service

export const getReviewerSummary = async () => {
  const data = await get("taxonomy/REVIEWER");
  return data;
};

export const getApproverSummary = async () => {
  const data = await get("taxonomy/APPROVER");
  return data;
};

export const getLoanDetails = async loan_id => {
  const data = await get(`loan/getLoanDetails/${loan_id}`);
  return data;
};

export const updateReviewerLoanStatus = async (status, comments) => {
  const data = await post(`loan/updateLoanStatus?status=${status}`, comments);
  return data;
};

export const updateApproverLoanStatus = async (status, comments) => {
  const data = await post(`loan/updateLoanStatus?status=${status}`, comments);
  return data;
};
