const languageEN = {
    en : {
        translation : {
            //User management
            Login : 'Login',
            "All Rights Reserved": "All Rights Reserved.",
            "Privacy Policy": "Privacy Policy",
            "Terms of Service": "Terms of Service",
            Username: 'Username',
            Password: 'Password',
            "Log In To Your Account": "Log In To Your Account",
            "Personal Details": "Personal Details",
            "First Name": "First Name",
            "Last Name": "Last Name",
            "E-Mail": "E-Mail",
            "Phone Number": "Phone Number",
            Continue: "Continue",
            "Create User": "Create User",
            "Update User": "Update User",
            Role: "Role",
            "Type to search": "Type to search",
            "Admin": "Admin",
            "Reviewer": "Reviewer",
            "Approver": "Approver",
            "User": "User",
            Referrer: "Referrer",
            "Submit": "Submit",
            Logout: "Logout",
            "Stay signed in": "Stay signed in",
            "Forget your Password": "Forget your Password ?",
            "no worries": "No worries",
            "click here": "click here",
            "to reset your password": "to reset your password.",
            "User lists": "User lists",
            "User management": "User management",
            Status: "Status",
            Filter: "Filter",
            Name: "Name",
            Action: "Action",
            "Change password": "Change password",
            "New password": "New password",
            "Confirm password": "Confirm password",

            //Company details
            "Buyer Company": "Buyer Company",
            "Supplier Credit": "Supplier Credit",
            "Supplier is the company supplying goods and/or services and may request credit for its buyers with the required information": "Supplier is the company supplying goods and/or services and may request credit for its buyers with the required information.",
            "Buyer Credit": "Buyer Credit",
            "Buyer is for a company that is purchasing goods and/or services which will be receiving that credit and information is required on the buyer": "Buyer is for a company that is purchasing goods and/or services which will be receiving that credit and information is required on the buyer.",
            "Credit Term": "Credit Term:",
            "Select one": "Select one",
            "Company Name": "Company Name",
            "Authorized Person": "Authorized Person",
            "Contact Email": "Contact Email",
            "Contact Phone": "Contact Phone",
            "Address": "Address",
            "Incorporated": "Incorporated",
            "Financial Year End": "Financial Year End",
            "General Lines Of Business": "General Lines Of Business",
            "Back": "Back",
            "Save For Later": "Save For Later",

            //Loan details
            "Loan Details": "Loan Details",
            "Supplier is providing the product being financed": "Supplier is providing the product being financed.",
            "Buyer is purchasing the product being financed": "Buyer is purchasing the product being financed.",
            "Product Type": "Product Type",
            "Purchased Amount": "Purchased Amount",
            "Buyer Country": "Buyer Country",
            "Seller Country": "Seller Country",
            "Loan Amount": "Loan Amount",
            "Loan Period": "Loan Period",
            "Product requesting to purchase": "Product requesting to purchase",

            //Financial details
            "Financial Details": "Financial Details",
            "Current Financial Year": "Current Financial Year",
            "Company Accounts": "Company Accounts",
            "Interim": "Interim",
            "Upload a File": "Upload a File",
            "Operating Profit": "Operating Profit",
            "Net Income": "Net Income",
            "Net Worth": "Net Worth",
            "Total Liabilities": "Total Liabilities",
            "Debt Service": "Debt Service",
            "Net Cash from Operations": "Net Cash from Operations",
            "Financial Year": "Financial Year",
            "Last Financial Year": "Last Financial Year",
            "2 Year Ago Financial Year": "2 Year Ago Financial Year",

            //Credit reference
            "Credit Reference": "Credit Reference",
            "Bank Reference": "Bank Reference",
            "Company Credit Report": "Company Credit Report",
            "Upload File": "Upload File",
            "Name of company giving reference": "Name of company giving reference",
            "Years of experience with company": "Years of experience with company",
            "Estimated annual sales to the company": "Estimated annual sales to the company",
            "Highest credit within the past 12 months": "Highest credit within the past 12 months",
            "Current Outstanding Balance": "Current Outstanding Balance",
            "Credit Relationship": "Credit Relationship",
            "Excellent": "Excellent",

            //Ledger experience
            "Ledger Experience": "Ledger Experience",
            "Have you ever purchased from the supplier on credit terms": "Have you ever purchased from the supplier on credit terms?",
            "Has the (supplier or buyer) ever been over 60 days past due over the last 2 years": "Has the (supplier or buyer) ever been over 60 days past due over the last 2 years?",
            "Max Credit Outstanding": "Max Credit Outstanding",
            "Credit Terms Granted": "Credit Terms Granted",
            "Credit Amount": "Credit Amount"
        }
    }
}

export default languageEN;