import React from "react";
import { get } from "../../../../../shared/services/rest-service";
import { LoaderLarge } from "../../../../loader";
class FileUploadStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileStatus: [],
      loading: true,
      loanId: ""
    };
  }

  async componentDidMount() {
    var t1 = {};
    var loanID;
    if (this.props.loan == undefined) {
      loanID = localStorage.getItem("LoanId");
    } else {
      loanID = this.props.loan.loan_id;
    }

    const getLoanDetails = await get(`loan/getLoanDetails/${loanID}`, {});
    if (getLoanDetails.CreditScore.FileStatus) {
      if (getLoanDetails.CreditScore.FileStatus.length >= 0) {
        this.setState({
          fileStatus: getLoanDetails.CreditScore.FileStatus,
          loading: false
        });
      }
    } else {
      this.setState({
        fileStatus: getLoanDetails.CreditScore.FileStatus,
        loading: false
      });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? (
          <LoaderLarge />
        ) : (
          <div className="file-upload-status">
            <h4 className="head-tittle mt-4">File Upload Status</h4>
            <p className="mt-2">
              Please provide the following files to complete the application
            </p>
            <hr className="mt-1" />
            <div className="file-upload-content">
              <ul className="list-group mb-3">
                {this.state.fileStatus.map(
                  (item, i) =>
                    item.groupBy !== "OR1" ? (
                      item.isPresent === true ? (
                        <li
                          className="list-group-item d-flex align-items-center"
                          key={i}
                        >
                          <span className="file-upload-bg-green">
                            <i className="icon-custom icon-bg-green icon-line icon-check"></i>
                          </span>
                          <p className="file-upload-name" key={i}>
                            {item.name}
                          </p>
                        </li>
                      ) : (
                        <li
                          className="list-group-item d-flex align-items-center"
                          key={i}
                        >
                          <span className="file-upload-bg-red">
                            <i className="icon-custom icon-bg-red icon-line icon-close"></i>
                          </span>
                          <p className="file-upload-name" key={i}>
                            {item.name}
                          </p>
                        </li>
                      )
                    ) : null
                  //</li>
                )}
              </ul>
            </div>

            {this.state.fileStatus.map((item, i) =>
              item.groupBy == "OR1" ? <p>OR</p> : null
            )}

            <div className="file-upload-content">
              <ul className="list-group mb-5">
                {this.state.fileStatus.map((item, i) =>
                  item.groupBy == "OR1" ? (
                    item.isPresent === true ? (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={i}
                      >
                        <span className="file-upload-bg-green">
                          <i className="icon-custom icon-bg-green icon-line icon-check"></i>
                        </span>
                        <p className="file-upload-name" key={i}>
                          {item.name}
                        </p>
                      </li>
                    ) : (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={i}
                      >
                        <span className="file-upload-bg-red">
                          <i className="icon-custom icon-bg-red icon-line icon-close"></i>
                        </span>
                        <p className="file-upload-name" key={i}>
                          {item.name}
                        </p>
                      </li>
                    )
                  ) : null
                )}
              </ul>
            </div>

            {/* {item.groupBy == "OR1" ? (
              <div>
                <p>OR</p>

                {item.isPresent === true ? (
                  <span className="file-upload-bg-green">
                    <i className="icon-custom icon-bg-green icon-line icon-check"></i>
                  </span>
                ) : (
                  <span className="file-upload-bg-red">
                    <i className="icon-custom icon-bg-red icon-line icon-close"></i>
                  </span>
                )}
              </div>
            ) : null}
            <p className="file-upload-name" key={i}>
              {item.name}
            </p> */}
          </div>
        )}
      </div>
    );
  }
}

export default FileUploadStatus;
