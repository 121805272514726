const setReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "LOGGEDIN":
      state["data"]["loggedIn"] = action.data;
      return state;
    case "SETLOAN":
      state["data"]["loanDetails"] = action.data;
      return state;
    case "SETCOMPANY":
      state["data"]["companyDetails"] = action.data;
      return state;
    case "SETFINANCE":
      state["data"]["financeDetails"] = action.data.data;
      state["data"]["currentYrCompanyAccFile"] = action.data.currentYrCompanyAccFile;
      state["data"]["lastYrCompanyAccFile"] = action.data.lastYrCompanyAccFile;
      state["data"]["twoYrAgoCompanyAccFile"] = action.data.twoYrAgoCompanyAccFile;
      return state;
    case "SETLEDGER":
      state["data"]["ledgerDetails"] = action.data;
      return state;
    case "SETCREDIT":
      state["data"]["creditReferenceDetails"] = action.data;
      return state;
    case "SETUSERDETAILS":
      state["data"]["personalDetails"] = action.data;
      return state;
    case "SETLANGUAGEDETAILS":
      state["data"]["languageDetails"] = action.data;
      return state;
    case "SETCHANGESTATUS":
      state["data"]["changeStatus"] = action.data;
      return state;
    case "SETUSERROLE":
      state["data"]["userRole"] = action.data;
      return state;
    default:
      return state;
  }
};

export default setReducer;
