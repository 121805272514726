const LedgerExperienceForm = (props) => {
  const { t } = props;
  return {
    type: "form",
    "components": [
      {
        "clearOnHide": false,
        "key": "panel",
        "input": false,
        "title": "",
        "theme": "default",
        "tableView": false,
        "components": [
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumnsColumns2",
                    "columns": [
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumnsColumns",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "autofocus": false,
                                    "input": true,
                                    "inputType": "checkbox",
                                    "tableView": true,
                                    "label": t("Have you ever purchased from the supplier on credit terms"),
                                    "dataGridLabel": false,
                                    "key": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                                    "defaultValue": false,
                                    "protected": false,
                                    "persistent": true,
                                    "hidden": false,
                                    "name": "",
                                    "value": "",
                                    "clearOnHide": true,
                                    "validate": {
                                      "required": false,
                                      "customMessage": "This field is required"
                                    },
                                    "type": "checkbox",
                                    "labelPosition": "right",
                                    "tags": [],
                                    "conditional": {
                                      "show": "",
                                      "when": null,
                                      "eq": ""
                                    },
                                    "properties": {},
                                    "hideLabel": false,
                                    "customClass": "nopad"
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {},
                            "hideLabel": true
                          }
                        ],
                        "width": 12,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "hideLabel": true,
                    "tags": [],
                    "conditional": {
                      "show": "",
                      "when": null,
                      "eq": ""
                    },
                    "properties": {}
                  }
                ],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns2",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumns2Columns2",
                    "columns": [
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumns2Columns",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "autofocus": false,
                                    "input": true,
                                    "inputType": "checkbox",
                                    "tableView": true,
                                    "label": t("Has the (supplier or buyer) ever been over 60 days past due over the last 2 years"),
                                    "dataGridLabel": false,
                                    "key": "panelColumns2ColumnsHasthesupplierorbuyereverbeenover60Dayspastdueoverthelast2Years",
                                    "defaultValue": false,
                                    "protected": false,
                                    "persistent": true,
                                    "hidden": false,
                                    "name": "",
                                    "value": "",
                                    "clearOnHide": true,
                                    "validate": {
                                      "required": false
                                    },
                                    "type": "checkbox",
                                    "labelPosition": "right",
                                    "hideLabel": false,
                                    "tags": [],
                                    "conditional": {
                                      "show": "true",
                                      "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                                      "eq": "true"
                                    },
                                    "properties": {}
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {},
                            "hideLabel": true
                          }
                        ],
                        "width": 12,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "hideLabel": true,
                    "tags": [],
                    "conditional": {
                      "show": "",
                      "when": null,
                      "eq": ""
                    },
                    "properties": {}
                  }
                ],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns2Columns3",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumns2Columns3Columns",
                    "columns": [
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumns2Columns3ColumnsColumns",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "key": "panelColumns2Columns3ColumnsColumnsContent",
                                    "label": "Content",
                                    "input": false,
                                    "tag": "p",
                                    "attrs": [
                                      {
                                        "value": "",
                                        "attr": ""
                                      }
                                    ],
                                    "className": "",
                                    "content": t("What is the total amount of credi outstanding at anyone time over the last 6 months?"),
                                    "type": "htmlelement",
                                    "hideLabel": true,
                                    "tags": [],
                                    "conditional": {
                                      "show": "true",
                                      "when": "panelColumnsWhatisthetotalamountofcreditoutstandingatanyonetimeoverlast6months?",
                                      "eq": "true"
                                    },
                                    "properties": {}
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "hideLabel": true,
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {},
                            "isNew": false
                          }
                        ],
                        "width": 12,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "hideLabel": true,
                    "tags": [],
                    "conditional": {
                      "show": "",
                      "when": null,
                      "eq": ""
                    },
                    "properties": {}
                  }
                ],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns4",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumns4Columns",
                    "columns": [
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumns4ColumnsColumns",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "autofocus": false,
                                    "input": true,
                                    "tableView": true,
                                    "inputType": "number",
                                    "label": t("Max Credit Outstanding"),
                                    "key": "outStandingBalance",
                                    "placeholder": t("Max Credit Outstanding"),
                                    "prefix": "$",
                                    "suffix": ".00",
                                    "defaultValue": "",
                                    "protected": false,
                                    "persistent": true,
                                    "hidden": false,
                                    "clearOnHide": true,
                                    "validate": {
                                      "required": false,
                                      "min": "",
                                      "max": "",
                                      "step": "any",
                                      "integer": "",
                                      "multiple": "",
                                      "custom": ""
                                    },
                                    "type": "number",
                                    "labelPosition": "top",
                                    "tags": [],
                                    "conditional": {
                                      "show": "true",
                                      "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                                      "eq": "true"
                                    },
                                    "properties": {}
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "hideLabel": true,
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {}
                          }
                        ],
                        "width": 6,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      },
                      {
                        "components": [],
                        "width": 6,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "hideLabel": true,
                    "tags": [],
                    "conditional": {
                      "show": "",
                      "when": null,
                      "eq": ""
                    },
                    "properties": {},
                    "isNew": false
                  }
                ],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "key": "panelContent",
            "label": "Content",
            "input": false,
            "tag": "p",
            "attrs": [
              {
                "value": "",
                "attr": ""
              }
            ],
            "className": "",
            "content": t("Credit Terms Granted"),
            "type": "htmlelement",
            "tags": [],
            "conditional": {
              "show": "true",
              "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
              "eq": "true"
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "key": "panelContent2",
            "label": "Content",
            "input": false,
            "tag": "hr",
            "attrs": [
              {
                "value": "",
                "attr": ""
              }
            ],
            "className": "horizontal-line",
            "content": "",
            "type": "htmlelement",
            "tags": [],
            "conditional": {
              "show": "true",
              "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
              "eq": "true"
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns3",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumns3Columns",
                    "columns": [
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumns3ColumnsColumns",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "autofocus": false,
                                    "input": true,
                                    "tableView": true,
                                    "inputType": "number",
                                    "label": t("Credit Amount"),
                                    "key": "panelColumns3ColumnsColumnsCreditAmount",
                                    "placeholder": "Credit Amount",
                                    "prefix": "$",
                                    "suffix": ".00",
                                    "defaultValue": "",
                                    "protected": false,
                                    "persistent": true,
                                    "hidden": false,
                                    "clearOnHide": true,
                                    "validate": {
                                      "required": false,
                                      "min": "",
                                      "max": "",
                                      "step": "any",
                                      "integer": "",
                                      "multiple": "",
                                      "custom": ""
                                    },
                                    "type": "number",
                                    "labelPosition": "top",
                                    "tags": [],
                                    "conditional": {
                                      "show": "true",
                                      "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                                      "eq": "true"
                                    },
                                    "properties": {}
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "hideLabel": true,
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {}
                          }
                        ],
                        "width": 6,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      },
                      {
                        "components": [
                          {
                            "clearOnHide": false,
                            "label": "Columns",
                            "input": false,
                            "tableView": false,
                            "key": "panelColumns3ColumnsColumns2",
                            "columns": [
                              {
                                "components": [
                                  {
                                    "autofocus": false,
                                    "input": true,
                                    "tableView": true,
                                    "label": t("Credit Term"),
                                    "key": "panelColumns3ColumnsColumns2CreditTerm",
                                    "placeholder": "",
                                    "data": {
                                      "values": [
                                        {
                                          "value": "",
                                          "label": ""
                                        }
                                      ],
                                      "json": "",
                                      "url": "",
                                      "resource": "",
                                      "custom": ""
                                    },
                                    "dataSrc": "values",
                                    "valueProperty": "",
                                    "defaultValue": "",
                                    "refreshOn": "",
                                    "filter": "",
                                    "authenticate": false,
                                    "template": "<span>{{ item.label }}</span>",
                                    "multiple": false,
                                    "protected": false,
                                    "unique": false,
                                    "persistent": true,
                                    "hidden": false,
                                    "clearOnHide": true,
                                    "validate": {
                                      "required": false
                                    },
                                    "type": "select",
                                    "labelPosition": "top",
                                    "tags": [],
                                    "conditional": {
                                      "show": "",
                                      "when": null,
                                      "eq": ""
                                    },
                                    "properties": {}
                                  }
                                ],
                                "width": 12,
                                "offset": 0,
                                "push": 0,
                                "pull": 0
                              }
                            ],
                            "type": "columns",
                            "hideLabel": true,
                            "tags": [],
                            "conditional": {
                              "show": "true",
                              "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                              "eq": "true"
                            },
                            "properties": {}
                          }
                        ],
                        "width": 6,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "hideLabel": true,
                    "tags": [],
                    "conditional": {
                      "show": "",
                      "when": null,
                      "eq": ""
                    },
                    "properties": {}
                  }
                ],
                "width": 12,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "hideLabel": true,
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {}
          },
          {
            "key": "panelColumns5Content",
            "label": "Content",
            "input": false,
            "tag": "hr",
            "attrs": [
              {
                "value": "",
                "attr": ""
              }
            ],
            "className": "horizontal-line",
            "content": "",
            "type": "htmlelement",
            "tags": [],
            "conditional": {
              "show": "true",
              "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
              "eq": "true"
            },
            "properties": {},
            "hideLabel": true
          },
          {
            "clearOnHide": false,
            "label": "Columns",
            "input": false,
            "tableView": false,
            "key": "panelColumns7",
            "columns": [
              {
                "components": [
                  {
                    "clearOnHide": false,
                    "label": "Columns",
                    "input": false,
                    "tableView": false,
                    "key": "panelColumns6",
                    "columns": [
                      {
                        "components": [
                          {
                            "autofocus": false,
                            "input": true,
                            "label": "Back",
                            "tableView": false,
                            "key": "panelColumns6Back",
                            "size": "md",
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "action": "submit",
                            "disableOnInvalid": false,
                            "theme": "success",
                            "type": "button",
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {}
                          }
                        ],
                        "width": 4,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      },
                      {
                        "components": [
                          {
                            "autofocus": false,
                            "input": true,
                            "label": "Save For Later",
                            "tableView": false,
                            "key": "panelColumns6SaveForLater",
                            "size": "md",
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "action": "event",
                            "event":"saveForLater",
                            "disableOnInvalid": false,
                            "theme": "success",
                            "type": "button",
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "customClass": "btn-save-ledger",
                            "properties": {},
                            "style": {
                              "margin-left": "20px"
                            }
                          }
                        ],
                        "width": 4,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      },
                      {
                        "components": [
                          {
                            "autofocus": false,
                            "input": true,
                            "label": "Continue",
                            "tableView": false,
                            "key": "panelColumns6Continue",
                            "size": "md",
                            "leftIcon": "",
                            "rightIcon": "",
                            "block": false,
                            "action": "submit",
                            "disableOnInvalid": false,
                            "theme": "success",
                            "type": "button",
                            "tags": [],
                            "conditional": {
                              "show": "",
                              "when": null,
                              "eq": ""
                            },
                            "properties": {},
                            "customClass": "btn-continue-ledger",
                            "style": {
                              "margin-left": "150px"
                            }
                          }
                        ],
                        "width": 4,
                        "offset": 0,
                        "push": 0,
                        "pull": 0
                      }
                    ],
                    "type": "columns",
                    "tags": [],
                    "conditional": {
                      "show": "true",
                      "when": "panelColumnsHaveyoueverpurchasedfromthesupplieroncreditterms",
                      "eq": "true"
                    },
                    "properties": {},
                    "hideLabel": true
                  }
                ],
                "width": 6,
                "offset": 0,
                "push": 0,
                "pull": 0
              }
            ],
            "type": "columns",
            "tags": [],
            "conditional": {
              "show": "",
              "when": null,
              "eq": ""
            },
            "properties": {},
            "hideLabel": true
          }
        ],
        "type": "panel",
        "breadcrumb": "default",
        "hideLabel": false,
        "tags": [],
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
}

export default LedgerExperienceForm;
