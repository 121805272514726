import { useTranslation } from "react-i18next";

const UserForm = () => {
  let { t } = useTranslation();
  return {
    _id: "5e9effb284e04dced6874358",
    type: "form",
    tags: [],
    owner: "5e5687252ea8e6f750d0f9f9",
    components: [
      {
        autofocus: false,
        input: true,
        inputType: "checkbox",
        tableView: true,
        label: "",
        dataGridLabel: false,
        key: "isEdit",
        defaultValue: false,
        protected: false,
        persistent: true,
        hidden: true,
        name: "",
        value: "",
        clearOnHide: true,
        validate: {
          required: false
        },
        type: "checkbox",
        labelPosition: "right",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: false
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("First Name"),
                key: "firstName",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: true,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("Last Name"),
                key: "lastName",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: true,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns2",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("Phone Number"),
                key: "contact",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                customDefaultValue: "value = data.gtfg",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: true,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                logic: [
                  {
                    name: "enableLogic",
                    trigger: {
                      type: "simple",
                      simple: {
                        show: true,
                        when: "isEdit",
                        eq: true
                      }
                    },
                    actions: [
                      {
                        name: "enableAction",
                        type: "property",
                        property: {
                          label: "disabled",
                          value: "disabled",
                          type: "string"
                        },
                        state: "false"
                      }
                    ]
                  }
                ],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("E-Mail"),
                key: "email",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: true,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                logic: [
                  {
                    name: "enableLogic",
                    trigger: {
                      type: "simple",
                      simple: {
                        show: true,
                        when: "isEdit",
                        eq: true
                      }
                    },
                    actions: [
                      {
                        name: "enableAction",
                        type: "property",
                        property: {
                          label: "disabled",
                          value: "disabled",
                          type: "string"
                        },
                        state: "false"
                      }
                    ]
                  }
                ],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns3",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: t("Role"),
                key: "role",
                placeholder: "",
                data: {
                  values: [
                    {
                      value: "ROLE_ADMIN",
                      label: t("Admin")
                    },
                    {
                      value: "ROLE_REVIEWER",
                      label: t("Reviewer")
                    },
                    {
                      value: "ROLE_APPROVER",
                      label: t("Approver")
                    },
                    {
                      value: "ROLE_USER",
                      label: t("User")
                    }
                  ],
                  json: "",
                  url: "",
                  resource: "",
                  custom: ""
                },
                dataSrc: "values",
                valueProperty: "",
                defaultValue: "",
                refreshOn: "",
                filter: "",
                authenticate: false,
                template: "<span>{{ item.label }}</span>",
                multiple: false,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                validate: {
                  required: true
                },
                logic: [
                  {
                    name: "enableLogic",
                    trigger: {
                      type: "simple",
                      simple: {
                        show: true,
                        when: "isEdit",
                        eq: true
                      }
                    },
                    actions: [
                      {
                        name: "enableAction",
                        type: "property",
                        property: {
                          label: "disabled",
                          value: "disabled",
                          type: "string"
                        },
                        state: "false"
                      }
                    ]
                  }
                ],
                type: "select",
                labelPosition: "top",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Username",
                key: "username",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: true,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                logic: [
                  {
                    name: "enableLogic",
                    trigger: {
                      type: "simple",
                      simple: {
                        show: true,
                        when: "isEdit",
                        eq: true
                      }
                    },
                    actions: [
                      {
                        name: "enableAction",
                        type: "property",
                        property: {
                          label: "disabled",
                          value: "disabled",
                          type: "string"
                        },
                        state: "false"
                      }
                    ]
                  }
                ],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          }
          //   {
          //     components: [
          //       {
          //         autofocus: false,
          //         input: true,
          //         tableView: true,
          //         inputType: "text",
          //         inputMask: "",
          //         label: t("Referrer"),
          //         key: "referrer",
          //         placeholder: "",
          //         prefix: "",
          //         suffix: "",
          //         multiple: false,
          //         defaultValue: "",
          //         protected: false,
          //         unique: false,
          //         persistent: true,
          //         hidden: false,
          //         clearOnHide: true,
          //         spellcheck: true,
          //         validate: {
          //           required: false,
          //           minLength: "",
          //           maxLength: "",
          //           pattern: "",
          //           custom: "",
          //           customPrivate: false
          //         },
          //         logic: [
          //           {
          //             name: "enableLogic",
          //             trigger: {
          //               type: "simple",
          //               simple: {
          //                 show: true,
          //                 when: "isEdit",
          //                 eq: true
          //               }
          //             },
          //             actions: [
          //               {
          //                 name: "enableAction",
          //                 type: "property",
          //                 property: {
          //                   label: "disabled",
          //                   value: "disabled",
          //                   type: "string"
          //                 },
          //                 state: "false"
          //               }
          //             ]
          //           }
          //         ],
          //         conditional: {
          //           show: true,
          //           when: "role",
          //           eq: "ROLE_REVIEWER"
          //         },
          //         type: "textfield",
          //         labelPosition: "top",
          //         inputFormat: "plain",
          //         tags: [],
          //         properties: {},
          //         lockKey: true
          //       }
          //     ],
          //     width: 6,
          //     offset: 0,
          //     push: 0,
          //     pull: 0
          //   }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },

      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns3",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("Referrer"),
                key: "referrer",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                logic: [
                  {
                    name: "enableLogic",
                    trigger: {
                      type: "simple",
                      simple: {
                        show: true,
                        when: "isEdit",
                        eq: true
                      }
                    },
                    actions: [
                      {
                        name: "enableAction",
                        type: "property",
                        property: {
                          label: "disabled",
                          value: "disabled",
                          type: "string"
                        },
                        state: "false"
                      }
                    ]
                  }
                ],
                conditional: {
                  show: true,
                  when: "role",
                  eq: "ROLE_REVIEWER"
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        autofocus: false,
        input: true,
        label: t("Submit"),
        tableView: false,
        key: "submit",
        size: "md",
        leftIcon: "",
        rightIcon: "",
        block: false,
        action: "event",
        event: "createUser",
        disableOnInvalid: true,
        theme: "primary",
        type: "button",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Loan",
    display: "form"
  };
};

export default UserForm;
