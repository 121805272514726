import React from "react";
import { get } from "../../../../../shared/services/rest-service";

class FinancialSummary extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      financialDetails: []
    };
  }

  async componentDidMount() {
    const getLoanDetails = await get(
      `loan/getLoanDetails/${this.props.loan.loan_id}`,
      {}
    );
    this.setState({ financialDetails: getLoanDetails.financialDetails });
  }

  render() {
    window.scrollTo(0, 0);

    const financialDetailsData = this.state.financialDetails.length;
    return (
      <div className="panel panel-default mt-4 financial-details">
        <div className="panel-heading financial-title">Financial Summary</div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th> </th>
              {this.state.financialDetails.map((item, i) => (
                <th key={i}>{item.year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ebitda</td>

              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.ebitda}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}
              {/* {
                                this.state.financialDetails.map((item, i) => <td key={i}>{item.ebitda}</td>)
                            } */}
            </tr>
            <tr>
              <td>Operating Profit</td>

              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.operatingProfit}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}
              {/* {
                                this.state.financialDetails.map((item, i) => <td key={i}>{item.operatingProfit}</td>)
                            } */}
            </tr>
            <tr>
              <td>Net Income</td>
              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.netIncome}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}

              {/*                             
                            {
                                this.state.financialDetails.map((item, i) => <td key={i}>{item.netIncome}</td>)
                            } */}
            </tr>
            <tr>
              <td>Net Worth</td>

              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.netWorth}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}
              {/* {
                                this.state.financialDetails.map((item, i) => <td key={i}>{item.netWorth}</td>)
                            } */}
            </tr>
            <tr>
              <td>Total Liability</td>
              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.totalLiability}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}

              {/* {
                                this.state.financialDetails.map((item, i) => item.totalLiability === null ? (
                                    <td key={i}>{"N/A"}</td>  
                                ) : (
                                    <td key={i}>{item.totalLiability}</td>  
                                )
                            )} */}
            </tr>
            <tr>
              <td>Debt Service</td>
              {financialDetailsData > 0 ? (
                [
                  this.state.financialDetails.map((item, i) => (
                    <th key={i}>{item.debtService}</th>
                  ))
                ]
              ) : (
                <td>NA</td>
              )}
              {/* {
                                this.state.financialDetails.map((item, i) => <td key={i}>{item.debtService}</td>)
                            } */}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default FinancialSummary;
