import config from "../../../../../app/modules/shared/config";

const LoanDetailsForm = (props, country) => {
  //let { t } = props;
  const disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];
  return {
    type: "form",
    components: [
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                inputType: "checkbox",
                tableView: true,
                label: "",
                dataGridLabel: false,
                key: "isEdit",
                defaultValue: false,
                protected: false,
                persistent: true,
                hidden: true,
                name: "",
                value: "",
                clearOnHide: true,
                validate: {
                  required: false
                },
                type: "checkbox",
                labelPosition: "right",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                hideLabel: false
              },
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: "Product Type",
                                key: "productTypeId",
                                placeholder: "Product Type",
                                dataSrc: "url",
                                data: {
                                  url: config.url + "taxonomy/PRODUCT_TYPE"
                                },
                                valueProperty: "value",
                                defaultValue: "",
                                refreshOn: "",
                                filter: "",
                                authenticate: false,
                                template: "<span>{{ item.label }}</span>",
                                multiple: false,
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "select",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {},
                                customClass: "product-type"
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns2",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns2Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns2ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: "Credit Term",
                                key: "creditTerm",
                                placeholder: "Credit Term",
                                dataSrc: "url",
                                data: {
                                  url: config.url + "credittermloan"
                                },
                                valueProperty: "id",
                                template: "<span>{{ item.label }}</span>",
                                refreshOn: "",
                                selectValues: "resultData",
                                valueProperty: "",
                                refreshOn: "",
                                filter: "",
                                authenticate: false,
                                multiple: false,
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "select",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns2ColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "number",
                                label: "Purchased Amount",
                                key: "purchasedAmount",
                                placeholder: "Purchased Amount",
                                tooltip: "Projected annual purchasing amount.",
                                prefix: "$",
                                suffix: ".00",
                                defaultValue: "",
                                protected: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true,
                                  min: "",
                                  max: "",
                                  // step: "any",
                                  // integer: "",
                                  // multiple: "",
                                  custom: ""
                                },
                                logic: disabledCondition,
                                type: "number",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns3",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns3Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns3ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: "Buyer Country",
                                key: "purchaseFrom.Transaltion.defaultValue",
                                placeholder: "Buyer Country",
                                data: {
                                  values: country,
                                  json: "",
                                  url: "",
                                  resource: "",
                                  custom: ""
                                },
                                dataSrc: "values",
                                valueProperty: "",
                                defaultValue: "",
                                refreshOn: "",
                                filter: "",
                                authenticate: false,
                                template: "<span>{{ item.label }}</span>",
                                multiple: false,
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "select",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns3ColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: "Seller Country",
                                key: "purchaseTo.Transaltion.defaultValue",
                                placeholder: "Seller Country",
                                data: {
                                  values: country,
                                  json: "",
                                  url: "",
                                  resource: "",
                                  custom: ""
                                },
                                dataSrc: "values",
                                valueProperty: "",
                                defaultValue: "",
                                refreshOn: "",
                                filter: "",
                                action: "event",
                                event: "back",
                                authenticate: false,
                                template: "<span>{{ item.label }}</span>",
                                multiple: false,
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "select",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                isNew: false
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns4",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns4Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns4ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "number",
                                label: "Loan Amount",
                                key: "amount",
                                placeholder: "Loan Amount",
                                tooltip: "Please enter loan amount.",
                                prefix: "$",
                                suffix: ".00",
                                defaultValue: "",

                                protected: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                refreshOn: "creditTerm",
                                validate: {
                                  required: true,
                                  custom:
                                    "valid = (input >= data.creditTerm.value.validationValue) ? true : 'Amount cannot be less than {{data.creditTerm.value.validationValue}}'"
                                },
                                type: "number",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: "",
                                  eq: ""
                                },
                                properties: {},
                                customClass: "nopad loan-amount"
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns4ColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: "Loan Period",
                                key: "period.Transaltion.defaultValue",
                                placeholder: "Loan Period",
                                type: "select",
                                dataSrc: "url",

                                data: {
                                  url:
                                    config.url +
                                    "getLoanPeriod/{{data.creditTerm.id}}"
                                },

                                valueProperty: "value",
                                template: "<span>{{ item.label }}</span>",

                                refreshOn: "creditTerm",

                                selectValues: "",
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: "",
                                  eq: ""
                                },
                                properties: {},
                                clearOnRefresh: true
                              }
                              // {
                              //   "autofocus": false,
                              //   "input": true,
                              //   "tableView": true,
                              //   "label": "Loan Period",
                              //   "key": "periodId",
                              //   "placeholder": "Loan Period",
                              //   "data": {
                              //     "values": [
                              //       {
                              //         "value": 9,
                              //         "label": "12 months"
                              //       },
                              //       {
                              //         "value": 10,
                              //         "label": "18 months"
                              //       },
                              //       {
                              //         "value": 11,
                              //         "label": "24 months"
                              //       }
                              //     ],
                              //     "json": "",
                              //     "url": "",
                              //     "resource": "",
                              //     "custom": ""
                              //   },
                              //   "dataSrc": "values",
                              //   "valueProperty": "",
                              //   "defaultValue": "",
                              //   "refreshOn": "",
                              //   "filter": "",
                              //   "authenticate": false,
                              //   "template": "<span>{{ item.label }}</span>",
                              //   "multiple": false,
                              //   "protected": false,
                              //   "unique": false,
                              //   "persistent": true,
                              //   "hidden": false,
                              //   "clearOnHide": true,
                              //   "validate": {
                              //     "required": true
                              //   },
                              //   "type": "select",
                              //   "labelPosition": "top",
                              //   "tags": [],
                              //   "conditional": {
                              //     "show": "true",
                              //     "when": "creditTerm",
                              //     "eq": "2"
                              //   },
                              //   "properties": {}
                              // },
                              // {
                              //   "autofocus": false,
                              //   "input": true,
                              //   "tableView": true,
                              //   "label": "Loan Period",
                              //   "key": "periodId",
                              //   "placeholder": "Loan Period",
                              //   "data": {
                              //     "values": [
                              //       {
                              //         "value": 9,
                              //         "label": "12 months"
                              //       },
                              //       {
                              //         "value": 10,
                              //         "label": "18 months"
                              //       },
                              //       {
                              //         "value": 11,
                              //         "label": "24 months"
                              //       },
                              //       {
                              //         "value": 12,
                              //         "label": "30 months"
                              //       },
                              //       {
                              //         "value": 13,
                              //         "label": "36 months"
                              //       }
                              //     ],
                              //     "json": "",
                              //     "url": "",
                              //     "resource": "",
                              //     "custom": ""
                              //   },
                              //   "dataSrc": "values",
                              //   "valueProperty": "",
                              //   "defaultValue": "",
                              //   "refreshOn": "",
                              //   "filter": "",
                              //   "authenticate": false,
                              //   "template": "<span>{{ item.label }}</span>",
                              //   "multiple": false,
                              //   "protected": false,
                              //   "unique": false,
                              //   "persistent": true,
                              //   "hidden": false,
                              //   "clearOnHide": true,
                              //   "validate": {
                              //     "required": true
                              //   },
                              //   "type": "select",
                              //   "labelPosition": "top",
                              //   "tags": [],
                              //   "conditional": {
                              //     "show": "",
                              //     "when": "",
                              //     "eq": ""
                              //   },
                              //   "customConditional": "show = data['creditTerm'] > 2",
                              //   "properties": {}
                              // }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: "",
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns5",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns5Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns5ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "text",
                                inputMask: "",
                                label: "Product requesting to purchase",
                                key: "productName",
                                placeholder: "Product requesting to purchase",
                                tooltip:
                                  "Please enter the details of product, goods or services to purchase.",
                                prefix: "",
                                suffix: "",
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: "",
                                  customPrivate: false
                                },
                                logic: disabledCondition,
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                type: "textfield",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                properties: {},
                                customClass: "nopad"
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        key: "content",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        cutomClass: "horizontal-line-com"
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns6",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns6Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns6ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Back",
                                tableView: false,
                                key: "columns6ColumnsColumnsBack",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "back",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "buttonclass"
                              }
                            ],
                            width: 2,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Save For Later",
                                tableView: false,
                                key: "columns6ColumnsColumnsSaveForLater",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "saveForLater",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "btn-save-loan"
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Continue",
                                tableView: false,
                                key: "columns6ColumnsColumnsContinue",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "submit",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "btn-continue-loan"
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {},
                        hideLabel: true
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
};

export default LoanDetailsForm;
