import React from "react";
import { NavLink } from "react-router-dom";
import "./sub-header.scss";

class SubHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      linkDisabled: true,
      pointerEvents: "none"
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isLoggedIn") === "true") {
      this.setState({ loggedIn: true });
    }
  }
  tabdsbaled(data) {
    this.setState({ pointerEvents: data });
  }

  render() {
    return (
      <section className="clearfix">
        {/* { this.state.loggedIn ? */}
        <div className="nav-mainbox ">
          <div className="sub-header-title">
            <h3 className="head-tittle">Online Credit Application</h3>
          </div>
          <div className="sub-header-menu">
            <ul className="sub-header-bar">
              <NavLink
                exact
                activeClassName="navbar__link--active"
                className="navbar__link link"
                to={"/"}
                style={{ pointerEvents: this.state.pointerEvents }}

                // to={{ pathname: "/", tabdsbaled: this.tabdsbaled }}
              >
                Personal Details
              </NavLink>
              <li className="topbar-devider"></li>
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                style={{ pointerEvents: this.state.pointerEvents }}
                to={"/company-details"}
                // to={{
                //   pathname: "/company-details",
                //   tabdsbaled: this.tabdsbaled.bind(this)
                // }}
                // tabdsbaled={this.tabdsbaled.bind(this)}
              >
                Company Details
              </NavLink>
              <li className="topbar-devider"></li>
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                style={{ pointerEvents: this.state.pointerEvents }}
                to={"/loan-details"}
                tabdsbaled={this.tabdsbaled.bind(this)}
              >
                Loan Details
              </NavLink>
              <li className="topbar-devider"></li>
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to={"/financial-details"}
                style={{ pointerEvents: this.state.pointerEvents }}
              >
                Financial Details
              </NavLink>
              <li className="topbar-devider"></li>
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to={"/credit-reference"}
                style={{ pointerEvents: this.state.pointerEvents }}
              >
                Credit Reference
              </NavLink>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default SubHeader;
