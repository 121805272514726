import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import { getUserDetails } from "../../../../../user/services/UserService";
import {
  getLoanDetails,
  getReviewerSummary,
  getApproverSummary,
  updateReviewerLoanStatus,
  updateApproverLoanStatus
} from "../../../../services/LoanServices";
// import languageEN from "../../../translate/languages/english";
// import languageSPA from "../../../translate/languages/spanish";
import "./summary.scss";
import SummaryForm from "./summaryForm";

class LoanSummaryOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      block1: true,
      loanSummary: {},
      summaryStatus: [],
      summaryRole: false,
      userID: "",
      loanId: ""
    };
    if (localStorage.getItem("token")) {
      if (this.props.location.pathname !== "/") {
        let currentUserDetails = JSON.parse(
          localStorage.getItem("currentUser")
        );

        this.setState({
          userID: currentUserDetails.id,
          loanId: this.props.match.params.id
        });
        localStorage.setItem("LoanId", this.props.match.params.id);
      } else {
        this.setState({
          userID: this.props.location.state.user_id,
          loanId: this.props.location.state.loan_id
        });
      }
    } else {
      window.open("/login", "_self");
    }
  }

  async componentDidMount() {
    // get loan details
    var loanID;
    var userId;
    if (this.props.loan == undefined) {
      let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));

      loanID = localStorage.getItem("LoanId");
      userId = currentUserDetails.id;
      this.setState({ userID: userId, loanId: loanID });
    } else {
      loanID = this.props.loan.loan_id;
      userId = this.props.location.state.user_id;
      this.setState({ userID: userId, loanId: loanID });
    }

    const getLoanDetail = await getLoanDetails(loanID);
    this.setState({
      loanSummary: getLoanDetail.LoanSummary
    });

    // get user details

    const getUserDetail = await getUserDetails(userId);

    localStorage.setItem("userRole", getUserDetail.result.role);
    if (getUserDetail.result.role === "ROLE_REVIEWER") {
      this.setState({ summaryRole: true });
      const getSummaryStatus = await getReviewerSummary();
      this.setState({ summaryStatus: getSummaryStatus });
    }

    if (getUserDetail.result.role === "ROLE_APPROVER") {
      this.setState({ summaryRole: true });
      const getSummaryStatus = await getApproverSummary();
      this.setState({ summaryStatus: getSummaryStatus });
    }
  }

  toggle = index => () => {
    this.setState({ [`block${index}`]: !this.state[`block${index}`] });
  };

  toggleExpand = (expand = false) => () => {
    this.setState({
      block1: expand
    });
  };

  render() {
    return (
      <div className="mt-3">
        <div className="container-fluid filter-form">
          <dl className="accordion">
            <Accordion
              title="Summary"
              onClick={this.toggle(1)}
              expand={this.state.block1}
              loanSummary={this.state.loanSummary}
              summaryRole={this.state.summaryRole}
              summaryStatus={this.state.summaryStatus}
              userId={this.state.userID}
              loanId={this.state.loanId}
            />
          </dl>
        </div>
      </div>
    );
  }
}

class Accordion extends React.Component {
  submitFormData = async ({ data }) => {
    var loanID;
    var userId;
    if (this.props.loan == undefined) {
      let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));

      loanID = localStorage.getItem("LoanId");
      userId = currentUserDetails.id;
    } else {
      loanID = this.props.loan.loan_id;
      userId = this.props.location.state.user_id;
    }
    const getUserDetail = await getUserDetails(this.props.userId);
    if (getUserDetail.result.role === "ROLE_REVIEWER") {
      if (data.status === 304) {
        let statusData = {
          id: loanID,
          comments: data.comment
        };
        const test = await updateReviewerLoanStatus("REVIEWED", statusData);
        window.open("/loans", "_self");
      }

      if (data.status === 305) {
        let statusData = {
          id: loanID,
          comments: data.comment
        };
        await updateReviewerLoanStatus("REJECTED", statusData);
        window.open("/loans", "_self");
      }
    } else {
      if (data.status === 307) {
        let statusData = {
          id: loanID,
          comments: data.comment
        };
        await updateApproverLoanStatus("APPROVED", statusData);
        window.open("/loans", "_self");
      }

      if (data.status === 306) {
        let statusData = {
          id: loanID,
          comments: data.comment
        };
        await updateApproverLoanStatus("REJECTED", statusData);
        window.open("/loans", "_self");
      }
    }
  };

  render() {
    // const { t } = this.props;
    const { title, expand, onClick } = this.props;
    const {
      Company,
      Amount,
      ProductName,
      AnnualPurchaseAmount,
      Term,
      Type,
      BuyerCountry,
      SellerCountry
    } = this.props.loanSummary;
    return (
      <div>
        <dt
          className={expand ? "title is-expanded" : "title"}
          onClick={onClick}
        >
          {title}
        </dt>
        <dd className={expand ? "content is-expanded" : "content"}>
          <div className="parent inline-flex-parent">
            <div className="summary-form">
              <p>
                <b>Company : </b>
                {Company}
                <br></br>
                <b>Amount : </b>
                {Amount}
                <br></br>
                <b>Product Name : </b>
                {ProductName}
                <br></br>
                <b>Annual purchase amount : </b>
                {AnnualPurchaseAmount}
                <br></br>
                <b>Term : </b>
                {Term}
                <br></br>
                <b>Type : </b>
                {Type}
                <br></br>
                <b>Buyer Country : </b>
                {BuyerCountry}
                <br></br>
                <b>Seller Country : </b>
                {SellerCountry}
                <br></br>
              </p>
            </div>

            {this.props.summaryRole ? (
              <div className="summary-form">
                <Form
                  form={SummaryForm(this.props.summaryStatus)}
                  submission={{ data: "" }}
                  onSubmit={this.submitFormData}
                />
              </div>
            ) : null}
          </div>
        </dd>
      </div>
    );
  }
}

export default withRouter(LoanSummaryOverview);
