import React from "react";
// import { get } from "../../../../../shared/services/rest-service";

class AuditHistory extends React.Component {
  constructor(props) {
    super(props);
  }
  prettyDate2 = time => {
    // var date = new Date(parseInt(time));
    // return date.toLocaleTimeString(navigator.language, {
    //   hour: "2-digit",
    //   minute: "2-digit"
    // });
    var timeString = time;
    var H = +timeString.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 || H === 24 ? "AM" : "PM";
    return (timeString = h + timeString.substr(2, 3) + ampm);
  };
  render() {
    return (
      <div className="panel panel-default mt-4 financial-details">
        <div className="panel-heading credit-file-upload">Audit Report</div>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Comments</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {this.props.auditReport.map((item, i) => (
              <tr key={i}>
                <td>
                  {item.createdAt.split("T")[0]}{" "}
                  {this.prettyDate2(item.createdAt.split("T")[1])}
                </td>
                <td>{item.status}</td>
                <td>{item.comments}</td>
                <td>{item.userName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AuditHistory;
