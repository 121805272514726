function FinancialDetailsForm(props, CreditTerm, creditRelationship, newData) {
  const { t } = props;
  const disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];

  return {
    type: "form",
    components: [
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: "Credit Report",
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label: "",
                                    dataGridLabel: false,
                                    key: "isEdit",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: true,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  },
                                  {
                                    key: "CompanyCreditReport",
                                    label: "Content",
                                    input: false,
                                    tag: "san",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    hidden: false,
                                    content: "Company Credit Report",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: "Upload File",
                                    tableView: false,
                                    key: "companyCreditReportFileId",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadCompanyCreditReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    hidden: false,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className:
                                      "companycredit-filename file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panelColumns2ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Personal Credit Report",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title1"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: "Upload File",
                                    tableView: false,
                                    key: "personalCreditReportFileId",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadPersonalCreditReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-upload"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "personal-credit file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: false,
                              when: "hidePersonal",
                              eq: true
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "credit-report-panel"
      },
      {
        clearOnHide: false,
        key: "panel2",
        input: false,
        title: "Trade Reference 1",
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2ColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Name of company giving reference",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-title"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "text",
                                    inputMask: "",
                                    label: "Name of company giving reference",
                                    key: "companyReference",
                                    placeholder:
                                      "Name of company giving reference",
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "textfield",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumns2Content",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Years of experience with company",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-tittle"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    inputMask: "",
                                    label: "Years of experience with company",
                                    key: "experience",
                                    placeholder:
                                      "Years of experience with company",
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "^[0-9]*$",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "number",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label:
                                      "Estimated annual sales to the company",
                                    key: "estimatedAnnualSale",
                                    placeholder:
                                      "Estimated annual sales to the company",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label:
                                      "Highest credit within the past 12 months",
                                    key: "highestCredit",
                                    placeholder:
                                      "Highest credit within the past 12 months",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "Current Outstanding Balance",
                                    key: "outStandingBalance",
                                    placeholder: "Current Outstanding Balance",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: "Credit Term",
                                    key: "tradeCreditTermid",
                                    placeholder: "",
                                    data: {
                                      values: CreditTerm,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: true
                                    },
                                    logic: disabledCondition,
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: "Credit Relationship",
                                    key:
                                      "panel2Columns5ColumnsColumnsCreditRelationship",
                                    placeholder: "",
                                    data: {
                                      values: creditRelationship,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: true,
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            key: "panel2Content",
            label: "Content",
            input: false,
            tag: "hr",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "horizontal-line",
            content: "",
            type: "htmlelement",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2Columns4ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Trade Reference 1",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title1"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: "Upload File",
                                    tableView: false,
                                    key: "tradeReferenceFileId",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadTradeReferenceReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },

                                    properties: {},
                                    storage: "s3"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "trade-reference1 file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: false,
          when: "hideTrade11",
          eq: true
        },
        properties: {}
      },
      {
        key: "panelContent",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        key: "panel2",
        input: false,
        title: "Trade Reference 2",
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2ColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Name of company giving reference",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-title"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "text",
                                    inputMask: "",
                                    label: "Name of company giving reference",
                                    key: "companyReference1",
                                    placeholder:
                                      "Name of company giving reference",
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "textfield",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumns2Content",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Years of experience with company",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-tittle"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "text",
                                    inputMask: "",
                                    label: "Years of experience with company",
                                    key: "experience1",
                                    placeholder:
                                      "Years of experience with company",
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "^[0-9]*$",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "textfield",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label:
                                      "Estimated annual sales to the company",
                                    key: "estimatedAnnualSale1",
                                    placeholder:
                                      "Estimated annual sales to the company",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label:
                                      "Highest credit within the past 12 months",
                                    key: "highestCredit1",
                                    placeholder:
                                      "Highest credit within the past 12 months",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "Current Outstanding Balance",
                                    key: "outStandingBalance1",
                                    placeholder: "Current Outstanding Balance",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: "Credit Term",
                                    key: "tradeCreditTermid1",
                                    placeholder: "",
                                    data: {
                                      values: CreditTerm,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: true
                                    },
                                    logic: disabledCondition,
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: "Credit Relationship",
                                    key:
                                      "panel2Columns5ColumnsColumnsCreditRelationship",
                                    placeholder: "",
                                    data: {
                                      values: creditRelationship,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: true,
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            key: "panel2Content",
            label: "Content",
            input: false,
            tag: "hr",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "horizontal-line",
            content: "",
            type: "htmlelement",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2Columns4ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: "Trade Reference 2",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title1"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: "Upload File",
                                    tableView: false,
                                    key: "tradeReferenceFileId",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadTradeReferenceReport2",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    storage: "s3"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "trade-reference2 file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: false,
          when: "hideTrade22",
          eq: true
        },
        properties: {}
      },
      {
        key: "panelContent",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: t("Bank Reference"),
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label: "",
                                    dataGridLabel: false,
                                    key: "isEdit",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: true,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  },
                                  {
                                    key: "panelColumnsColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "san",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    hidden: false,
                                    content: t("Company Credit Report"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload File"),
                                    tableView: false,
                                    key: "companyCreditReportFileIdd",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadCompanyCreditReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    hidden: false,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panelColumns2ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Personal Credit Report"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hidden: true,
                                    customClass: "upload-title1"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload File"),
                                    tableView: false,
                                    key: "personalCreditReportFileIdd",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "submit",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    hidden: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-upload"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: true,
          when: "isCredit",
          eq: true
        },
        properties: {},
        customClass: "credit-report-panel"
      },
      {
        clearOnHide: false,
        key: "panel3",
        input: false,
        title: "Bank Reference",
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2ColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t(
                                      "Name of company giving reference"
                                    ),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-title"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "text",
                                    inputMask: "",
                                    label: t("Name of bank giving reference"),
                                    key: "bankReference",
                                    placeholder: t(
                                      "Name of bank giving reference"
                                    ),
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "textfield",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2ColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2ColumnsColumnsColumns2Content",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Years with bank"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "cre-tittle"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "text",
                                    inputMask: "",
                                    label: t("Years with bank"),
                                    key: "yearsWithBank",
                                    placeholder: t("Years with bank"),
                                    prefix: "",
                                    suffix: "",
                                    multiple: false,
                                    defaultValue: "",
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    spellcheck: true,
                                    validate: {
                                      required: false,
                                      minLength: "",
                                      maxLength: "",
                                      pattern: "^[0-9]*$",
                                      custom: "",
                                      customPrivate: false
                                    },
                                    logic: disabledCondition,
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    type: "textfield",
                                    labelPosition: "top",
                                    inputFormat: "plain",
                                    tags: [],
                                    properties: {},
                                    hideLabel: true
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Credit facility"),
                                    key: "creditFacility",
                                    placeholder: t("Credit facility"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Estimated annual balance"),
                                    key: "estimatedAnnualBalance",
                                    placeholder: t("Estimated annual balance"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Current Outstanding Balance"),
                                    key: "outStandingBalance2",
                                    placeholder: t(
                                      "Current Outstanding Balance"
                                    ),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Current credit amount"),
                                    key: "creditAmount",
                                    placeholder: t("Current credit amount"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: t("Credit Relationship"),
                                    key: "creditRelationId",
                                    placeholder: "",
                                    data: {
                                      values: creditRelationship,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    logic: disabledCondition,
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            key: "panel2Content",
            label: "Content",
            input: false,
            tag: "hr",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "horizontal-line",
            content: "",
            type: "htmlelement",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panel2Columns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panel2Columns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panel2Columns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "panel2Columns4ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Bank Reference"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "upload-title1"
                                  },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload File"),
                                    tableView: false,
                                    key: "bankReferenceFileName",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadBankReferenceReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "bank-reference file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: false,
          when: "hideBank1",
          eq: true
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "panelColumns6",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "panelColumns6Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "panelColumns6ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Back",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsBack",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "back",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: false,
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                headers: []
                              }
                            ],
                            width: 2,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsSaveForLater",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "saveForLater",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: true,
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: "",
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 1,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Continue",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsContinue",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "submit",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: false,
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "btn-continue-finance"
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
}

export default FinancialDetailsForm;
