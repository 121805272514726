function FinancialDetailsForm(props) {
  const { t } = props;
  let currentYearReportFile = "";
  let lastYearFileName = "";
  let last2YearFileName = "";
  let currentYearReportFileUrl = "";
  let lastYearFileNameUrl = "";
  let last2YearFileNameUrl = "";
  if (props.financialDetails) {
    currentYearReportFile =
      props.financialDetails.companyDetails.currentYearReportFile;
    lastYearFileName = props.financialDetails.companyDetails.lastYearFileName;
    last2YearFileName = props.financialDetails.companyDetails.last2YearFileName;
    currentYearReportFileUrl =
      props.financialDetails.companyDetails.currentYearReportFileUrl;
    lastYearFileNameUrl =
      props.financialDetails.companyDetails.lastYearFileNameUrl;
    last2YearFileNameUrl =
      props.financialDetails.companyDetails.last2YearFileNameUrl;
  }
  if (props.financialDetails) {
    if (
      props.financialDetails.companyDetails.currentYearReportFile ==
        undefined ||
      props.financialDetails.companyDetails.currentYearReportFile == ""
    ) {
      currentYearReportFile = "";
      currentYearReportFileUrl = "";
    }
    if (
      props.financialDetails.companyDetails.lastYearFileName == undefined ||
      props.financialDetails.companyDetails.lastYearFileName == ""
    ) {
      lastYearFileName = "";
      lastYearFileNameUrl = "";
    }
    if (
      props.financialDetails.companyDetails.last2YearFileName == undefined ||
      props.financialDetails.companyDetails.last2YearFileName == ""
    ) {
      last2YearFileName = "";
      last2YearFileNameUrl = "";
    }
  }

  const disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit2",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];

  return {
    type: "form",
    components: [
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: t("Current Financial Year"),
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    autofocus: false,
                    input: true,
                    inputType: "checkbox",
                    tableView: true,
                    label: "",
                    dataGridLabel: false,
                    key: "isEdit2",
                    defaultValue: false,
                    protected: false,
                    persistent: true,
                    hidden: true,
                    name: "",
                    value: "",
                    clearOnHide: true,
                    validate: {
                      required: false
                    },
                    type: "checkbox",
                    labelPosition: "right",
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {},
                    hideLabel: false
                  },
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "CompanyAccounts",
                                    label: t("Company Accounts"),
                                    input: false,
                                    tag: "p",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Company Accounts"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "browse"
                                  }
                                  // {
                                  //   "autofocus": false,
                                  //   "input": true,
                                  //   "label": t("Upload a File"),
                                  //   "tableView": false,
                                  //   "key": "panelColumnsColumnsColumnsUploadaFile",
                                  //   "size": "md",
                                  //   "leftIcon": "",
                                  //   "rightIcon": "",
                                  //   "block": false,
                                  //   "action": "event",
                                  //   "event": "uploadCurrentYearReport",
                                  //   "disableOnInvalid": false,
                                  //   "theme": "success",
                                  //   "type": "button",
                                  //   "tags": [],
                                  //   "conditional": {
                                  //     "show": "",
                                  //     "when": null,
                                  //     "eq": ""
                                  //   },
                                  //   "properties": {},
                                  //   "customClass": "btn-browse"
                                  // },
                                  // {
                                  //   "key": "content",
                                  //   "label": "Content",
                                  //   "input": false,
                                  //   "tag": "div",
                                  //   "attrs": [
                                  //     {
                                  //       "value": "",
                                  //       "attr": ""
                                  //     }
                                  //   ],
                                  //   "className": "current-year-filename file-name",
                                  //   "content": "",
                                  //   "type": "htmlelement",
                                  //   "hideLabel": true,
                                  //   "tags": [],
                                  //   "conditional": {
                                  //     "show": "",
                                  //     "when": null,
                                  //     "eq": ""
                                  //   },
                                  //   "properties": {}
                                  // },
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label: t("Interim"),
                                    dataGridLabel: false,
                                    key: "isInterim",
                                    defaultValue: false,

                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    hideLabel: false,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload a File"),
                                    tableView: false,
                                    key:
                                      "panelColumnsColumnsColumnsUploadaFile",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadCurrentYearReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  },

                                  // {

                                  // //   "key": "columnsColumnsFileName",
                                  // //   "label": "<a href="/"><b>{fileName}</b></a>",
                                  // //   "input": false,
                                  // //   "tag": "span",
                                  // //   "attrs": [
                                  // //     {
                                  // //       "value": "",
                                  // //       "attr": ""
                                  // //     }
                                  // //   ],
                                  // //  // "className": "current-year-filename file-name",
                                  // //   "type": "htmlelement",
                                  // //   "action": "event",
                                  // //    content:"<a><b>"+localStorage.getItem+"</b></a>",
                                  // //   //"type": "button",
                                  // //   "event": "currentYearReportDownload",

                                  // //   "hideLabel": true,
                                  // //   "tags": [],
                                  // //   "conditional": {
                                  // //     "show": "",
                                  // //     "when": null,
                                  // //     "eq": ""
                                  // //   },
                                  // //   "properties": {},
                                  //  // "customClass": "subheading"
                                  //   "key": "content",
                                  //   "label": "Content",
                                  //   "input": false,
                                  //   "tag": "div",
                                  //  // "customClass": "current-year-filename fa fa-remove ",
                                  //   "attrs": [
                                  //     {
                                  //       "value": "",
                                  //       "attr": ""
                                  //     }
                                  //   ],
                                  //    "className": "current-year-filename fa fa-remove",
                                  //    "action": "event",
                                  //    "event": "xyzs",
                                  //    "type": "button",

                                  //    "content": "",
                                  //   // "type": "htmlelement",
                                  //   "hideLabel": true,
                                  //   "tags": [],
                                  //   "conditional": {
                                  //     "show": "",
                                  //     "when": null,
                                  //     "eq": ""
                                  //   },
                                  //   "properties": {}
                                  // },
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Remove"),
                                    tableView: false,
                                    key:
                                      "panelColumnsColumnsColumnsUploadaFile",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadCurrentYearReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: true,
                                      when: localStorage.getItem("isBack"),
                                      eq: "no"
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  },

                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className:
                                      "current-year-filename file-name",
                                    content: "",
                                    // type:"button",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                  // {

                                  //     "key": "content",
                                  //     "label": "Content",
                                  //     "input": false,
                                  //     "tag": "div",
                                  //     //"customClass": "current-year-filename file-name",
                                  //     "attrs": [
                                  //       {
                                  //         "value": "",
                                  //         "attr": ""
                                  //       }
                                  //     ],
                                  //      "label": "current-year-filename file-name",
                                  //     "content": "",
                                  //         "action": "event",
                                  //    // "type": "button",
                                  //     "type": "htmlelement",
                                  //     "hideLabel": true,
                                  //     "tags": [],
                                  //     "conditional": {
                                  //       "show": true,
                                  //       "when": "current-year-filename",
                                  //       "eq": true
                                  //     },
                                  //     "properties": {}
                                  //   }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "columnsColumnsFileName",
                                    label:
                                      "<a href=" / "><b>{fileName}</b></a>",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "file-name",
                                    type: "htmlelement",
                                    action: "event",
                                    content:
                                      "<a href=" +
                                      currentYearReportFileUrl +
                                      "><b>" +
                                      currentYearReportFile +
                                      "</b></a>",
                                    //"type": "button",
                                    event: "currentYearReportDownload",

                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "subheading"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "EBITDA",
                                    key: "ebitda",
                                    placeholder: "EBITDA",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Operating Profit"),
                                    key: "operatingProfit",
                                    placeholder: t("Operating Profit"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Income"),
                                    key: "netIncome",
                                    placeholder: t("Net Income"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Worth"),
                                    key: "netWorth",
                                    placeholder: t("Net Worth"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Total Liabilities"),
                                    key: "totalLiability",
                                    placeholder: t("Total Liabilities"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Debt Service"),
                                    key: "debtService",
                                    placeholder: t("Debt Service"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ".00"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Cash from Operations"),
                                    key: "netCashFromOperations",
                                    placeholder: t("Net Cash from Operations"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ""
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Financial Year"),
                                    key: "year",
                                    placeholder: t("Financial Year"),
                                    prefix: "",
                                    suffix: "",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "panel-tittle"
      },
      {
        key: "panelContent",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: t("Last Financial Year"),
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "CompanyAccounts1",
                                    label: t("Company Accounts"),
                                    input: false,
                                    tag: "p",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Company Accounts"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "browse"
                                  }
                                  // {
                                  // "autofocus": false,
                                  // "input": true,
                                  // "label": t("Upload a File"),
                                  // "tableView": false,
                                  // "key": "panelColumnsColumnsColumnsUploadaFile",
                                  // "size": "md",
                                  // "leftIcon": "",
                                  // "rightIcon": "",
                                  // "block": false,
                                  // "action": "event",
                                  // "event": "uploadLastYearReport",
                                  // "disableOnInvalid": false,
                                  // "theme": "success",
                                  // "type": "button",
                                  // "tags": [],
                                  // "conditional": {
                                  //   "show": "",
                                  //   "when": null,
                                  //   "eq": ""
                                  // },
                                  // "properties": {},
                                  // "customClass": "btn-browse"
                                  // },
                                  // {
                                  // "key": "content",
                                  // "label": "Content",
                                  // "input": false,
                                  // "tag": "div",
                                  // "attrs": [
                                  //   {
                                  //     "value": "",
                                  //     "attr": ""
                                  //   }
                                  // ],
                                  // "className": "last-year-filename file-name",
                                  // "content": "",
                                  // "type": "htmlelement",
                                  // "hideLabel": true,
                                  // "tags": [],
                                  // "conditional": {
                                  //   "show": "",
                                  //   "when": null,
                                  //   "eq": ""
                                  // },
                                  // "properties": {}
                                  // }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label: t("Interim"),
                                    dataGridLabel: false,
                                    key: "isInterim",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    hideLabel: false,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload a File"),
                                    tableView: false,
                                    key:
                                      "panelColumnsColumnsColumnsUploadaFile",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadLastYearReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "last-year-filename file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "columnsColumnsFileName",
                                    label:
                                      "<a href=" / "><b>{fileName}</b></a>",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "file-name",
                                    type: "htmlelement",
                                    action: "event",
                                    content:
                                      "<a href=" +
                                      lastYearFileNameUrl +
                                      "><b>" +
                                      lastYearFileName +
                                      "</b></a>",

                                    //"type": "button",
                                    event: "currentYearReportDownload",

                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "subheading"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "EBITDA",
                                    key: "ebitda1",
                                    placeholder: "EBITDA",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Operating Profit"),
                                    key: "operatingProfit1",
                                    placeholder: t("Operating Profit"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Income"),
                                    key: "netIncome1",
                                    placeholder: t("Net Income"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Worth"),
                                    key: "netWorth1",
                                    placeholder: t("Net Worth"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Total Liabilities"),
                                    key: "totalLiability1",
                                    placeholder: t("Total Liabilities"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Debt Service"),
                                    key: "debtService1",
                                    placeholder: t("Debt Service"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ".00"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Cash from Operations"),
                                    key: "netCashFromOperations1",
                                    placeholder: t("Net Cash from Operations"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ""
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Financial Year"),
                                    key: "year1",
                                    placeholder: t("Financial Year"),
                                    prefix: "",
                                    suffix: "",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "panel-tittle"
      },
      {
        key: "panelContent",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: t("2 Year Ago Financial Year"),
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "CompanyAccounts2",
                                    label: t("Company Accounts"),
                                    input: false,
                                    tag: "p",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "nopad",
                                    content: t("Company Accounts"),
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "browse"
                                  }
                                  // {
                                  //   "autofocus": false,
                                  //   "input": true,
                                  //   "label": t("Upload a File"),
                                  //   "tableView": false,
                                  //   "key": "panelColumnsColumnsColumnsUploadaFile",
                                  //   "size": "md",
                                  //   "leftIcon": "",
                                  //   "rightIcon": "",
                                  //   "block": false,
                                  //   "action": "event",
                                  //   "event": "uploadTwoYearAgoReport",
                                  //   "disableOnInvalid": false,
                                  //   "theme": "success",
                                  //   "type": "button",
                                  //   "tags": [],
                                  //   "conditional": {
                                  //     "show": "",
                                  //     "when": null,
                                  //     "eq": ""
                                  //   },
                                  //   "properties": {},
                                  //   "customClass": "btn-browse"
                                  // },
                                  // {
                                  //   "key": "content",
                                  //   "label": "Content",
                                  //   "input": false,
                                  //   "tag": "div",
                                  //   "attrs": [
                                  //     {
                                  //       "value": "",
                                  //       "attr": ""
                                  //     }
                                  //   ],
                                  //   "className": "two-year-filename file-name",
                                  //   "content": "",
                                  //   "type": "htmlelement",
                                  //   "hideLabel": true,
                                  //   "tags": [],
                                  //   "conditional": {
                                  //     "show": "",
                                  //     "when": null,
                                  //     "eq": ""
                                  //   },
                                  //   "properties": {}
                                  // }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label: t("Interim"),
                                    dataGridLabel: false,
                                    key: "isInterim",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: true,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    hideLabel: false,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },

          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    label: t("Upload a File"),
                                    tableView: false,
                                    key:
                                      "panelColumnsColumnsColumnsUploadaFile",
                                    size: "md",
                                    leftIcon: "",
                                    rightIcon: "",
                                    block: false,
                                    action: "event",
                                    event: "uploadTwoYearAgoReport",
                                    disableOnInvalid: false,
                                    theme: "success",
                                    type: "button",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "btn-browse"
                                  },
                                  {
                                    key: "content",
                                    label: "Content",
                                    input: false,
                                    tag: "div",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "two-year-filename file-name",
                                    content: "",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    key: "columnsColumnsFileName",
                                    label:
                                      "<a href=" / "><b>{fileName}</b></a>",
                                    input: false,
                                    tag: "span",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "file-name",
                                    type: "htmlelement",
                                    action: "event",
                                    content:
                                      "<a href=" +
                                      last2YearFileNameUrl +
                                      "><b>" +
                                      last2YearFileName +
                                      "</b></a>",

                                    //"type": "button",
                                    event: "currentYearReportDownload",

                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: "subheading"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },

          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "EBITDA",
                                    key: "ebitda2",
                                    placeholder: "EBITDA",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Operating Profit"),
                                    key: "operatingProfit2",
                                    placeholder: t("Operating Profit"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Income"),
                                    key: "netIncome2",
                                    placeholder: t("Net Income"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Worth"),
                                    key: "netWorth2",
                                    placeholder: t("Net Worth"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Total Liabilities"),
                                    key: "totalLiability2",
                                    placeholder: t("Total Liabilities"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Debt Service"),
                                    key: "debtService2",
                                    placeholder: t("Debt Service"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ".00"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns5",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns5Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Net Cash from Operations"),
                                    key: "netCashFromOperations2",
                                    placeholder: t("Net Cash from Operations"),
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    customClass: ""
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns5ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: t("Financial Year"),
                                    key: "year2",
                                    placeholder: t("Financial Year"),
                                    prefix: "",
                                    suffix: "",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "panel-tittle"
      },
      {
        key: "panelContent",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line-com",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "panelColumns6",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "panelColumns6Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "panelColumns6ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Back",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsBack",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "back",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: false,
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit2",
                                  eq: true
                                },
                                properties: {},
                                headers: []
                              }
                            ],
                            width: 2,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Save For Later",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsSaveForLater",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "saveForLater",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: false,
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit2",
                                  eq: true
                                },
                                properties: {}
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: "Continue",
                                tableView: false,
                                key: "panelColumns6ColumnsColumnsContinue",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "submit",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                hidden: false,
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit2",
                                  eq: true
                                },
                                properties: {},
                                customClass: "btn-continue-finance"
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
}

export default FinancialDetailsForm;
