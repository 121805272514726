import React from "react";

class CreditReferenceFileUpload extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      financialRatio: [],
      bankRefFile: "",
      tradeRefFile: "",
      tradeRefFile2: "",

      bankRefFileUrl: "",
      personalCreditRefFile: "",
      companyCreditRefFile: "",
      tradeRefFileUrl: "",
      tradeRefFileUrl2: "",
      personalCreditRefFileUrl: "",
      companyCreditRefFileUrl: "",
      termId: "",
      amount: ""
    };
  }
  componentDidMount() {
    this.setState({
      termId: this.props.loanDetails.termId,
      amount: this.props.loanDetails.amount
    });
    if (this.props.fileStatus.bankReference) {
      if (this.props.fileStatus.bankReference.bankReferenceFile) {
        if (
          this.props.fileStatus.bankReference.bankReferenceFile !== null ||
          this.props.fileStatus.bankReference.bankReferenceFile !== undefined
        ) {
          this.setState({
            bankRefFile: this.props.fileStatus.bankReference.bankReferenceFile
              .name,
            bankRefFileUrl: this.props.fileStatus.bankReference
              .bankReferenceFile.url
          });
        } else {
          this.setState({
            bankRefFile: "NA",
            bankRefFileUrl: "NA"
          });
        }
      } else {
        this.setState({
          bankRefFile: "NA",
          bankRefFileUrl: "NA"
        });
      }
    } else {
      this.setState({
        bankRefFile: "NA",
        bankRefFileUrl: "NA"
      });
    }
    if (this.props.fileStatus.tradeReference.length > 0) {
      if (
        this.props.fileStatus.tradeReference[0].personalCreditReportFile !==
        null
      ) {
        this.setState({
          personalCreditRefFile: this.props.fileStatus.tradeReference[0]
            .personalCreditReportFile.name,
          personalCreditRefFileUrl: this.props.fileStatus.tradeReference[0]
            .personalCreditReportFile.url
        });
      } else {
        this.setState({
          personalCreditRefFile: "NA",
          personalCreditRefFileUrl: "#"
        });
      }
      if (
        this.props.fileStatus.tradeReference[0].tradeCompanyCreditReportFile !==
        null
      ) {
        this.setState({
          companyCreditRefFile: this.props.fileStatus.tradeReference[0]
            .tradeCompanyCreditReportFile.name,
          companyCreditRefFileUrl: this.props.fileStatus.tradeReference[0]
            .tradeCompanyCreditReportFile.url
        });
      } else {
        this.setState({
          companyCreditRefFile: "NA",
          companyCreditRefFileUrl: ""
        });
      }

      if (this.props.fileStatus.tradeReference[0].tradeReferenceFile !== null) {
        this.setState({
          tradeRefFile: this.props.fileStatus.tradeReference[0]
            .tradeReferenceFile.name,
          tradeRefFileUrl: this.props.fileStatus.tradeReference[0]
            .tradeReferenceFile.url
        });
      } else {
        this.setState({
          tradeRefFile: "NA",
          tradeRefFileUrl: ""
        });
      }
      if (this.props.fileStatus.tradeReference[1]) {
        if (
          this.props.fileStatus.tradeReference[1].tradeReferenceFile !== null
        ) {
          this.setState({
            tradeRefFile2: this.props.fileStatus.tradeReference[1]
              .tradeReferenceFile.name,
            tradeRefFileUrl2: this.props.fileStatus.tradeReference[1]
              .tradeReferenceFile.url
          });
        } else {
        }
      } else {
        this.setState({
          tradeRefFile2: "NA",
          tradeRefFileUrl2: ""
        });
      }
    } else {
      this.setState({
        tradeRefFile: "NA",
        personalCreditRefFile: "NA",
        companyCreditRefFile: "NA",
        companyCreditRefFileUrl: "",
        tradeRefFileUrl: "",
        personalCreditRefFileUrl: ""
      });
    }
  }
  displayTable() {
    if (this.state.termId == 1) {
      if (this.state.amount < 300001) {
        return (
          <tbody>
            <tr>
              <td>Company Credit Report</td>

              {this.state.companyCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.companyCreditRefFileUrl}>
                    {this.state.companyCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Personal Credit Report</td>

              {this.state.personalCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.personalCreditRefFileUrl}>
                    {this.state.personalCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File</td>
              {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                  <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>

                } */}
              {this.state.tradeRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl}>
                    {this.state.tradeRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
          </tbody>
        );
      } else if (this.state.amount > 300000 && this.state.amount <= 1000000) {
        return (
          <tbody>
            <tr>
              <td>Company Credit Report</td>

              {this.state.companyCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.companyCreditRefFileUrl}>
                    {this.state.companyCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Personal Credit Report</td>

              {this.state.personalCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.personalCreditRefFileUrl}>
                    {this.state.personalCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File 1</td>
              {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                    <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>
  
                  } */}
              {this.state.tradeRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl}>
                    {this.state.tradeRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File 2</td>
              {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                    <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>
  
                  } */}
              {this.state.tradeRefFile2 !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl2}>
                    {this.state.tradeRefFile2}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
          </tbody>
        );
      } else if (this.state.amount >= 1000001) {
        return (
          <tbody>
            <tr>
              <td>Company Credit Report</td>

              {this.state.companyCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.companyCreditRefFileUrl}>
                    {this.state.companyCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Personal Credit Report</td>

              {this.state.personalCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.personalCreditRefFileUrl}>
                    {this.state.personalCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File 1</td>
              {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                    <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>
  
                  } */}
              {this.state.tradeRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl}>
                    {this.state.tradeRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File 2</td>
              {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                    <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>
  
                  } */}
              {this.state.tradeRefFile2 !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl2}>
                    {this.state.tradeRefFile2}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Bank Reference</td>

              {this.state.bankRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.bankRefFileUrl}>
                    {this.state.bankRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
          </tbody>
        );
      }
    } else {
      return (
        <tbody>
          <tr>
            <td>Bank Reference</td>

            {this.state.bankRefFile !== "NA" ? (
              <td>
                {" "}
                <a href={this.state.bankRefFileUrl}>{this.state.bankRefFile}</a>
              </td>
            ) : (
              <td>NA</td>
            )}
          </tr>
          <tr>
            <td>Company Credit Report</td>

            {this.state.companyCreditRefFile !== "NA" ? (
              <td>
                {" "}
                <a href={this.state.companyCreditRefFileUrl}>
                  {this.state.companyCreditRefFile}
                </a>
              </td>
            ) : (
              <td>NA</td>
            )}
          </tr>
          <tr>
            <td>Personal credit report</td>
            {/* {this.props.fileStatus.tradeReference[0].tradeReferenceFile?
                  <td> <a href={this.props.fileStatus.tradeReference[0].tradeReferenceFile.url} download>{this.props.fileStatus.tradeReference[0].tradeReferenceFile.name}</a></td>:<td>NA</td>

                } */}
            {this.state.personalCreditRefFile !== "NA" ? (
              <td>
                {" "}
                <a href={this.state.personalCreditRefFileUrl}>
                  {this.state.personalCreditRefFile}
                </a>
              </td>
            ) : (
              <td>NA</td>
            )}
          </tr>
        </tbody>
      );
    }
  }
  render() {
    return (
      <div className="panel panel-default mt-4 financial-details">
        <div className="panel-heading credit-file-upload">
          <i className="fa fa-user pr-1"></i> List of files that was uploaded
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {this.displayTable()}
          {/* <tbody>
            <tr>
              <td>Bank Reference</td>

              {this.state.bankRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.bankRefFileUrl}>
                    {this.state.bankRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Credit Report</td>

              {this.state.companyCreditRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.companyCreditRefFileUrl}>
                    {this.state.companyCreditRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
            <tr>
              <td>Trade Reference File</td>
             
              {this.state.tradeRefFile !== "NA" ? (
                <td>
                  {" "}
                  <a href={this.state.tradeRefFileUrl}>
                    {this.state.tradeRefFile}
                  </a>
                </td>
              ) : (
                <td>NA</td>
              )}
            </tr>
          </tbody> */}
        </table>
      </div>
    );
  }
}

export default CreditReferenceFileUpload;
