import React from "react";
import { get } from "../../../../../shared/services/rest-service";
class EligibilityStatus extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      eligibilityStatus: false,
      loanId: "",
      eligibiltyStatusN: [],
      loanDetails: {}
    };
    // if (this.props.loan == undefined) {
    //   var loan_id = localStorage.getItem("LoanId");
    //   this.setState({
    //     loanId: loan_id
    //   });
    // } else {
    //   this.setState({
    //     loanId: this.props.loan.loan_id
    //   });
    // }
  }
  // async componentWillMount() {
  //   debugger;
  //   var loanID;
  //   if (this.props.loan == undefined) {
  //     loanID = localStorage.getItem("LoanId");
  //   } else {
  //     loanID = this.props.loan.loan_id;
  //   }

  //   const getLoanDetails = await get(`loan/getLoanDetails/${loanID}`, {});
  //   if (getLoanDetails.CreditScore.EligibilityStatus) {
  //     this.setState({
  //       eligibilityStatus: getLoanDetails.CreditScore.EligibilityStatus,
  //       eligibiltyStatusN: getLoanDetails.EligibiltyStatus

  //          });
  //   }
  //   this.setState({
  //     eligibiltyStatusN: getLoanDetails.EligibiltyStatus
  //   });
  // }
  async componentDidMount() {
    debugger;
    var loanID;
    if (this.props.loan == undefined) {
      loanID = localStorage.getItem("LoanId");
    } else {
      loanID = this.props.loan.loan_id;
    }

    const getLoanDetails = await get(`loan/getLoanDetails/${loanID}`, {});
    if (getLoanDetails.CreditScore.EligibilityStatus) {
      this.setState({
        eligibilityStatus: getLoanDetails.CreditScore.EligibilityStatus,
        eligibiltyStatusN: getLoanDetails.EligibilityStatus,
        loanDetails: getLoanDetails
      });
    }
    this.setState({
      loanDetails: getLoanDetails,
      eligibiltyStatusN: getLoanDetails.EligibiltyStatus
    });
  }

  render() {
    const eligibiltyStatusN = this.state.eligibiltyStatusN;

    if (this.state.loanDetails.loanDetails) {
      const termId = this.state.loanDetails.loanDetails.termId;
      const loanAmount = this.state.loanDetails.loanDetails.amount;
    }

    return (
      <div>
        {this.state.eligibilityStatus ? (
          <div className="file-upload-status">
            <h4 className="head-tittle mt-4">Eligibility Status</h4>
            <hr className="mt-1" />
            <div className="file-upload-content">
              <ul className="list-group mb-5">
                {this.state.loanDetails.EligibiltyStatus.map((item, i) => (
                  <li
                    className="list-group-item d-flex align-items-center"
                    key={i}
                  >
                    {item.status === true ? (
                      <span className="file-upload-bg-green">
                        <i className="icon-custom icon-bg-green icon-line icon-check"></i>{" "}
                      </span>
                    ) : (
                      <span className="file-upload-bg-red">
                        <i className="icon-custom icon-bg-red icon-line icon-close"></i>{" "}
                      </span>
                    )}
                    <p className="file-upload-name" key={i}>
                      {item.text}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </div>

      // <div className="file-upload-status">
      //   {this.state.eligibilityStatus ? (
      //     <div>
      //       <h4 className="head-tittle mt-4">Eligibility Status</h4>
      //       <hr className="mt-1" />
      //       <div className="file-upload-content">
      //         <ul className="list-group mb-5">
      //           <li className="list-group-item d-flex align-items-center">
      //             <span className="file-upload-bg-green">
      //               <i className="icon-custom icon-bg-green icon-line icon-check"></i>
      //             </span>
      //             <p className="file-upload-name">Financial File - 2018</p>
      //           </li>
      //           <li className="list-group-item d-flex align-items-center">
      //             <span className="file-upload-bg-green">
      //               <i className="icon-custom icon-bg-green icon-line icon-check"></i>
      //             </span>
      //             <p className="file-upload-name">Audited Financials - 2018</p>
      //           </li>
      //           <li className="list-group-item d-flex align-items-center">
      //             <span className="file-upload-bg-green">
      //               <i className="icon-custom icon-bg-green icon-line icon-check"></i>
      //             </span>
      //             <p className="file-upload-name">Audited Financials - 2018</p>
      //           </li>
      //           <li className="list-group-item d-flex align-items-center">
      //             <span className="file-upload-bg-red">
      //               <i className="icon-custom icon-bg-red icon-line icon-close"></i>
      //             </span>
      //             <p className="file-upload-name">Credit Report File</p>
      //           </li>
      //           <li className="list-group-item d-flex align-items-center">
      //             <span className="file-upload-bg-red">
      //               <i className="icon-custom icon-bg-red icon-line icon-close"></i>
      //             </span>
      //             <p className="file-upload-name">Bank Reference File</p>
      //           </li>
      //         </ul>
      //       </div>
      //     </div>
      //   ) : null}
      // </div>
    );
  }
}

export default EligibilityStatus;
