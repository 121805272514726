const ResetPasswordForm = (props) => {
  const { t } = props;
  return {
    "type": "form",
    "components": [
      {
        "type": "password",
        "persistent": true,
        "protected": true,
        "suffix": "",
        "prefix": "",
        "placeholder": "New password",
        "key": "newPassword",
        "lockKey": true,
        "label": t("New password"),
        "inputType": "password",
        "tableView": false,
        "input": true,
        "autofocus": false,
        "hidden": false,
        "clearOnHide": true,
        "labelPosition": "top",
        "tags": [],
        "validate": {
          "required": true,
          "minLength": "8",
          "maxLength": "20",
          "pattern": "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
          "custom": "",
          "customPrivate": false
        },
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {},
        "hideLabel": false
      },
      {
        "type": "password",
        "persistent": true,
        "protected": true,
        "suffix": "",
        "prefix": "",
        "placeholder": t("Confirm password"),
        "key": "confirmPassword",
        "lockKey": true,
        "label": t("Confirm password"),
        "inputType": "password",
        "tableView": false,
        "input": true,
        "autofocus": false,
        "hidden": false,
        "clearOnHide": true,
        "labelPosition": "top",
        "tags": [],
        "validate": {
          "required": true,
          "minLength": "8",
          "maxLength": "20",
          "pattern": "",
          "custom": "valid = (input == '{{ newPassword }}') ? true : 'Passwords must match'",
          "customPrivate": false
        },
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {},
        "hideLabel": false
      },
      {
        "clearOnHide": false,
        "label": "Columns",
        "input": false,
        "tableView": false,
        "key": "columns",
        "columns": [
          {
            "components": [
              {
                "type": "button",
                "theme": "primary",
                "disableOnInvalid": true,
                "action": "submit",
                "block": false,
                "rightIcon": "",
                "leftIcon": "",
                "size": "md",
                "key": "submit",
                "tableView": false,
                "label": t("Submit"),
                "input": true,
                "autofocus": false
              }
            ],
            "width": 3,
            "offset": 0,
            "push": 0,
            "pull": 0
          }
        ],
        "type": "columns",
        "hideLabel": true,
        "tags": [],
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {}
      }
    ],
    "title": "Reset Password",
    "name": "resetPassword"
  };
}

export default ResetPasswordForm;