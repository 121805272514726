let Config = {
  url: "https://preprod-api.economicfunding.com/"
  // url:
  // url:
  //   "http://f370b65954099abcf2dac29bda291b95-963813876.us-east-1.elb.amazonaws.com/"preprod
  // "http://81260fb6-default-devalb-a4b5-1576481990.us-east-1.elb.amazonaws.com/"test
  // url: 'http://81260fb6-default-devalb-a4b5-192636668.us-east-1.elb.amazonaws.com/'
};

export default Config;
