const setUser = (data) => {
    return {
        type: 'SETUSERDETAILS',
        data: data
    }
}

const setUserRole = (data) => {
    return {
        type: 'SETUSERROLE',
        data: data
    }
}

export { 
    setUser,
    setUserRole
};