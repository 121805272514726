import React from "react";
import { get } from "../../../../../shared/services/rest-service";

class FinancialRatios extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      financialRatio: []
    };
  }

  async componentDidMount() {
    const getLoanDetails = await get(
      `loan/getLoanDetails/${this.props.loan.loan_id}`,
      {}
    );
    this.setState({ financialRatio: getLoanDetails.FinancialRatio });
  }

  render() {
    const financialRatioData = this.state.financialRatio.length;
    return (
      <div>
        {localStorage.getItem("userRole") !== "ROLE_USER" ? (
          <div className="panel panel-default mt-4 financial-details">
            <div className="panel-heading financial-title">
              Financial Ratios
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> </th>
                  {this.state.financialRatio.map((item, i) => (
                    <th key={i}>{item.year}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Operating Earning / Short Term Liabilities</td>

                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>
                          {item.OperatingEarningShortTermLiabilities}
                        </th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}
                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.OperatingEarningShortTermLiabilities}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Operating Earning / Total Assets</td>

                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>{item.OperatingEarningTotalAssets}</th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}

                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.OperatingEarningTotalAssets}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Operating Earning / Long Term Liabilities</td>
                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>
                          {item.OperatingEarningLongTermLiabilities}
                        </th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}
                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.OperatingEarningLongTermLiabilities}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Net Income / Equity (ROE)</td>

                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>{item.NetIncomeEquity_ROE}</th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}
                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.NetIncomeEquity_ROE}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Net Income / Total Assets</td>
                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>{item.NetIncomeTotalAssets}</th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}
                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.NetIncomeTotalAssets}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Equity / TotalAssets</td>
                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>{item.EquityTotalAssets}</th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}

                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.EquityTotalAssets}</td>)
                            } */}
                </tr>
                <tr>
                  <td>Current Ratio</td>

                  {financialRatioData > 0 ? (
                    [
                      this.state.financialRatio.map((item, i) => (
                        <th key={i}>{item.CurrentRatio}</th>
                      ))
                    ]
                  ) : (
                    <td>NA</td>
                  )}
                  {/* {
                                this.state.financialRatio.map((item, i) => <td key={i}>{item.CurrentRatio}</td>)
                            } */}
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}

export default FinancialRatios;
