import React from 'react';
import Loader from 'react-loader-spinner';

export const LoaderLarge = () => (
  <div style={{
    width: "200px",
	height: "300px",
	
	
	position: "absolute",
	top:"0",
	bottom: "0",
	left: "0",
	right: "0",
  	
	margin: "auto",
  }}>
    <Loader type="ThreeDots" color="#72c02c" height={100} width={100} />
  </div>
);
