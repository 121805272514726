function getLoanFilterFields(userRole, loanStatus) {
  let showReferrer = true;
  let createLoan = false;
  let removeHiddenSpace = "dispaly-referrer-column";
  if (userRole !== "ROLE_REVIEWER") {
    showReferrer = false;
    removeHiddenSpace = "remove-hidden-space";
  }
  if (userRole !== "ROLE_USER") {
    createLoan = true;
    removeHiddenSpace = "remove-hidden-space";
  }

  return {
    _id: "5e9effb284e04dced6874358",
    type: "form",
    tags: [],
    owner: "5e5687252ea8e6f750d0f9f9",
    components: [
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "ID",
                key: "id",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Borrower",
                key: "borrower",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Loan Amount",
                key: "amount",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Company Name",
                key: "companyName",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns2",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Product",
                key: "product",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Status",
                key: "approvalStatus",
                placeholder: "",
                data: {
                  values: loanStatus,
                  json: "",
                  url: "",
                  resource: "",
                  custom: ""
                },
                dataSrc: "values",
                valueProperty: "",
                defaultValue: "",
                refreshOn: "",
                filter: "",
                authenticate: false,
                template: "<span>{{ item.label }}</span>",
                multiple: false,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                validate: {
                  required: false
                },
                type: "select",
                labelPosition: "top",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
                // "autofocus": false,
                // "input": true,
                // "tableView": true,
                // "inputType": "text",
                // "inputMask": "",
                // "label": "Status",
                // "key": "approvalStatus",
                // "placeholder": "",
                // "prefix": "",
                // "suffix": "",
                // "multiple": false,
                // "defaultValue": "",
                // "protected": false,
                // "unique": false,
                // "persistent": true,
                // "hidden": false,
                // "clearOnHide": true,
                // "spellcheck": true,
                // "validate": {
                // 	"required": false,
                // 	"minLength": "",
                // 	"maxLength": "",
                // 	"pattern": "",
                // 	"custom": "",
                // 	"customPrivate": false
                // },
                // "conditional": {
                // 	"show": "",
                // 	"when": null,
                // 	"eq": ""
                // },
                // "type": "textfield",
                // "labelPosition": "top",
                // "inputFormat": "plain",
                // "tags": [],
                // "properties": {},
                // "lockKey": true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },

          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: "Referrer",
                key: "referrer",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: false,
                hidden: showReferrer,
                clearOnHide: showReferrer,
                spellcheck: false,
                validate: {
                  required: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                class: removeHiddenSpace,
                inputFormat: "plain",
                hideLabel: showReferrer,
                tags: [],
                properties: {},
                lockKey: true,
                customClass: removeHiddenSpace
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },

          {
            components: [
              {
                autofocus: false,
                input: true,
                label: "Filter",
                tableView: false,
                key: "columnsFilter",
                size: "md",
                leftIcon: "",
                rightIcon: "",
                block: false,
                action: "event",
                event: "filter",
                disableOnInvalid: false,
                theme: "primary",
                type: "button",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                customClass: "mrg-top-30"
              }
            ],
            width: 1.5,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                label: "Create loan",
                tableView: false,
                key: "createLoan",
                size: "md",
                leftIcon: "",
                rightIcon: "",
                hidden: createLoan,
                block: false,
                action: "event",
                event: "createLoan",
                disableOnInvalid: false,
                theme: "primary",
                type: "button",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                customClass: "mrg-top-30 mrg-left-20"
              }
            ],
            width: 1.5,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Loan",
    display: "form"
  };
}

export default getLoanFilterFields;
