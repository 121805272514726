import axios from "axios";
import { ToastsStore } from "react-toasts";
import Config from "../../shared/config";

export const get = async url => {
  debugger;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  try {
    const { data } = await axios.get(Config.url + url, { headers: headers });
    return data;
  } catch (err) {
    if (err.response) {
      ToastsStore.error(err.response.data.errors[0].msg);
    } else {
      ToastsStore.error("Something went wrong! Please try again later");
    }
    return err;
  }
};

export const put = async (url, payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  try {
    const { data } = await axios.put(
      Config.url + url,
      payload ? payload : null,
      { headers: headers }
    );
    return data;
  } catch (err) {
    if (err.response) {
      ToastsStore.error(err.response.data.errors[0].msg);
    } else {
      ToastsStore.error("Something went wrong! Please try again later");
    }
    return err;
  }
};

export const post = async (url, payload) => {
  debugger;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  try {
    const { data } = await axios.post(Config.url + url, payload, {
      headers: headers
    });

    return data;
  } catch (err) {
    if (err.response) {
      ToastsStore.error(err.response.data.errors[0].msg);
    } else {
      ToastsStore.error("Something went wrong! Please try again later");
    }
    return err;
  }
};

export const patch = async (url, payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  try {
    const { data } = await axios.patch(Config.url + url, payload, {
      headers: headers
    });
    return data;
  } catch (err) {
    if (err.response) {
      ToastsStore.error(err.response.data.errors[0].msg);
    } else {
      ToastsStore.error("Something went wrong! Please try again later");
    }
    return err;
  }
};

export const getDelete = async (url, payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  try {
    const { data } = await axios.delete(Config.url + url, {
      data: payload,
      headers: headers
    });
    return data;
  } catch (err) {
    if (err.response) {
      ToastsStore.error(err.response.data.errors[0].msg);
    } else {
      ToastsStore.error("Something went wrong! Please try again later");
    }
    return err;
  }
};
