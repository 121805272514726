import React, { useState, useEffect } from "react";
import { Form } from "react-formio";
import UserForm from "./user-form";
import { createUser } from "../services/UserService";
import { get, post } from "../../shared/services/rest-service";
import languageEN from "../../translate/languages/english";
import languageSPA from "../../translate/languages/spanish";
import { useTranslation } from "react-i18next";

const CreateUser = props => {
  const { t } = useTranslation();
  const [userDetail, setUserDetail] = useState({});
  const [title, setTitle] = useState(t("Create User"));

  const handleCreateUser = async ({ data }) => {
    if (props.location.state) {
      await post("register", data);
      props.history.push("/users");
    } else {
      const payload = data;
      delete payload.submit;
      // payload.referrer = '';
      try {
        await createUser(payload);
        props.history.push("/users");
      } catch (err) {
        alert(err);
      }
    }
  };

  const fetchData = async () => {
    if (props.location.state) {
      const getUserDetail = await get(
        "users/getUserDetails/" + props.location.state.id,
        {}
      );
      getUserDetail.result.isEdit = true;
      setUserDetail({ userDetail: getUserDetail.result });
      setTitle(t("Update User"));
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.open("/login", "_self");
    } else {
      fetchData();
    }
  }, []);

  return (
    <section className="clearfix">
      <div className="row no-gutters align-items-center">
        <div className="mainbox col-lg-9 mt-2">
          <div className="mg-lft-100 pad-top-30 headline">
            <h3 className="head-tittle pt-1">{title}</h3>
          </div>
          <div className="g-pa-10 g-mx-30--xl" id="userForm">
            <Form
              submission={{ data: userDetail ? userDetail.userDetail : "" }}
              form={UserForm()}
              onCustomEvent={evt => handleCreateUser(evt)}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateUser;
