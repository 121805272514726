import { get, post } from "../../shared/services/rest-service";
import axios from "axios";

export const login = async requestPayload => {
  const response = await post("login", requestPayload);
  return response;
};

export const register = async (url, payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `JWT ${localStorage.getItem("token")}`
  };
  const { data } = await axios.post(
    //"http://f370b65954099abcf2dac29bda291b95-963813876.us-east-1.elb.amazonaws.com/" + preprod
    "https://preprod-api.economicfunding.com/" + url,
    payload,
    {
      headers: headers
    }
  );
  return data;
};

export const getUsers = async (
  offset = 5,
  limit = 0,
  uName = "",
  enabled = "",
  role = ""
) => {
  const response = get(
    `users/getAllUsers?pagesize=${offset}&pagenumber=${limit}&username=${uName}&enabled=${enabled}&role=${role}`
  );
  return response;
};
export const createUser = async data => {
  const response = post(`users/createUser`, data);
  return response;
};

export const welcomeUser = async () => {
  const response = get("users/welcome");
  return response;
};

export const getUserDetails = async user_id => {
  const response = get(`users/getUserDetails/${user_id}`);
  return response;
};
