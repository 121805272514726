const languageSPA = {
    spa : {
        translation : {
            Login : "Iniciar sesión",
            "All Rights Reserved": "Todos los derechos reservados.",
            "Privacy Policy": "Política de privacidad",
            "Terms of Service": "Términos de servicio",
            Username: "Nombre de usuario",
            Password: "Contraseña",
            "Log In To Your Account": "Ingrese a su cuenta",
            "Personal Details": "Detalles personales",
            "First Name": "Nombre de pila",
            "Last Name": "Apellido",
            "E-Mail": "Correo electrónico",
            "Phone Number": "Número de teléfono",
            Continue: "Seguir",
            "Create User": "Crear usuario",
            "Update User": "Actualizar usuario",
            Role: "Papel",
            "Type to search": "Escribe para buscar",
            "Admin": "Administradora",
            "Reviewer": "Crítica",
            "Approver": "Aprobadora",
            "User": "Usuaria",
            Referrer: "Referencia",
            "Submit": "Enviar",
            "Logout": "Cerrar sesión",
            "Stay signed in": "Mantente conectado",
            "Forget your Password": "Olvidaste tu contraseña ?",
            "no worries": "sin preocupaciones",
            "click here": "haga clic aquí",
            "to reset your password": "restablecer tu contraseña.",
            "User lists": "Listas de usuarios",
            "User management": "Gestión de usuarios",
            Status: "Estado",
            Filter: "Filtrar",
            Name: "Nombre",
            Action: "Acción",
            "Change password": "Cambia la contraseña",
            "New password": "Nueva contraseña",
            "Confirm password": "Confirmar contraseña",

            //Company details

            "Buyer Company": "Empresa compradora",
            "Supplier Credit": "Crédito de proveedor",
            "Supplier is the company supplying goods and/or services and may request credit for its buyers with the required information": "El proveedor es la compañía que suministra bienes y / o servicios y puede solicitar crédito a sus compradores con la información requerida.",
            "Buyer Credit": "Crédito del comprador",
            "Buyer is for a company that is purchasing goods and/or services which will be receiving that credit and information is required on the buyer": "El comprador es para una compañía que está comprando bienes y / o servicios que recibirán ese crédito y se requiere información sobre el comprador.",
            "Credit Term": "Plazo de credito:",
            "Select one": "Seleccione uno",
            "Company Name": "nombre de empresa",
            "Authorized Person": "Persona autorizada",
            "Contact Email": "Email de contacto",
            "Contact Phone": "teléfono de contacto",
            "Address": "Habla a",
            "Incorporated": "Incorporada",
            "Financial Year End": "Fin de año financiero",
            "General Lines Of Business": "Fin de año financiero",
            "Back": "Espalda",
            "Save For Later": "Guardar para más adelante",

            //Loan details
            "Loan Details": "Detalles del préstamo",
            "Supplier is providing the product being financed": "El proveedor está proporcionando el producto que se está financiando.",
            "Buyer is purchasing the product being financed": "El comprador está comprando el producto que se financia.",
            "Product Type": "tipo de producto",
            "Purchased Amount": "Cantidad comprada",
            "Buyer Country": "País del comprador",
            "Seller Country": "País del vendedor",
            "Loan Amount": "Monto del préstamo",
            "Loan Period": "Periodo de préstamo",
            "Product requesting to purchase": "Producto que solicita comprar",

            //Financial details
            "Financial Details": "Detalles financieros",
            "Current Financial Year": "Año financiero actual",
            "Company Accounts": "Cuentas de empresa",
            "Interim": "Provisional",
            "Upload a File": "Cargar un archivo",
            "Operating Profit": "Beneficio operativo",
            "Net Income": "Lngresos netos",
            "Net Worth": "Valor neto",
            "Total Liabilities": "Responsabilidad total",
            "Debt Service": "Servicio de deuda",
            "Net Cash from Operations": "Efectivo neto de operaciones",
            "Financial Year": "Año financiero",
            "Last Financial Year": "Último año financiero",
            "2 Year Ago Financial Year": "Hace 2 años, año financiero",

            //Credit reference

            "Credit Reference": "Referencia crediticia",
            "Bank Reference": "Referencia bancaria",
            "Company Credit Report": "Informe de crédito de la compañía",
            "Upload File": "Subir archivo",
            "Name of company giving reference": "Nombre de la empresa que da la referencia",
            "Years of experience with company": "Años de experiencia con la empresa",
            "Estimated annual sales to the company": "Ventas anuales estimadas a la empresa",
            "Highest credit within the past 12 months": "Mayor crédito en los últimos 12 meses",
            "Current Outstanding Balance": "Saldo pendiente actual",
            "Credit Relationship": "Relación de crédito",
            "Excellent": "Excelente",

            //Ledger experience

            "Ledger Experience": "Experiencia contable",
            "Have you ever purchased from the supplier on credit terms": "¿Alguna vez ha comprado al proveedor en términos de crédito?",
            "Has the (supplier or buyer) ever been over 60 days past due over the last 2 years": "¿El (proveedor o comprador) ha tenido más de 60 días de atraso en los últimos 2 años?",
            "Max Credit Outstanding": "Crédito máximo pendiente",
            "Credit Terms Granted": "Términos de crédito otorgados",
            "Credit Amount": "Monto de crédito"
        }
    }
}

export default languageSPA;