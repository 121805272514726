import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import LoanDetailsForm from "./LoanDetailsForm";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { setLoan } from "../../../shared/services/actions/loan-action";
import { bindActionCreators } from "redux";
import { post, get } from "../../../shared/services/rest-service";
import register from "../../../shared/services/register-user-service";
import SubHeader from "../../../user/sub-header/sub-header";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import { ToastsStore } from "react-toasts";
import { LoaderLarge } from "../../loader";

class LoanDetails extends React.Component {
  creditTermOptions;

  constructor(props) {
    super(props);
    this.state = {
      displayCol: "col-lg-9",
      loanDetails: {},
      displayMenu: false,
      country: [],
      loading: false
    };
    this.creditTermOptions = get("taxonomy/COMPANY_CREDIT_TYPE");
  }

  async componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      if (this.props.location.pathname === "/loan-details") {
        this.setState({
          displayCol: "col-lg-9",
          displayMenu: true,
          loading: false
        });

        if (this.props.data.loanDetails) {
          this.setState({
            loanDetails: this.props.data.loanDetails
          });
        }

        // if(localStorage.getItem("isBack")=="no")
        //       {
        //          // this.props.data.companyDetails={}
        //          this.setState({
        //           loanDetails:{}
        //         })          // this.setState({
        //           //   companyDetails:""
        //           // })
        //       }
      } else {
        this.setState({
          displayCol: "col-lg-12",
          loading: true
        });

        if (this.props.location.state) {
          const getUserDetail = await get(
            "users/getUserDetails/" + this.props.location.state.user_id,
            {}
          );
          if (getUserDetail) {
            this.setState({
              loading: false
            });
          }
          if (getUserDetail.result.role !== "ROLE_USER") {
            this.props.loanDetails.isEdit = true;
            this.setState({ loanDetails: this.props.loanDetails });
          }
          if (getUserDetail.result.role == "ROLE_REVIEWER") {
            this.props.loanDetails.isEdit = false;

            this.setState({ loanDetails: this.props.loanDetails });
            // if (this.props.loanDetails.status == "SUBMITTED") {
            //   if (this.props.loanDetails.amount > 100000)
            //     this.props.data.companyDetails.isEdit = false;
            // }
          }

          if (getUserDetail.result.role === "ROLE_USER") {
            this.setState({ loanDetails: this.props.loanDetails });
            // this.setState({ loanDetails: this.props.loanDetails });
            if (this.props.status == "APPROVED") {
              this.props.loanDetails.isEdit = true;
            }
          }
        }
        if (this.props.data.loanDetails) {
          this.setState({
            loanDetails: this.props.data.loanDetails
          });
        }
      }

      const getCountry = await get("taxonomy/COUNTRY", {});
      this.setState({ country: getCountry, loading: false });
    }
  }

  logit = ({ data }) => {
    debugger;
    if (this.props.location.pathname === "/loan-details") {
      const tempData = JSON.parse(JSON.stringify(data));
      const dataTovalidate = this.props.data;
      tempData.termId = tempData.creditTerm.id;

      tempData.purchaseFromId = tempData.purchaseFrom.Transaltion.defaultValue;
      tempData.purchaseToId = tempData.purchaseTo.Transaltion.defaultValue;
      tempData.creditTypeId = this.props.data.companyDetails.creditTermId;
      if (tempData.creditTerm == 1) {
        if (tempData.amount < 100000) {
          this.props.data.companyDetails.isEdit = false;
        }
        // else {
        //   this.props.data.companyDetails.isEdit = true;
        // }
      }

      tempData.periodId = tempData.period.Transaltion.defaultValue;
      if (localStorage.getItem("CompanyId") != "undefined") {
        tempData.referrer = localStorage.getItem("CompanyId");
      }
      dataTovalidate["loanDetails"] = tempData;
      // dataPayload.creditTerm = dataPayload.terms.Transaltion.defaultValue;
      //  this.props.setLoan(dataPayload);
      this.props.history.push("/financial-details");
      if (localStorage.getItem("CompanyId")) {
        localStorage.removeItem("CompanyId");
      }
    } else {
      const dataPayload = JSON.parse(JSON.stringify(data));
      // dataPayload.creditTerm = dataPayload.creditTerm.id;
      this.props.setLoan(dataPayload);
      let selectedTab = 4;
      this.props.nextTabs(selectedTab);
    }
  };

  handleCustomEvent = event => {
    if (event.type === "back") {
      if (this.props.location.pathname === "/loan-details") {
        this.props.history.push("/company-details");
      } else {
        let selectedTab = 2;
        this.props.nextTabs(selectedTab);
      }
    } else if (event.type === "saveForLater") {
      const dataPayload = JSON.parse(JSON.stringify(event.data));
      // dataPayload.creditTerm = dataPayload.creditTerm.id;
      this.validateData(dataPayload);
      //  this.props.history.push("/financial-details");
    }
  };
  saveValidateData = async dataTovalidate => {
    debugger;
    try {
      const res = await post("loan/save-validate", dataTovalidate);
      if (res.error == null) {
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          const test = await post("loan/save", dataToSave);
          ToastsStore.success(test);
          if (localStorage.getItem("isLoggedIn") == "false") {
            this.props.history.push("/login");
          } else {
            this.props.history.push("/loans");
          }
        } catch (error) {
          alert("cant save data");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  validateData = async data => {
    debugger;
    const tempData = JSON.parse(JSON.stringify(data));

    const dataTovalidate = this.props.data;
    if (tempData.creditTerm == "10000.00") {
      tempData.termId = 1;
    } else {
      tempData.termId = tempData.creditTerm;
    }
    tempData.purchaseFromId = tempData.purchaseFrom.Transaltion.defaultValue;
    tempData.purchaseToId = tempData.purchaseTo.Transaltion.defaultValue;
    tempData.creditTypeId = this.props.data.companyDetails.creditTermId;
    if (localStorage.getItem("CompanyId") != "undefined") {
      tempData.referrer = localStorage.getItem("CompanyId");
    }
    tempData.periodId = tempData.period.Transaltion.defaultValue;
    dataTovalidate["loanDetails"] = tempData;
    if (!localStorage.getItem("token")) {
      try {
        const res = await register(dataTovalidate.personalDetails);
        localStorage.setItem("currentUser", JSON.stringify(res));

        if (res.token) {
          this.saveValidateData(dataTovalidate);
        }
      } catch (error) {
        alert("Some Error");
      }
    }
    // if (!localStorage.getItem('token')) {
    //   try {
    //     await register(dataTovalidate.loanDetails);
    //   } catch (error) {
    //     alert('Some Error');
    //   }
    // }
    else {
      try {
        await post("loan/save-validate", dataTovalidate);
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          const test = await post("loan/save", dataToSave);
          ToastsStore.success(test);
          if (localStorage.getItem("isLoggedIn") == "false") {
            this.props.history.push("/login");
          } else {
            this.props.history.push("/loans");
          }
        } catch (error) {
          alert("cant save data");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  detectChange(event) {
    debugger;
    if (event.changed && event.changed.component.key === "creditTerm") {
      if (document.getElementsByName("data[amount]")[0].value) {
        event.data.amount = "";
        document.getElementsByName("data[amount]")[0].value = "";
      }
    }
  }

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <section className="clearfix">
        {this.state.displayMenu ? <SubHeader /> : null}
        <div className="row no-gutters align-items-center">
          <div className={`mainbox ${this.state.displayCol}`}>
            <div className="mg-lft-100 pad-top-30 headline">
              <h3 className="head-tittle">{t("Loan Details")}</h3>
            </div>
            {loading ? (
              <LoaderLarge />
            ) : (
              <div>
                <div className="ml-5 pad-top-30">
                  <p className="loan-p">
                    {t("Supplier is providing the product being financed.")}
                    {t("Buyer is purchasing the product being financed.")}
                  </p>
                </div>
                <div className="loan-form g-pa-10 g-mx-30--xl">
                  <Form
                    form={LoanDetailsForm(
                      this.props.loanDetails,
                      this.state.country
                    )}
                    submission={{
                      data: this.state.loanDetails ? this.state.loanDetails : ""
                    }}
                    onSubmit={this.logit}
                    onChange={this.detectChange}
                    onCustomEvent={this.handleCustomEvent}
                    options={{
                      language: localStorage.getItem("language")
                        ? localStorage.getItem("language")
                        : "",
                      languages: ["en", "spa"],
                      i18n: {
                        en: languageEN.en.translation,
                        spa: languageSPA.spa.translation
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  console.log("redux store loan details", state);
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProops = dispatch => {
  return bindActionCreators({ setLoan: setLoan }, dispatch);
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProops
  )(withRouter(LoanDetails))
);
