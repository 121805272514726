const PatternForm = (props) => {
    const { t } = props;
    let passworComplexity = t("Password Complexity")
    return {
      "type": "form",
      "components": [
         {
          "key": "columnsColumnsSupplierCredit",
          "label": "Supplier Credit",
          "input": false,
          "tag": "span",
          "attrs": [
            {
              "value": "",
              "attr": ""
            }
          ],
          "className": "nopad",
          "content": "<div>\n<b>"+passworComplexity+":</b>\n<div><li>Password should be minimum 8 Digits</li>\n<li>Password should be Contains at least one capital letter</li>\n<li>Password should be Contains at least one number</li>\n<li>Password should be Contains at least one special character</li>\n<div>\n</div>",
          "type": "htmlelement",
          "hideLabel": true,
          "tags": [],
          "conditional": {
            "show": "",
            "when": null,
            "eq": ""
          },
          "properties": {},
          "customClass": "subheading"
        }
      ],
      "title": "Pattern Form",
      "name": "patternForm"
    };
  }
  
  export default PatternForm;