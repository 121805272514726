function LedgerExperienceForm(props, creditTerm) {
  const disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit1",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];
  return {
    type: "form",
    components: [
      {
        clearOnHide: false,
        key: "panel",
        input: false,
        title: "Ledger Experience",
        theme: "default",
        tableView: false,
        components: [
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumnsColumns2",
                    columns: [
                      {
                        components: [
                          {
                            autofocus: false,
                            input: true,
                            inputType: "checkbox",
                            tableView: true,
                            label: "",
                            dataGridLabel: false,
                            key: "isEdit",
                            defaultValue: true,
                            protected: false,
                            persistent: true,
                            hidden: true,
                            name: "",
                            value: true,
                            clearOnHide: true,
                            validate: {
                              required: false
                            },
                            type: "checkbox",
                            labelPosition: "right",
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {},
                            hideLabel: false
                          },
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label:
                                      "Have you ever purchased from the supplier on credit terms?",
                                    dataGridLabel: false,
                                    key: "isPurchased",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    // "validate": {
                                    //   "required": true,
                                    //   "customMessage": "This field is required"
                                    // },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    tags: [],
                                    conditional: {
                                      show: "",
                                      when: null,
                                      eq: ""
                                    },
                                    properties: {},
                                    hideLabel: false,
                                    customClass: "nopad"
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {},
                            hideLabel: true
                          }
                        ],
                        width: 12,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {},
            hideLabel: true
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns2",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2Columns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    inputType: "checkbox",
                                    tableView: true,
                                    label:
                                      "Has the (supplier or buyer) ever been over 60 days past due over the last 2 years?",
                                    dataGridLabel: false,
                                    key: "isPastDue",
                                    defaultValue: false,
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    name: "",
                                    value: "",
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    type: "checkbox",
                                    labelPosition: "right",
                                    hideLabel: false,
                                    tags: [],
                                    conditional: {
                                      show: "true",
                                      when: "isPurchased",
                                      eq: "true"
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {},
                            hideLabel: true
                          }
                        ],
                        width: 12,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {},
            hideLabel: true
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns2Columns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns2Columns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns2Columns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    key:
                                      "panelColumns2Columns3ColumnsColumnsContent",
                                    label: "Content",
                                    input: false,
                                    tag: "p",
                                    attrs: [
                                      {
                                        value: "",
                                        attr: ""
                                      }
                                    ],
                                    className: "",
                                    content:
                                      "What is the total amount of credi outstanding at anyone time over the last 6 months?",
                                    type: "htmlelement",
                                    hideLabel: true,
                                    tags: [],
                                    conditional: {
                                      show: "true",
                                      when: "isPurchased",
                                      eq: "true"
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {},
                            isNew: false
                          }
                        ],
                        width: 12,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {},
            hideLabel: true
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns4",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns4Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns4ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "Max Credit Outstanding",
                                    key: "creditOutStanding",
                                    placeholder: "Max Credit Outstanding",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "true",
                                      when: "isPurchased",
                                      eq: "true"
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {},
                    isNew: false
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {},
            hideLabel: true
          },
          {
            key: "panelContent",
            label: "Content",
            input: false,
            tag: "p",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "",
            content: "Credit Terms Granted",
            type: "htmlelement",
            tags: [],
            conditional: {
              show: "true",
              when: "isPurchased",
              eq: "true"
            },
            properties: {},
            hideLabel: true
          },
          {
            key: "panelContent2",
            label: "Content",
            input: false,
            tag: "hr",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "horizontal-line",
            content: "",
            type: "htmlelement",
            tags: [],
            conditional: {
              show: "true",
              when: "isPurchased",
              eq: "true"
            },
            properties: {},
            hideLabel: true
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns3",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns3Columns",
                    columns: [
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    inputType: "number",
                                    label: "Credit Amount",
                                    key: "creditAmount",
                                    placeholder: "Credit Amount",
                                    prefix: "$",
                                    suffix: ".00",
                                    defaultValue: "",
                                    protected: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false,
                                      min: "",
                                      max: "",
                                      // "step": "any",
                                      // "integer": "",
                                      // "multiple": "",
                                      custom: ""
                                    },
                                    logic: disabledCondition,
                                    type: "number",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: "true",
                                      when: "isPurchased",
                                      eq: "true"
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            clearOnHide: false,
                            label: "Columns",
                            input: false,
                            tableView: false,
                            key: "panelColumns3ColumnsColumns2",
                            columns: [
                              {
                                components: [
                                  {
                                    autofocus: false,
                                    input: true,
                                    tableView: true,
                                    label: "Credit Term",
                                    key: "creditType.Transaltion.defaultValue",
                                    placeholder: "",
                                    data: {
                                      values: creditTerm,
                                      json: "",
                                      url: "",
                                      resource: "",
                                      custom: ""
                                    },
                                    dataSrc: "values",
                                    valueProperty: "",
                                    defaultValue: "",
                                    refreshOn: "",
                                    filter: "",
                                    authenticate: false,
                                    template: "<span>{{ item.label }}</span>",
                                    multiple: false,
                                    protected: false,
                                    unique: false,
                                    persistent: true,
                                    hidden: false,
                                    clearOnHide: true,
                                    validate: {
                                      required: false
                                    },
                                    logic: disabledCondition,
                                    type: "select",
                                    labelPosition: "top",
                                    tags: [],
                                    conditional: {
                                      show: true,
                                      when: "isPurchased",
                                      eq: true
                                    },
                                    properties: {}
                                  }
                                ],
                                width: 12,
                                offset: 0,
                                push: 0,
                                pull: 0
                              }
                            ],
                            type: "columns",
                            hideLabel: true,
                            tags: [],
                            conditional: {
                              show: "",
                              when: null,
                              eq: ""
                            },
                            properties: {}
                          }
                        ],
                        width: 6,
                        offset: 0,
                        push: 0,
                        pull: 0
                      }
                    ],
                    type: "columns",
                    hideLabel: true,
                    tags: [],
                    conditional: {
                      show: "",
                      when: null,
                      eq: ""
                    },
                    properties: {}
                  }
                ],
                width: 12,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            hideLabel: true,
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {}
          },
          {
            key: "panelColumns5Content",
            label: "Content",
            input: false,
            tag: "hr",
            attrs: [
              {
                value: "",
                attr: ""
              }
            ],
            className: "horizontal-line",
            content: "",
            type: "htmlelement",
            tags: [],
            conditional: {
              show: "true",
              when: "isPurchased",
              eq: "true"
            },
            properties: {},
            hideLabel: true
          },
          {
            clearOnHide: false,
            label: "Columns",
            input: false,
            tableView: false,
            key: "panelColumns7",
            columns: [
              {
                components: [
                  {
                    clearOnHide: false,
                    label: "Columns",
                    input: false,
                    tableView: false,
                    key: "panelColumns6",
                    columns: [
                      {
                        components: [
                          {
                            autofocus: false,
                            input: true,
                            label: "Back",
                            tableView: false,
                            key: "panelColumns6Back",
                            size: "md",
                            leftIcon: "",
                            rightIcon: "",
                            block: false,
                            action: "event",
                            event: "back",
                            disableOnInvalid: false,
                            theme: "success",
                            type: "button",
                            tags: [],
                            conditional: {
                              show: false,
                              when: "isEdit",
                              eq: true
                            },
                            properties: {}
                          }
                        ],
                        width: 4,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            autofocus: false,
                            input: true,
                            label: "Save For Later",
                            tableView: false,
                            key: "panelColumns6SaveForLater",
                            size: "md",
                            leftIcon: "",
                            rightIcon: "",
                            block: false,
                            action: "event",
                            event: "saveForLater",
                            disableOnInvalid: false,
                            hidden: false,
                            theme: "success",
                            type: "button",
                            tags: [],
                            conditional: {
                              show: false,
                              when: "isEdit",
                              eq: true
                            },
                            customClass: "btn-save-ledger",
                            properties: {},
                            style: {
                              "margin-left": "20px"
                            }
                          }
                        ],
                        width: 4,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        components: [
                          {
                            autofocus: false,
                            input: true,
                            label: "Continue",
                            tableView: false,
                            key: "panelColumns6Continue",
                            size: "md",
                            leftIcon: "",
                            rightIcon: "",
                            block: false,
                            action: "submit",
                            disableOnInvalid: false,
                            theme: "success",
                            type: "button",
                            tags: [],
                            conditional: {
                              show: false,
                              when: "isEdit",
                              eq: true
                            },
                            properties: {},
                            customClass: "btn-continue-ledger",
                            style: {
                              "margin-left": "150px"
                            }
                          }
                        ],
                        width: 4,
                        offset: 0,
                        push: 0,
                        pull: 0
                      },
                      {
                        input: true,
                        tableView: true,
                        inputType: "text",
                        inputMask: "",
                        label: "First Name",
                        key: "isShortTerm",
                        placeholder: "",
                        prefix: "",
                        suffix: "",
                        multiple: false,
                        defaultValue: "true",
                        protected: false,
                        unique: false,
                        persistent: true,
                        validate: {
                          required: false,
                          minLength: "",
                          maxLength: "",
                          pattern: "",
                          custom: "",
                          customPrivate: false
                        },
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        type: "textfield",
                        $$hashKey: "object:18",
                        autofocus: false,
                        hidden: true,
                        clearOnHide: true,
                        spellcheck: true,
                        labelPosition: "top",
                        inputFormat: "plain",
                        tags: [],
                        properties: {}
                      }
                    ],
                    type: "columns",
                    tags: [],
                    conditional: {
                      show: "true",
                      when: "isPurchased",
                      eq: "true"
                    },
                    properties: {},
                    hideLabel: true
                  }
                ],
                width: 6,
                offset: 0,
                push: 0,
                pull: 0
              }
            ],
            type: "columns",
            tags: [],
            conditional: {
              show: "",
              when: null,
              eq: ""
            },
            properties: {},
            hideLabel: true
          }
        ],
        type: "panel",
        breadcrumb: "default",
        hideLabel: false,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
}

export default LedgerExperienceForm;
