import React from "react";
import { Form } from "react-formio";
import { login } from "../../services/UserService";
import LoginForm from "./LoginForm";
import "./Login.scss";
import { getLoanDetails } from "../../../loan/services/LoanServices";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setCompany } from "../../../shared/services/actions/company-action";
import { setCredit } from "../../../shared/services/actions/credit-action";
import { setFinance } from "../../../shared/services/actions/finance-action";
import { setLedger } from "../../../shared/services/actions/ledger-action";
import { setLoan } from "../../../shared/services/actions/loan-action";
import { setLoggedIn } from "../../../shared/services/actions/log-in-acion";
import { setUserRole } from "../../../shared/services/actions/user-action";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";

class Login extends React.Component {
  componentWillMount() {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedIn", false);

    this.setState({ loggedIn: false });

    localStorage.removeItem("currentUser");
  }

  onLogin = ({ data }) => {
    const loginData = {
      username: data.username,
      password: data.password
    };
    this.signIn(loginData);
  };

  signIn = async data => {
    const loginResponse = await login(data);
    this.props.setUserRole(loginResponse);
    localStorage.setItem("currentUser", JSON.stringify(loginResponse));
    if (!(loginResponse instanceof Error)) {
      localStorage.setItem("token", loginResponse.token);
      localStorage.setItem("isLoggedIn", true);
      window.open("/loans", "_self");

      // this.props.history.push({
      //   pathname: "/loans"
      //   // state :{
      //   //   role: loginResponse.roleName
      //   // }
      // });
      // if (loginResponse.roleName = 'ROLE_ADMIN') {
      //   // this.props.history.push('/users');

      //   // this.props.history.push('/loans');
      // } else if (loginResponse.roleName = 'ROLE_USER') {
      //   this.getLoanDetails();
      // }
    }
  };

  getLoanDetails = async () => {
    const loanDetails = await getLoanDetails();
    this.setReduxState(loanDetails.result);
  };

  setReduxState = loanDetails => {
    this.props.setLoggedIn(true);
    this.props.setCompany(loanDetails.companyDetails);
    this.props.setLoan(loanDetails.loanDetails);
    this.props.setFinance(loanDetails.financialDetails);
    this.props.setCredit(loanDetails.creditReferenceDetails);
    this.props.history.push("/company-details");
  };

  render() {
    const { t } = this.props;
    return (
      <section className="clearfix g-pa-50">
        <div className="row no-gutters align-items-center">
          <div className="col-lg-4 offset-lg-4">
            <div className="mg-lft-70 pad-top-30 g-mx-70--xl">
              <h3 className="no-underline">{t("Login To Your Account")}</h3>
            </div>
            <div className="g-mx-70--xl" id="loginForm">
              <Form
                form={LoginForm(this.props)}
                onSubmit={this.onLogin}
                options={{
                  language: localStorage.getItem("language")
                    ? localStorage.getItem("language")
                    : "",
                  languages: ["en", "spa"],
                  i18n: {
                    en: languageEN.en.translation,
                    spa: languageSPA.spa.translation
                  }
                }}
              />
            </div>
            <hr />
            <div className="mg-lft-70 g-mx-70--xl">
              <h4>{t("Forget your Password")}</h4>
              {t("no worries")}, please contact administrator
              {/* <span className="green-link" href="/register/forgotPassword">
                {t("click here")}
                </span>{" "}
                {t("to reset your password")} */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setCompany: setCompany,
      setLoan: setLoan,
      setFinance: setFinance,
      setLedger: setLedger,
      setCredit: setCredit,
      setLoggedIn: setLoggedIn,
      setUserRole: setUserRole
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: state.set.loggedIn
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(Login)
);
