import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { messageService } from "../services/message-passing-service";
import efmHeaderLogo from "../../../../assets/images/efm.png.jfif";
import { get } from "../../shared/services/rest-service";
import { withTranslation } from "react-i18next";
import { companyData } from "../../../../../src/assets/brandJson";

import "./Header.scss";

var logo = efmHeaderLogo;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      languages: [],
      firstName: "",
      lastName: "",
      token: null,
      loanDetails: {}
    };
    localStorage.setItem("language", "en");
    let companyId;

    if (window.location.search) {
      companyId = window.location.search.slice(1).split("=")[1];
    }
    localStorage.setItem("CompanyId", companyId);
    for (var i = 0; i < companyData.length; i++) {
      if (companyData[i].id == companyId) {
        logo = companyData[i].src;
      }
    }
  }

  async componentDidMount() {
    this.subscription = messageService.getMessage().subscribe(message => {
      if (message) {
        this.setState({ loggedIn: true });
      } else {
        this.setState({ messages: false });
      }
    });

    //get language details
    const getLanguageDetails = await get("taxonomy/LANGUAGE", {});
    this.setState({ languages: getLanguageDetails });
    if (localStorage.getItem("isLoggedIn") == "true") {
      let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));
      const getUserDetail = await get(
        "users/getUserDetails/" + currentUserDetails.id,
        {}
      );
      this.setState({ loanDetails: getUserDetail.result });
    }
  }
  async componentWillUpdate() {
    if (localStorage.getItem("isLoggedIn") == "true") {
      this.setState({ loggedIn: true });
      let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));

      const getUserDetail = await get(
        "users/getUserDetails/" + currentUserDetails.id,
        {}
      );
      setTimeout(() => {
        this.setState({
          firstName: getUserDetail.result.firstName,
          lastName: getUserDetail.result.lastName
        });
      }, 100);
    }
  }
  logOut() {
    localStorage.removeItem("token");
    localStorage.setItem("isLoggedIn", false);

    this.setState({ loggedIn: false });

    localStorage.removeItem("currentUser");
  }

  logIn() {
    this.setState({ redirect: true });
  }

  handleLanguage(evt) {
    let lng = "";
    if (evt.target.value === 301) {
      lng = "spa";
    } else {
      lng = "en";
    }
    this.props.i18n.changeLanguage(lng, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      localStorage.setItem("language", lng);
    });
  }

  handleLoanList = () => {
    this.props.history.push({
      pathname: "/loans"
    });
  };
  render() {
    const { t } = this.props;
    let isToken = localStorage.getItem("isLoggedIn");
    let currentRoleName = JSON.parse(localStorage.getItem("currentUser"));
    return (
      <header
        id="js-header"
        className="u-header u-shadow-v19"
        data-header-fix-moment="100"
        data-header-fix-effect="slide"
      >
        {isToken == "true" ? (
          <div className="row m-0">
            <div className="header">
              <div className="topbar">
                {/* <div className="container">
                  <ul className="loginbar pull-right">
                    <li className="topbar-devider"></li>
                    <Link onClick={this.logOut.bind(this)} to={"/login"}>
                      <li>{t("Logout")}</li>
                    </Link>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        ) : null
        // <div className="row m-0">
        //   <div className="header">
        //     <div className="topbar">
        //       <div className="container">
        //         <ul className="loginbar pull-right">
        //           <li className="topbar-devider"></li>
        //           <Link to={"/login"}>
        //             <li>{t("Login")}</li>
        //           </Link>
        //           <li className="topbar-devider"></li>
        //           {/* <select id="lang" onChange={this.handleLanguage.bind(this)}>
        // 						{
        // 							this.state.languages.map(function(lan, i){
        // 							return <option key={i} value={lan.value}>{lan.label}</option>
        // 							})
        // 						}
        // 						</select> */}
        //         </ul>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        }

        <div className="u-header__section u-header__section--dark g-py-3">
          <nav className="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
            <div className="container-fluid nav-container">
              <div className="logo-img">
                <a href="/" className="navbar-brand d-flex">
                  {/* {localStorage.getItem("CompanyId") == "1" ? (
                    <img
                      className="header-img"
                      src="https://expcdn.co.uk/www/images/experian_full_colour.svg"
                      alt="Header logo"
                    />
                  ) : (
                    <img
                      className="header-img"
                      src={efmHeaderLogo}
                      alt="Header logo"
                    />
				  )} */}
                  <img className="header-img" src={logo} alt="Header logo" />
                </a>
              </div>

              {isToken == "true" ? (
                <div className="collapse navbar-collapse">
                  {currentRoleName.roleName !== "ROLE_USER" ? null : (
                    <NavLink
                      activeClassName="header-navbar__link--inactive"
                      className="header-navbar__link"
                      to="/"
                      // onClick={ window.open("/",'_blank')}
                      // 			to={{
                      // 				pathname:"",
                      // 				 personalDetails:this.state.loanDetails

                      // 				}}
                      // 				exact
                    >
                      Home
                    </NavLink>
                  )}
                  <NavLink
                    activeClassName="header-navbar__link--active"
                    className="header-navbar__link"
                    to="/loans"
                    onClick={() => {
                      this.handleLoanList();
                    }}
                  >
                    Loan list
                  </NavLink>
                  {currentRoleName.roleName === "ROLE_ADMIN" && (
                    <NavLink
                      activeClassName="header-navbar__link--active"
                      className="header-navbar__link"
                      to="/users"
                    >
                      User management
                    </NavLink>
                  )}
                </div>
              ) : null}
              {isToken == "true" ? (
                <div class="dropdown">
                  <button class="dropbtn">
                    <div>
                      <i className="profile-img fa fa-user-circle-o"></i>
                      <span className="profile-text ml-2 text-capitalize">{`${this.state.firstName} ${this.state.lastName}`}</span>
                      <span class="down-caret"></span>
                    </div>
                  </button>
                  <div class="dropdown-content">
                    <a href="/login" onClick={this.logOut.bind(this)}>
                      {" "}
                      {t("Logout")}
                    </a>
                    <a href="/change-password">Change Password</a>
                  </div>
                </div>
              ) : (
                <div className="row m-0">
                  <div className="header">
                    <div className="topbar">
                      <div className="container">
                        <ul className="loginbar pull-right">
                          <li className="topbar-devider"></li>
                          <Link to={"/login"}>
                            <li>{t("Login")}</li>
                          </Link>
                          <li className="topbar-devider"></li>
                          {/* <select id="lang" onChange={this.handleLanguage.bind(this)}>
                          {      
                            this.state.languages.map(function(lan, i){
                            return <option key={i} value={lan.value}>{lan.label}</option>
                            })
                          }
                          </select> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )

              //   <div class="dropdown">
              //   <button class="dropbtn">
              //     <div>
              //       <i className="profile-img fa fa-user-circle-o"></i>
              //       <span className="profile-text ml-2 text-capitalize">{`${this.state.firstName} ${this.state.lastName}`}</span>
              //       <span class="down-caret"></span>
              //     </div>
              //   </button>
              //   <div class="dropdown-content">
              //     <a href="/login" onClick={this.logOut.bind(this)}>
              //       {" "}
              //       {t("Logout")}
              //     </a>
              //     <a href="/change-password">Change Password</a>
              //   </div>
              // </div>
              }
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default withTranslation()(withRouter(Header));
