import React from "react";
import { get } from "../../../../../shared/services/rest-service";

class LoanEligibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loanEligibility: {},
      loanId: "",
      termid: ""
    };
  }

  async componentDidMount() {
    debugger;
    var loanID;
    if (this.props.loan == undefined) {
      loanID = localStorage.getItem("LoanId");
    } else {
      loanID = this.props.loan.loan_id;
    }

    const getLoanDetails = await get(`loan/getLoanDetails/${loanID}`, {});
    this.setState({
      loanEligibility: getLoanDetails.CreditScore,
      termid: getLoanDetails.loanDetails.termId
    });
    
  }

  render() {
    return (
      <div>
        {localStorage.getItem("userRole") !== "ROLE_USER" ? (
          <div className="loan-elg">
            <h4 className="head-tittle mt-4">Loan Eligibility</h4>

            <div className="mt-3 loan-elg-wrap">
              <div className="ml-5 mt-3">
                <div className="ml-5 loan-elg-content">
                  <i className="fa fa-cc-mastercard rounded"></i>
                </div>
                <div className="mt-3 ml-3">
                  <h4>{this.state.loanEligibility.RequestedLoanAmount}</h4>
                </div>
                <div>Requested Loan Amount</div>
              </div>
              <div className="ml-5 mt-3">
                <div className="ml-5 loan-elg-content">
                  <i className="fa fa-cc-mastercard rounded"></i>
                </div>
                <div className="mt-3 ml-3">
                  <h4>{this.state.loanEligibility.EligibleLoanAmount}</h4>
                </div>
                <div>Eligible Loan Amount</div>
              </div>
              <div className="ml-5 mt-3">
                <div className="ml-5 loan-elg-content">
                  <i className="fa fa-cc-mastercard rounded"></i>
                </div>
                <div className="mt-3 ml-3">
                  <h4>{this.state.loanEligibility.JobsCreated}</h4>
                </div>
                <div>Jobs Created</div>
              </div>
              {this.state.loanEligibility.RequestedLoanAmount >= 100000 &&
              this.state.termid == 1 ? (
                <div className="ml-5 mt-3">
                  <div className="ml-5 loan-elg-content">
                    <i className="fa fa-line-chart rounded"></i>
                  </div>
                  <div className="mt-3 ml-3">
                    <h4>{this.state.loanEligibility.EBITDAThreshold}</h4>
                  </div>
                  <div>EBITDA Threshold</div>
                </div>
              ) : (
                [
                  this.state.termid != 1 ? (
                    <div className="ml-5 mt-3">
                      <div className="ml-5 loan-elg-content">
                        <i className="fa fa-line-chart rounded"></i>
                      </div>
                      <div className="mt-3 ml-3">
                        <h4>{this.state.loanEligibility.EBITDAThreshold}</h4>
                      </div>
                      <div>EBITDA Threshold</div>
                    </div>
                  ) : (
                    <div className="ml-5 mt-3">
                      <div className="ml-5 loan-elg-content">
                        <i className="fa fa-line-chart rounded"></i>
                      </div>
                      <div className="mt-3 ml-3">
                        <h4>
                          {this.state.loanEligibility.LedgerExperienceThreshold}
                        </h4>
                      </div>
                      <div>Ledger Experience Threshold</div>
                    </div>
                  )
                ]
              )}
              {this.state.loanEligibility.RequestedLoanAmount >= 100000 ? (
                <div className="mt-3 ml-5 d-flex flex-column justify-content-center">
                  <div className="ml-5 loan-elg-content">
                    <i className="fa fa-line-chart rounded"></i>
                  </div>
                  <div className="mt-3 ml-3">
                    <h4>{this.state.loanEligibility.NetworthThreshold}</h4>
                  </div>
                  <div>Networth Threshold</div>
                </div>
              ) : (
                [
                  this.state.termid != 1 ? (
                    <div className="mt-3 ml-5 d-flex flex-column justify-content-center">
                      <div className="ml-5 loan-elg-content">
                        <i className="fa fa-line-chart rounded"></i>
                      </div>
                      <div className="mt-3 ml-3">
                        <h4>{this.state.loanEligibility.NetworthThreshold}</h4>
                      </div>
                      <div>Networth Threshold</div>
                    </div>
                  ) : (
                    <div className="mt-3 ml-5 d-flex flex-column justify-content-center">
                      <div className="ml-5 loan-elg-content">
                        <i className="fa fa-line-chart rounded"></i>
                      </div>
                      <div className="mt-3 ml-3">
                        <h4>
                          {this.state.loanEligibility.TradeReferenceThreshold}
                        </h4>
                      </div>
                      <div>Trade Reference Threshold</div>
                    </div>
                  )
                ]
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LoanEligibility;
