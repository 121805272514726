import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from "i18next-xhr-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import languages from './languages';

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "spa",
    resources : languages,

    keySeparator: false,

    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;