import React from "react";
import { Redirect } from "react-router";
import { Form } from "react-formio";
import LedgerExperienceForm from "./LedgerExperienceForm";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { setLedger } from "../../../shared/services/actions/ledger-action";
import { bindActionCreators } from "redux";
import { post } from "../../../shared/services/rest-service";
import register from "../../../shared/services/register-user-service";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";

class LedgerExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: "" };
  }
  componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    }
  }

  logit = ({ data }) => {
    this.props.setLedger(data);
    this.props.history.push("/financial-details");
  };
  handleCustomEvent = event => {
    if (event.type === "back") {
      this.props.history.push("/loan-details");
    } else if (event.type === "saveForLater") {
      this.validateData(event.data);
    }
  };

  validateData = async data => {
    const tempData = JSON.parse(JSON.stringify(data));
    const dataTovalidate = this.props.data;
    //tempData.creditTermId = tempData.creditTermId.value;
    dataTovalidate["ledgerDetails"] = tempData;
    if (!localStorage.getItem("token")) {
      try {
        await register(dataTovalidate.userDetails);
      } catch (error) {
        alert("Some Error");
      }
    }
    try {
      await post("loan/save-validate", dataTovalidate);
      try {
        const dataToSave = new FormData();
        dataToSave.append("data", JSON.stringify(dataTovalidate));
        dataToSave.append("isValid", "true");
        await post("loan/save", dataToSave);
      } catch (error) {
        alert("cant save data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t } = this.props;
    if (this.state.token) {
      return <Redirect to="/welcome" />;
    }
    return (
      <section className="clearfix">
        <div className="row no-gutters align-items-center">
          <div className="mainbox col-lg-9">
            <div className="mg-lft-100 pad-top-30 headline">
              <h3 className="head-tittle">{t("Ledger Experience")}</h3>
            </div>
            <div className="formcss g-pa-10 g-mx-30--xl">
              <Form
                submission={{
                  data: this.props.data ? this.props.data.ledgerDetails : ""
                }}
                form={LedgerExperienceForm(this.props)}
                onSubmit={this.logit}
                onCustomEvent={this.handleCustomEvent}
                options={{
                  language: localStorage.getItem("language")
                    ? localStorage.getItem("language")
                    : "",
                  languages: ["en", "spa"],
                  i18n: {
                    en: languageEN.en.translation,
                    spa: languageSPA.spa.translation
                  }
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProops = dispatch => {
  return bindActionCreators({ setLedger: setLedger }, dispatch);
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProops
  )(LedgerExperience)
);
