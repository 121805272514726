const setLoan = (data) => {
    return {
        type: 'SETLOAN',
        data: data
    }
}

const setChangeStatus = (data) => {
    return {
        type: 'SETCHANGESTATUS',
        data: data
    }
}

export { 
    setLoan,
    setChangeStatus
};