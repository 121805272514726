import React from "react";
import { Form } from "react-formio";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { getUsers } from "../services/UserService";
import filterForm from "./filter-form";
import { post } from "../../shared/services/rest-service";
import languageEN from "../../translate/languages/english";
import languageSPA from "../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastsStore } from "react-toasts";
import "./user-list.scss";
import { get } from "../../shared/services/rest-service";

class UserList extends React.Component {
  userList = [];

  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      count: 0,
      pageNum: 0,
      pageSize: 5,
      filters: {},
      userRole: {}
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      await this.getUserList();
      await this.getUserRole();
    }
  }
  getUserRole = async () => {
    debugger;
    const getUserRole = await get("getRoles", {});

    this.setState({ userRole: getUserRole });
  };
  getUserList = async () => {
    debugger;
    const userList = await getUsers();
    this.setState({
      rowData: userList.rows,
      count: userList.count,
      pageNum: 0,
      pageSize: 5,
      filters: { data: { uName: "", status: "" } }
    });
  };

  pageChanged = async pageNum => {
    const userList = await getUsers(this.state.pageSize, pageNum);
    this.setState({
      rowData: userList.rows,
      count: userList.count,
      pageNum: pageNum,
      pageSize: this.state.pageSize
    });
  };

  pageSizeChanged = async (pageSize, pageIndex) => {
    const userList = await getUsers(pageSize, pageIndex);
    this.setState({
      rowData: userList.rows,
      count: userList.count,
      pageNum: pageIndex,
      pageSize: pageSize
    });
  };

  handleCustomEvent = event => {
    if (event.type === "filter") {
      this.filter(event);
    } else if (event.type === "createUser") {
      this.props.history.push("/user");
    }
  };

  filter = async data => {
    const uName = data.data.uName;
    const status = data.data.status;
    const role = data.data.role;
    const userList = await getUsers(
      this.state.pageSize,
      this.state.pageNum,
      uName,
      data.data.status,
      role
    );
    setTimeout(() => {
      this.setState({
        rowData: userList.rows,
        count: userList.count,
        pageNum: this.state.pageNum,
        pageSize: this.state.pageSize,
        filters: { data: { uName: uName, status: status } }
      });
    }, 100);
  };

  editRow = id => {
    this.props.history.push({
      pathname: `/user/${id}`,
      state: {
        id: id
      }
    });
  };

  handleClickBasic(id) {
    confirmAlert({
      title: "Confirm to Deactivate",
      message: "Are you sure to Deactivate this User.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await post("users/deactivateUser?id=" + id, {});
            window.open("/users", "_self");
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  async handleClickResetPass(username) {
    confirmAlert({
      title: "Reset Password",
      message: "Are you sure to Reset password of " + username,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await post("users/resetPasswordToken?username=" + username, {});
            ToastsStore.success("Reset Password link sent to your mail id");
          }
        },
        {
          label: "No"
        }
      ]
    });
  }

  disabledUser = (enable, id, username) => {
    if (enable) {
      return (
        <div>
          <button
            className="btn btn-info"
            onClick={() => {
              this.editRow(id);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="ml-2 btn btn-danger"
            onClick={() => {
              this.handleClickBasic(id);
            }}
          >
            <i className="fa fa-eye-slash"></i>
          </button>
          <button
            className="ml-2 btn btn-warning"
            onClick={() => {
              this.handleClickResetPass(username);
            }}
          >
            <i className="fa fa-key"></i>
          </button>
        </div>
      );
    }
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        Header: t("Name"),
        accessor: "firstName",
        style: {
          textAlign: "start",
          whiteSpace: "unset"
        },
        Cell: props => {
          const name = `${props.original.firstName} ${props.original.lastName}`;
          return name;
        }
      },
      {
        Header: t("Username"),
        accessor: "username",
        style: {
          textAlign: "start",
          whiteSpace: "unset"
        }
      },
      {
        Header: "Role",
        accessor: "role",
        sortable: false,
        style: {
          textAlign: "start",
          whiteSpace: "unset"
        }
      },
      {
        Header: t("Referrer"),
        accessor: "referrer",
        sortable: false,
        style: {
          textAlign: "start",
          whiteSpace: "unset"
        }
      },
      {
        Header: t("Action"),
        sortable: false,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        style: {
          textAlign: "start"
        },
        Cell: props => {
          return (
            <div>
              {this.disabledUser(
                props.original.enabled,
                props.original.id,
                props.original.username
              )}
            </div>
          );
        }
      }
    ];
    return (
      <div className="mt-5">
        <div className="container-fluid filter-form">
          <div className="form-title">
            <h3 className="head-tittle pad-top-30">{t("User management")}</h3>
            <div className="filter-name filter-user">
              <Form
                form={filterForm(this.props, this.state.userRole)}
                submission={this.state.filters}
                onCustomEvent={this.handleCustomEvent}
                options={{
                  language: localStorage.getItem("language")
                    ? localStorage.getItem("language")
                    : "",
                  languages: ["en", "spa"],
                  i18n: {
                    en: languageEN.en.translation,
                    spa: languageSPA.spa.translation
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="widgets_div">
          <div className="contents">
            <div className="icon_div">
              <span>
                <b style={{ marginRight: "5px" }}> Legends</b>
              </span>
            </div>
            <div className="icon_div">
              <span>
                <button className="btn btn-info btnsize">
                  <i className="fa fa-pencil"></i>
                </button>
              </span>
            </div>
            <div className="text_div">
              <span>Edit User</span>
            </div>
            <div className="icon_div">
              <span>
                <button className="ml-2 btn btn-danger btnsize">
                  <i className="fa fa-eye-slash"></i>
                </button>
              </span>
            </div>
            <div className="text_div">
              <span>Deactivate User</span>
            </div>
            <div className="icon_div">
              <span>
                <button className="ml-2 btn btn-warning btnsize">
                  <i className="fa fa-key"></i>
                </button>
              </span>
            </div>
            <div className="text_div">
              <span>Reset Password</span>
            </div>
          </div>
        </div>
        <div
          className="container-fluid mt-3"
          style={{ marginTop: 300, paddingBottom: 100 }}
        >
          <ReactTable
            manual
            columns={columns}
            page={this.state.pageNum}
            data={this.state.rowData}
            pageSize={this.state.pageSize}
            minRows={0}
            pages={Math.ceil(this.state.count / this.state.pageSize)}
            onPageChange={this.pageChanged}
            onPageSizeChange={this.pageSizeChanged}
            // noDataText={"Please wait..."}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(UserList);
