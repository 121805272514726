import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import FinancialDetailsForm from "./FinancialDetailsForm";
import LedgerExperienceForm from "./LedgerExperienceForm";
import { get, post } from "../../../shared/services/rest-service";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { setFinance } from "../../../shared/services/actions/finance-action";
import { setCompany } from "../../../shared/services/actions/company-action";
import { bindActionCreators } from "redux";
import register from "../../../shared/services/register-user-service";
import SubHeader from "../../../user/sub-header/sub-header";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import { ToastsStore } from "react-toasts";
import { LoaderLarge } from "../../loader";

var file1 = {};
var file2 = {};
var file3 = {};

class FinancialDetails extends React.Component {
  currentYrCompanyAccFile = new FormData();
  lastYrCompanyAccFile = new FormData();
  twoYrAgoCompanyAccFile = new FormData();

  constructor(props) {
    super(props);
    this.state = {
      token: "",
      displayMenu: false,
      displayCol: "col-lg-9",
      financialDetails: [],
      ledgerExperience: {},
      isEdit: false,
      loanDetails: null,
      userRole: "",
      CreditTerm: [],
      hideSaveForLaterButton: false,
      currentFileNameUrl: "",
      currentFileName: "",
      currentFileNameUrl: "",
      lastYearFileName: "",
      lastYearFileNameUrl: "",
      loading: false,
      last2YearFileName: "",
      last2YearFileNameUrl: "",
      removeCurrentYear: false,
      currentFinancialId: "",
      lastYearFinancialId: "",
      last2YearFinancialId: ""
    };
  }

  async componentDidMount() {
    debugger;
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      if (this.props.location.pathname === "/financial-details") {
        this.setState({ displayMenu: true });
        this.setState({ displayCol: "col-lg-9" });
        if (this.props.data.loanDetails.termId === 1) {
          if (
            this.props.data.loanDetails.amount >= 100001 &&
            this.props.data.loanDetails.amount <= 10000000
          ) {
            this.props.data.companyDetails.isEdit = true;
            this.props.data.companyDetails.isEdit1 = false;
            this.props.data.companyDetails.isEdit2 = false;
          }
        }
      } else {
        debugger;
        this.setState({ displayMenu: false, loading: true });
        this.setState({ displayCol: "col-lg-12" });
        if (this.props.location.state) {
          this.setState({
            loanDetails: this.props.financialDetails.loanDetails
          });

          const getUserDetail = await get(
            "users/getUserDetails/" + this.props.location.state.user_id,
            {}
          );
          if (this.props.financialDetails) {
            if (this.props.financialDetails.financialDetails.length > 0) {
              this.setState({
                currentFinancialId: this.props.financialDetails
                  .financialDetails[0].id,
                lastYearFinancialId: this.props.financialDetails
                  .financialDetails[1].id,
                last2YearFinancialId: this.props.financialDetails
                  .financialDetails[2].id
              });
            }
          } else {
          }
          if (this.props.financialDetails.financialDetails.length > 0) {
            if (this.props.financialDetails.financialDetails[0].File) {
              this.setState({
                currentFileName: this.props.financialDetails.financialDetails[0]
                  .File.name,
                currentFileNameUrl: this.props.financialDetails
                  .financialDetails[0].File.url
              });
            }

            if (this.props.financialDetails.financialDetails[1].File) {
              this.setState({
                lastYearFileName: this.props.financialDetails
                  .financialDetails[1].File.name,
                lastYearFileNameUrl: this.props.financialDetails
                  .financialDetails[1].File.url
              });
            }
            if (this.props.financialDetails.financialDetails[2].File) {
              this.setState({
                last2YearFileName: this.props.financialDetails
                  .financialDetails[2].File.name,
                last2YearFileNameUrl: this.props.financialDetails
                  .financialDetails[2].File.url
              });
            }
          }

          if (this.props.financialDetails.loanDetails.termId === 1) {
            if (
              this.props.financialDetails.loanDetails.amount >= 100001 &&
              this.props.financialDetails.loanDetails.amount <= 10000000
            ) {
              this.props.financialDetails.companyDetails.isEdit = true;
              this.props.financialDetails.companyDetails.isEdit1 = false;
              this.props.financialDetails.companyDetails.isEdit2 = true;

              if (
                this.state.currentFileName !== undefined ||
                this.state.currentFileName !== "" ||
                this.state.currentFileName !== null
              ) {
                this.props.financialDetails.companyDetails.currentYearReportFile = this.state.currentFileName;
                this.props.financialDetails.companyDetails.currentYearReportFileUrl = this.state.currentFileNameUrl;
              } else {
                this.props.financialDetails.companyDetails.currentYearReportFile =
                  "";
                this.props.financialDetails.companyDetails.currentYearReportFileUrl =
                  "";
              }

              if (
                this.state.lastYearFileName !== undefined ||
                this.state.lastYearFileName !== "" ||
                this.state.lastYearFileName !== null
              ) {
                this.props.financialDetails.companyDetails.lastYearFileName = this.state.lastYearFileName;
                this.props.financialDetails.companyDetails.lastYearFileNameUrl = this.state.lastYearFileNameUrl;
              } else {
                this.props.financialDetails.companyDetails.lastYearFileName =
                  "";
                this.props.financialDetails.companyDetails.lastYearFileNameUrl =
                  "";
              }

              if (
                this.state.last2YearFileName !== undefined ||
                this.state.last2YearFileName !== "" ||
                this.state.last2YearFileName !== null
              ) {
                this.props.financialDetails.companyDetails.last2YearFileName = this.state.last2YearFileName;
                this.props.financialDetails.companyDetails.last2YearFileNameUrl = this.state.last2YearFileNameUrl;
              } else {
                this.props.financialDetails.companyDetails.last2YearFileName =
                  "";
                this.props.financialDetails.companyDetails.last2YearFileNameUrl =
                  "";
              }
            }
          }

          if (getUserDetail.result.role == "ROLE_ADMIN") {
            debugger;
            this.props.financialDetails.companyDetails.isEdit = true;
            this.props.financialDetails.companyDetails.isEdit1 = true;
            this.props.financialDetails.companyDetails.isEdit2 = true;
            this.setState({ userRole: getUserDetail.result.role });
            this.setState({
              financialDetails: this.props.financialDetails.financialDetails
            });
            this.setState({
              ledgerExperience: this.props.financialDetails.companyDetails
            });

            // this.props.financialDetails.companyDetails.currentYearReportFile = this.state.currentFileName

            this.setState({ isEdit2: true });
          }

          if (getUserDetail.result.role == "ROLE_APPROVER") {
            this.props.financialDetails.companyDetails.isEdit = true;
            this.props.financialDetails.companyDetails.isEdit1 = true;
            this.props.financialDetails.companyDetails.isEdit2 = true;
            this.setState({ userRole: getUserDetail.result.role });
            this.setState({
              financialDetails: this.props.financialDetails.financialDetails
            });
            this.setState({
              ledgerExperience: this.props.financialDetails.companyDetails
            });

            // this.props.financialDetails.companyDetails.currentYearReportFile = this.state.currentFileName

            this.setState({ isEdit2: true });
          }
          if (getUserDetail.result.role == "ROLE_REVIEWER") {
            if (this.props.status == "APPROVED") {
              this.props.financialDetails.companyDetails.isEdit = true;
              this.props.financialDetails.companyDetails.isEdit1 = true;
              this.props.financialDetails.companyDetails.isEdit2 = true;

              this.setState({ userRole: getUserDetail.result.role });
              this.setState({
                financialDetails: this.props.financialDetails.financialDetails,
                isEdit2: true
              });
              this.setState({
                ledgerExperience: this.props.financialDetails.companyDetails
              });
            } else if (this.props.financialDetails.loanDetails.termId == 1) {
              if (this.props.financialDetails.loanDetails.amount > 100000) {
                if (this.props.status == "SUBMITTED") {
                  this.props.financialDetails.companyDetails.isEdit = true;
                  this.props.financialDetails.companyDetails.isEdit1 = false;
                  this.props.financialDetails.companyDetails.isEdit2 = false;
                  this.setState({ userRole: getUserDetail.result.role });
                  this.setState({
                    financialDetails: this.props.financialDetails
                      .financialDetails,
                    isEdit2: false
                  });
                  this.setState({
                    ledgerExperience: this.props.financialDetails.companyDetails
                  });
                }
              } else {
                if (this.props.status == "SUBMITTED") {
                  this.props.financialDetails.companyDetails.isEdit = false;
                  this.props.financialDetails.companyDetails.isEdit1 = false;
                  this.props.financialDetails.companyDetails.isEdit2 = false;
                  this.setState({ userRole: getUserDetail.result.role });
                  this.setState({
                    financialDetails: this.props.financialDetails
                      .financialDetails,
                    isEdit2: false
                  });
                  this.setState({
                    ledgerExperience: this.props.financialDetails.companyDetails
                  });
                }
              }
            }
            // this.setState({ userRole: getUserDetail.result.role });
            // this.setState({
            //   financialDetails: this.props.financialDetails.financialDetails
            // });
            // this.setState({
            //   ledgerExperience: this.props.financialDetails.companyDetails
            // });
            // this.props.financialDetails.companyDetails.isEdit = false;
            // this.props.financialDetails.companyDetails.isEdit1 = false;
            // this.props.financialDetails.companyDetails.currentYearReportFile = this.state.currentFileName

            // this.setState({ isEdit: true });
          }
          // if(this.props.financialDetails.currentYrCompanyAccFile)
          // {
          // this.setState({
          //   currentFileNameUrl:this.props.financialDetails.currentYrCompanyAccFile
          // })
          // }

          if (getUserDetail.result.role === "ROLE_USER") {
            this.setState({
              financialDetails: this.props.financialDetails.financialDetails
            });
            this.setState({
              ledgerExperience: this.props.financialDetails.companyDetails
            });
            if (this.props.status == "APPROVED") {
              this.props.financialDetails.companyDetails.isEdit1 = true;
              this.props.financialDetails.companyDetails.isEdit = true;
              this.props.financialDetails.companyDetails.isEdit2 = true;

              //    this.props.financialDetails.companyDetails.currentYearReportFile =this.state.currentFileName

              this.setState({ isEdit2: true });
            } else {
              this.setState({ isEdit2: false });
            }
          }
        }
      }

      const getLedgerExperienceCreditTerm = await get(
        "taxonomy/FINANCIAL_CREDITTERM",
        {}
      );
      this.setState({
        CreditTerm: getLedgerExperienceCreditTerm,
        loading: false
      });
    }
  }

  submitFinanceData = ({ data }) => {
    debugger;

    var t1 = {};
    var tempData = {};
    if (this.props.financialDetails) {
      if (this.props.financialDetails.financialDetails.length > 0) {
        tempData = JSON.parse(JSON.stringify(this.modifyFormData(data)));
      } else {
        tempData = JSON.parse(JSON.stringify(this.modifyFormDataNew(data)));
      }
    } else {
      tempData = JSON.parse(JSON.stringify(this.modifyFormDataNew(data)));
    }
    const dataTovalidate = this.props.data;
    dataTovalidate["currentYrCompanyAccFile"] = file1;
    dataTovalidate["lastYrCompanyAccFile"] = file2;
    dataTovalidate["twoYrAgoCompanyAccFile"] = file3;
    dataTovalidate["financialDetails"] = tempData;

    if (
      this.props.data.loanDetails.termId == 1 &&
      this.props.data.loanDetails.amount <= 300000
    ) {
      t1.hideBank1 = true;
      t1.hideTrade22 = true;

      dataTovalidate["tradeReference1"] = t1;
      //Object.assign(dataTovalidate, (dataTovalidate["tradeReference1"] = t1));
    } else if (
      this.props.data.loanDetails.termId === 1 &&
      this.props.data.loanDetails.amount >= 300001 &&
      this.props.data.loanDetails.amount <= 1000000
    ) {
      t1.hideBank1 = true;
      t1.hideTrade22 = false;
      t1.hideTrade11 = false;
      dataTovalidate["tradeReference1"] = t1;
    } else if (
      this.props.data.loanDetails.termId === 1 &&
      this.props.data.loanDetails.amount >= 1000001 &&
      this.props.data.loanDetails.amount <= 10000000
    ) {
      t1.hideBank1 = false;
      t1.hideTrade22 = false;
      t1.hideTrade11 = false;

      dataTovalidate["tradeReference1"] = t1;
    } else if (this.props.data.loanDetails.termId !== 1) {
      if (this.props.data.loanDetails.amount >= 500000) {
        t1.hideTrade22 = true;
        t1.hideTrade11 = true;
        t1.hidePersonal = true;
        dataTovalidate["tradeReference1"] = t1;
      } else {
        t1.hideTrade22 = true;
        t1.hideTrade11 = true;
        dataTovalidate["tradeReference1"] = t1;
      }
    }
    // else if(this.props.data.loanDetails.amount >= 300001 && this.props.data.loanDetails.amount <=1000000)
    // {

    // }
    if (this.props.location.pathname === "/financial-details") {
      //  this.validateData(this.modifyFormData(data));

      //this.props.setFinance(myData);
      this.props.history.push("/credit-reference");
    } else {
      // this.validateData(this.modifyFormData(data));
      let selectedTab = 5;
      this.props.nextTabs(selectedTab);
    }
  };

  modifyFormData(data) {
    debugger;
    const fileNameDivCurr = document.getElementsByClassName(
      "current-year-filename"
    )[0];
    const fileNameDivLast = document.getElementsByClassName(
      "last-year-filename"
    )[0];
    const fileNameDivTwo = document.getElementsByClassName(
      "two-year-filename"
    )[0];
    // if(this.props.financialDetails)
    // {
    //   if(this.props.financialDetails.financialDetails)
    //   {
    //     this.setState({
    //       currentFinancialId:this.props.financialDetails.financialDetails[0].id,
    //       lastYearFinancialId:this.props.financialDetails.financialDetails[1].id,
    //       last2YearFinancialId:this.props.financialDetails.financialDetails[2].id

    //     })
    //   }
    // }
    const modifiedData = [
      {
        ebitda: data.ebitda,
        operatingProfit: data.operatingProfit,
        netIncome: data.netIncome,
        netWorth: data.netWorth,
        totalLiability: data.totalLiability,
        debtService: data.debtService,
        netCashFromOperations: data.netCashFromOperations,
        year: data.year,
        id: this.state.currentFinancialId,
        detailType: 1,
        isInterim: data.isInterim,
        File: { name: fileNameDivCurr.textContent }
      },
      {
        ebitda: data.ebitda1,
        operatingProfit: data.operatingProfit1,
        netIncome: data.netIncome1,
        netWorth: data.netWorth1,
        totalLiability: data.totalLiability1,
        debtService: data.debtService1,
        netCashFromOperations: data.netCashFromOperations1,
        year: data.year1,
        id: this.state.lastYearFinancialId,

        detailType: 2,
        isInterim: data.isInterim,
        File: { name: fileNameDivLast.textContent }
      },
      {
        ebitda: data.ebitda2,
        operatingProfit: data.operatingProfit2,
        netIncome: data.netIncome,
        id: this.state.last2YearFinancialId,

        netWorth: data.netWorth2,
        totalLiability: data.totalLiability2,
        debtService: data.debtService2,
        netCashFromOperations: data.netCashFromOperations2,
        year: data.year2,
        detailType: 3,
        isInterim: data.isInterim,
        File: { name: fileNameDivTwo.textContent }
      }
    ];

    return modifiedData;
  }

  modifyFormDataNew(data) {
    const fileNameDivCurr = document.getElementsByClassName(
      "current-year-filename"
    )[0];
    const fileNameDivLast = document.getElementsByClassName(
      "last-year-filename"
    )[0];
    const fileNameDivTwo = document.getElementsByClassName(
      "two-year-filename"
    )[0];
    // if(this.props.financialDetails)
    // {
    //   if(this.props.financialDetails.financialDetails)
    //   {
    //     this.setState({
    //       currentFinancialId:this.props.financialDetails.financialDetails[0].id,
    //       lastYearFinancialId:this.props.financialDetails.financialDetails[1].id,
    //       last2YearFinancialId:this.props.financialDetails.financialDetails[2].id

    //     })
    //   }
    // }
    const modifiedData = [
      {
        ebitda: data.ebitda,
        operatingProfit: data.operatingProfit,
        netIncome: data.netIncome,
        netWorth: data.netWorth,
        totalLiability: data.totalLiability,
        debtService: data.debtService,
        netCashFromOperations: data.netCashFromOperations,
        year: data.year,
        detailType: 1,
        isInterim: data.isInterim,
        File: { name: fileNameDivCurr.textContent }
      },
      {
        ebitda: data.ebitda1,
        operatingProfit: data.operatingProfit1,
        netIncome: data.netIncome1,
        netWorth: data.netWorth1,
        totalLiability: data.totalLiability1,
        debtService: data.debtService1,
        netCashFromOperations: data.netCashFromOperations1,
        year: data.year1,
        detailType: 2,
        isInterim: data.isInterim,
        File: { name: fileNameDivLast.textContent }
      },
      {
        ebitda: data.ebitda2,
        operatingProfit: data.operatingProfit2,
        netIncome: data.netIncome,
        netWorth: data.netWorth2,
        totalLiability: data.totalLiability2,
        debtService: data.debtService2,
        netCashFromOperations: data.netCashFromOperations2,
        year: data.year2,
        detailType: 3,
        isInterim: data.isInterim,
        File: { name: fileNameDivTwo.textContent }
      }
    ];

    return modifiedData;
  }

  modifyDataToShow = data => {
    const bulkData = {};
    if (data && data.length) {
      data.forEach(yearOnYearData => {
        if (yearOnYearData.detailType === 1) {
          // fileNameDivCurr.textContent = yearOnYearData.File ? yearOnYearData.File.name : '';
          bulkData.ebitda = yearOnYearData.ebitda;
          bulkData.operatingProfit = yearOnYearData.operatingProfit;
          bulkData.netIncome = yearOnYearData.netIncome;
          bulkData.netWorth = yearOnYearData.netWorth;
          bulkData.totalLiability = yearOnYearData.totalLiability;
          bulkData.debtService = yearOnYearData.debtService;
          bulkData.netCashFromOperations = yearOnYearData.netCashFromOperations;
          bulkData.year = yearOnYearData.year;
          bulkData.isInterim = yearOnYearData.isInterim;
          //bulkData.isEdit = true;
          if (!yearOnYearData.isEdit) {
            bulkData.isEdit2 = this.state.isEdit2;
          }
        }
        if (yearOnYearData.detailType === 2) {
          // fileNameDivLast.textContent = yearOnYearData.File ? yearOnYearData.File.name : '';
          bulkData.ebitda1 = yearOnYearData.ebitda;
          bulkData.operatingProfit1 = yearOnYearData.operatingProfit;
          bulkData.netIncome1 = yearOnYearData.netIncome;
          bulkData.netWorth1 = yearOnYearData.netWorth;
          bulkData.totalLiability1 = yearOnYearData.totalLiability;
          bulkData.debtService1 = yearOnYearData.debtService;
          bulkData.netCashFromOperations1 =
            yearOnYearData.netCashFromOperations;
          bulkData.year1 = yearOnYearData.year;
          if (!yearOnYearData.isEdit) {
            bulkData.isEdit = this.state.isEdit;
          }
        }
        if (yearOnYearData.detailType === 3) {
          // fileNameDivTwo.textContent = yearOnYearData.File ? yearOnYearData.File.name : '';
          bulkData.ebitda2 = yearOnYearData.ebitda;
          bulkData.operatingProfit2 = yearOnYearData.operatingProfit;
          bulkData.netIncome2 = yearOnYearData.netIncome;
          bulkData.netWorth2 = yearOnYearData.netWorth;
          bulkData.totalLiability2 = yearOnYearData.totalLiability;
          bulkData.debtService2 = yearOnYearData.debtService;
          bulkData.netCashFromOperations2 =
            yearOnYearData.netCashFromOperations;
          bulkData.year2 = yearOnYearData.year;
          if (!yearOnYearData.isEdit) {
            bulkData.isEdit = this.state.isEdit;
          }
        }
      });
    }
    return bulkData;
  };

  submitLedgerExperience1 = ({ data, event }) => {
    var t1 = {};
    debugger;
    if (this.props.location.pathname === "/financial-details") {
      this.props.setCompany(data);
      if (localStorage.getItem("token")) {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount <= 100000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          tempData.creditTypeId = data.creditType.Transaltion.defaultValue;

          t1.hideBank1 = true;
          t1.hideTrade22 = true;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;

          // dataTovalidate["companyDetails"] = tempData;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          if (data.creditOutStanding && data.creditAmount) {
            this.props.history.push("/credit-reference");
          }
        } else if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount >= 300001 &&
          this.props.data.loanDetails.amount <= 1000000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;

          t1.hideBank1 = true;
          t1.hideTrade22 = false;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;

          // dataTovalidate["companyDetails"] = tempData;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          if (data.creditOutStanding && data.creditAmount) {
            this.props.history.push("/credit-reference");
          }
        } else if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount >= 1000001 &&
          this.props.data.loanDetails.amount <= 10000000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;

          t1.hideBank1 = false;
          t1.hideTrade22 = false;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;

          // dataTovalidate["companyDetails"] = tempData;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          if (data.creditOutStanding && data.creditAmount) {
            this.props.history.push("/credit-reference");
          }
        } else if (this.props.data.loanDetails.termId !== 1) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          if (this.props.data.loanDetails.amount > 500000) {
            t1.hideTrade22 = true;
            t1.hideTrade11 = true;
            t1.hidePersonal = true;
            dataTovalidate["tradeReference1"] = t1;
          } else {
            t1.hideTrade22 = true;
            t1.hideTrade11 = true;
            dataTovalidate["tradeReference1"] = t1;
          }
          // dataTovalidate["companyDetails"] = tempData;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          if (data.creditOutStanding && data.creditAmount) {
            this.props.history.push("/credit-reference");
          }
        }
      } else {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount <= 100000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          t1.hideBank1 = true;
          t1.hideTrade22 = true;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;
          // dataTovalidate["companyDetails"] = tempData;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          this.props.history.push("/credit-reference");
        }
      }
    } else {
      if (
        this.props.financialDetails.loanDetails.termId === 1 &&
        this.props.financialDetails.loanDetails.amount <= 100000
      ) {
        const dataTovalidate = this.props.data;
        const tempData = JSON.parse(JSON.stringify(data));

        tempData.creditTermId = this.props.data.companyDetails.creditTerm.Transaltion.id;

        t1.hideBank1 = true;
        t1.hideTrade22 = true;
        t1.hideTrade11 = false;

        dataTovalidate["tradeReference1"] = t1;
        dataTovalidate["companyDetails"] = {
          ...dataTovalidate["companyDetails"],
          tempData
        };
        this.props.setCompany(dataTovalidate["companyDetails"]);
        let selectedTab = 5;
        this.props.nextTabs(selectedTab);
      }
      if (
        this.props.financialDetails.loanDetails.amount >= 100001 &&
        this.props.financialDetails.loanDetails.amount <= 10000000
      ) {
        this.props.setCompany(data);
      } else {
        this.props.setCompany(data);
        let selectedTab = 5;
        this.props.nextTabs(selectedTab);
      }
    }
  };

  submitLedgerExperience = ({ data, event }) => {
    var t1 = {};

    debugger;
    if (this.props.location.pathname === "/financial-details") {
      this.props.setCompany(data);
      if (localStorage.getItem("token")) {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount <= 100000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          // dataTovalidate["companyDetails"] = tempData;

          t1.hideBank1 = true;
          t1.hideTrade22 = true;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;

          //  dataTovalidate["companyDetails"] = tempData;
          if (data.creditOutStanding && data.creditAmount) {
            this.props.history.push("/credit-reference");
          }
          // if(data.creditOutStanding && data.creditAmount && data.creditType.Transaltion.defaultValue!==""  )
          // {
          // this.props.history.push("/credit-reference");
          // }
          // this.props.history.push("/credit-reference");
        }
      } else {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount <= 100000
        ) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          tempData.creditTypeId = data.creditType.Transaltion.defaultValue;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          // dataTovalidate["companyDetails"] = tempData;
          // this.props.history.push("/credit-reference");
        }
      }
    } else {
      debugger;
      if (
        this.props.financialDetails.loanDetails.amount >= 100001 &&
        this.props.financialDetails.loanDetails.amount <= 10000000
      ) {
        if (data.creditOutStanding && data.creditAmount && data.creditType) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.financialDetails.companyDetails.creditTermId;
          tempData.creditTypeId = data.creditType.Transaltion.defaultValue;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          // dataTovalidate["companyDetails"] = tempData;
          t1.hideBank1 = true;
          t1.hideTrade22 = true;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;
          this.props.setCompany(data);
        }
        // this.props.setCompany(data);
      } else {
        if (data.creditOutStanding && data.creditAmount) {
          const tempData = JSON.parse(JSON.stringify(data));
          const dataTovalidate = this.props.data;
          tempData.creditTermId = this.props.data.companyDetails.creditTermId;
          dataTovalidate["companyDetails"] = {
            ...dataTovalidate["companyDetails"],
            tempData
          };
          // dataTovalidate["companyDetails"] = tempData;
          t1.hideBank1 = true;
          t1.hideTrade22 = true;
          t1.hideTrade11 = false;

          dataTovalidate["tradeReference1"] = t1;
          this.props.setCompany(data);
          let selectedTab = 5;
          this.props.nextTabs(selectedTab);
        }
        // this.props.setCompany(data);
        // let selectedTab = 5
        // this.props.nextTabs(selectedTab)
      }
    }
    // this.props.history.push("/credit-reference");
  };

  handleLedgerCustomEvent = event => {
    if (event.type === "back") {
      if (this.props.location.pathname === "/financial-details") {
        this.props.history.push("/loan-details");
      } else {
        let selectedTab = 3;
        this.props.nextTabs(selectedTab);
      }
    } else if (event.type === "saveForLater") {
      debugger;
      this.validateLedgerData(event.data);
      // if(this.props.data.loanDetails.termId === 1 && this.props.data.loanDetails.amount <= 100000){
      //   this.props.history.push("/credit-reference");
      // }
      // if(this.props.financialDetails){
      //   if(this.props.financialDetails.loanDetails.termId === 1 && this.props.financialDetails.loanDetails.amount <= 100000){
      //     this.props.history.push("/credit-reference");
      //   }
      // }
    }
  };

  validateLedgerData = async data => {
    const tempData = JSON.parse(JSON.stringify(data));
    const dataTovalidate = this.props.data;
    tempData.creditTermId = this.props.data.companyDetails.creditTermId;
    dataTovalidate["companyDetails"] = {
      ...dataTovalidate["companyDetails"],
      tempData
    };
    // dataTovalidate["companyDetails"] = tempData;

    if (!localStorage.getItem("token")) {
      try {
        const res = await register(dataTovalidate.personalDetails);
        localStorage.setItem("currentUser", JSON.stringify(res));

        if (res.token) {
          this.saveValidateData(dataTovalidate);
        }
      } catch (error) {
        alert("Some Error");
      }
    } else {
      // if (!localStorage.getItem('token')) {
      //   try {
      //     await register(dataTovalidate.userDetails);
      //   } catch (error) {
      //     alert('Some Error');
      //   }
      // }
      try {
        await post("loan/save-validate", dataTovalidate);
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          const res = await post("loan/save", dataToSave);
          ToastsStore.success(res);
          this.props.history.push("/loans");
        } catch (error) {
          alert("cant save data");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  saveValidateData = async dataTovalidate => {
    debugger;
    try {
      const res = await post("loan/save-validate", dataTovalidate);
      if (res.error == null) {
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          const test = await post("loan/save", dataToSave);
          ToastsStore.success(test);
          if (localStorage.getItem("isLoggedIn") == "false") {
            this.props.history.push("/login");
          } else {
            this.props.history.push("/loans");
          }
        } catch (error) {
          alert("cant save data");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleCustomEvent = event => {
    debugger;
    if (event.type === "back") {
      if (this.props.location.pathname === "/financial-details") {
        this.props.history.push("/loan-details");
      } else {
        let selectedTab = 3;
        this.props.nextTabs(selectedTab);
      }
    } else if (event.type === "saveForLater") {
      this.validateData(this.modifyFormData(event.data));
      // this.props.history.push("/credit-reference");
    } else {
      debugger;
      this.uploadCompanyAccountReport(event.type);
    }
  };

  uploadCompanyAccountReport = type => {
    debugger;
    switch (type) {
      case "uploadCurrentYearReport":
        document.getElementById("currentYear").click();
        break;
      case "uploadLastYearReport":
        document.getElementById("lastYear").click();
        break;
      case "uploadTwoYearAgoReport":
        document.getElementById("2Year").click();
        break;
      case "uploadLastYearReportDownload":
        alert("CLick on file name");
        break;
      default:
        return;
    }
  };

  uploadCurrentYearReport1 = () => {
    debugger;
    const fileNameDiv = document.getElementsByClassName("current-year-vikas");

    fileNameDiv.textContent = this.state.currentFileName;
  };

  uploadCurrentYearReport = event => {
    var file = event.target.files[0];
    this.currentYrCompanyAccFile.append("file", file);
    file1 = file;
    // this.props.financialDetails.companyDetails.removeCurrentYear = true
    debugger;
    var fileNameDiv = document.getElementsByClassName(
      "current-year-filename"
    )[0];
    fileNameDiv.textContent = file.name;
    fileNameDiv.onclick = function() {
      file = {};
      fileNameDiv.textContent = "";
      file1 = {};
    };
  };

  uploadLastYearReport = event => {
    var file = event.target.files[0];
    this.lastYrCompanyAccFile.append("file", file);
    file2 = file;
    var fileNameDiv = document.getElementsByClassName("last-year-filename")[0];
    fileNameDiv.textContent = file.name;
    fileNameDiv.onclick = function() {
      file = {};
      fileNameDiv.textContent = "";
      file2 = {};
    };
  };

  upload2yearsAgoReport = event => {
    var file = event.target.files[0];
    this.twoYrAgoCompanyAccFile.append("file", file);
    file3 = file;
    var fileNameDiv = document.getElementsByClassName("two-year-filename")[0];
    fileNameDiv.textContent = file.name;
    fileNameDiv.onclick = function() {
      file = {};
      fileNameDiv.textContent = "";
      file3 = {};
    };
  };

  validateData = async data => {
    debugger;
    const tempData = JSON.parse(JSON.stringify(data));
    const dataTovalidate = this.props.data;
    dataTovalidate["currentYrCompanyAccFile"] = file1;
    dataTovalidate["financialDetails"] = tempData;

    if (!localStorage.getItem("token")) {
      try {
        const res = await register(dataTovalidate.personalDetails);
        localStorage.setItem("currentUser", JSON.stringify(res));

        if (res.token) {
          this.saveValidateData(dataTovalidate);
        }
      } catch (error) {
        alert("Some Error");
      }
    } else {
      try {
        await post("loan/save-validate", dataTovalidate);
        try {
          const dataToSave = new FormData();
          dataToSave.append("currentYrCompanyAccFile", file1);
          dataToSave.append("lastYrCompanyAccFile", file2);
          dataToSave.append("twoYrAgoCompanyAccFile", file3);
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          const res = await post("loan/save", dataToSave);
          ToastsStore.success(res);
          this.props.history.push("/loans");
        } catch (error) {
          alert("cant save data");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  displayForm() {
    if (this.props.financialDetails) {
      if (
        this.props.financialDetails.loanDetails.termId === 1 &&
        this.props.financialDetails.loanDetails.amount >= 100001 &&
        this.props.financialDetails.loanDetails.amount <= 10000000
      ) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.ledgerExperience
                  ? this.state.ledgerExperience
                  : ""
              }}
              onChange={schema => this.submitLedgerExperience(schema)}
              //onChange={(schema)=>this.tes(schema)}
              form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
              onSubmit={this.submitLedgerExperience}
              onCustomEvent={this.handleLedgerCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      } else if (
        this.props.financialDetails.loanDetails.termId === 1 &&
        this.props.financialDetails.loanDetails.amount > 10000000
      ) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      } else if (this.props.financialDetails.loanDetails.termId !== 1) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      } else if (this.props.financialDetails.loanDetails.termId == 1) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.ledgerExperience
                  ? this.state.ledgerExperience
                  : ""
              }}
              //        onChange={(schema) => this.submitLedgerExperience(schema)}
              //onChange={(schema)=>this.tes(schema)}
              form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
              onSubmit={this.submitLedgerExperience1}
              onCustomEvent={this.handleLedgerCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      }
    } else if (this.props.data.loanDetails) {
      if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount >= 100001 &&
        this.props.data.loanDetails.amount <= 10000000
      ) {
        return (
          <div>
            <Form
              submission={{
                data: this.props.data.companyDetails
                  ? this.props.data.companyDetails
                  : ""
              }}
              // onChange={()=>this.submitLedgerExperience}
              //  onChange={(schema)=>this.tes(schema)}
              onChange={schema => this.submitLedgerExperience(schema)}
              form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
              onSubmit={this.submitLedgerExperience}
              onCustomEvent={this.handleLedgerCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      }
      if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount > 10000000
      ) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      } else if (this.props.data.loanDetails.termId !== 1) {
        return (
          <div>
            <Form
              submission={{
                data: this.state.financialDetails
                  ? this.modifyDataToShow(this.state.financialDetails)
                  : ""
              }}
              form={FinancialDetailsForm(this.props)}
              onSubmit={this.submitFinanceData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      } else if (this.props.data.loanDetails.termId == 1) {
        return (
          <div>
            <Form
              submission={{
                data: this.props.data.companyDetails
                  ? this.props.data.companyDetails
                  : ""
              }}
              // onChange={()=>this.submitLedgerExperience}
              //  onChange={(schema)=>this.tes(schema)}
              // onChange={(schema) => this.submitLedgerExperience(schema)}
              form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
              onSubmit={this.submitLedgerExperience1}
              onCustomEvent={this.handleLedgerCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        );
      }
    }
  }
  // displayForm(){
  //   console.log("in financial render", this.props)
  //   return (
  //     <div>

  //       {this.props.financialDetails ? (
  //         <div>
  //           { this.props.financialDetails.loanDetails.termId === 1 &&
  //             <div>
  //               <Form
  //                 submission={{
  //                   data: this.state.ledgerExperience ? this.state.ledgerExperience : ''
  //                 }}
  //                 onChange={(schema) => this.submitLedgerExperience(schema)}
  //                 //onChange={(schema)=>this.tes(schema)}
  //                 form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
  //                 onSubmit={this.submitLedgerExperience}
  //                 onCustomEvent={this.handleLedgerCustomEvent}
  //                 options = {{
  //                   language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                   languages: ['en', 'spa'],
  //                   i18n: {
  //                     en: languageEN.en.translation,
  //                     spa: languageSPA.spa.translation
  //                   }
  //                 }}
  //               />

  //               { this.props.financialDetails.loanDetails.amount >= 100001 && this.props.financialDetails.loanDetails.amount <= 10000000 &&
  //                   <Form
  //                     submission={{
  //                       data: this.state.financialDetails ? this.modifyDataToShow(this.state.financialDetails) : ''
  //                     }}
  //                     form={FinancialDetailsForm(this.props)}
  //                     onSubmit={this.submitFinanceData}
  //                     onCustomEvent={this.handleCustomEvent}
  //                     options = {{
  //                       language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                       languages: ['en', 'spa'],
  //                       i18n: {
  //                         en: languageEN.en.translation,
  //                         spa: languageSPA.spa.translation
  //                       }
  //                     }}
  //                   />
  //               }
  //             </div>
  //           }
  //           { this.props.financialDetails.loanDetails.termId !== 1 &&
  //             <Form
  //               submission={{
  //                 data: this.state.financialDetails ? this.modifyDataToShow(this.state.financialDetails) : ''
  //               }}
  //               form={FinancialDetailsForm(this.props)}
  //               onSubmit={this.submitFinanceData}
  //               onCustomEvent={this.handleCustomEvent}
  //               options = {{
  //                 language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                 languages: ['en', 'spa'],
  //                 i18n: {
  //                   en: languageEN.en.translation,
  //                   spa: languageSPA.spa.translation
  //                 }
  //               }}
  //             />
  //           }
  //         </div>
  //       ): (
  //         <div>
  //         {this.props.data.loanDetails ? (
  //           <div>
  //             { this.props.data.loanDetails.terms.Transaltion.defaultValue === 1 &&  this.props.data.loanDetails.amount >= 100001 && this.props.data.loanDetails.amount <= 10000000 ?
  //               <div>
  //                 <Form
  //                   submission={{
  //                     data: this.props.data.companyDetails ? this.props.data.companyDetails : ''
  //                   }}
  //                   // onChange={()=>this.submitLedgerExperience}
  //                 //  onChange={(schema)=>this.tes(schema)}
  //                 onChange={(schema) => this.submitLedgerExperience(schema, schema["event"]="")}
  //                   form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
  //                   onSubmit={this.submitLedgerExperience}
  //                   onCustomEvent={this.handleLedgerCustomEvent}
  //                   options = {{
  //                     language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                     languages: ['en', 'spa'],
  //                     i18n: {
  //                         en: languageEN.en.translation,
  //                         spa: languageSPA.spa.translation
  //                     }
  //                   }}
  //                 />
  //                   <Form
  //                     submission={{
  //                       data: this.state.financialDetails ? this.modifyDataToShow(this.state.financialDetails) : ''
  //                     }}
  //                     form={FinancialDetailsForm(this.props)}
  //                     onSubmit={this.submitFinanceData}
  //                     onCustomEvent={this.handleCustomEvent}
  //                     options = {{
  //                       language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                       languages: ['en', 'spa'],
  //                       i18n: {
  //                         en: languageEN.en.translation,
  //                         spa: languageSPA.spa.translation
  //                       }
  //                     }}
  //                   />

  //               </div>:
  //               (

  //                 (this.props.data.loanDetails.terms.Transaltion.defaultValue === 1 &&  this.props.data.loanDetails.amount <= 100000?
  //                   <Form
  //                   submission={{
  //                     data: this.props.data.companyDetails ? this.props.data.companyDetails : ''
  //                   }}
  //                   // onChange={()=>this.submitLedgerExperience}
  //                 //  onChange={(schema)=>this.tes(schema)}
  //                   form={LedgerExperienceForm(this.props, this.state.CreditTerm)}
  //                   onSubmit={this.submitLedgerExperience1}
  //                   onCustomEvent={this.handleLedgerCustomEvent}
  //                   options = {{
  //                     language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                     languages: ['en', 'spa'],
  //                     i18n: {
  //                         en: languageEN.en.translation,
  //                         spa: languageSPA.spa.translation
  //                     }
  //                   }}
  //                 />:null
  //                 )
  //               )
  //             }
  //               { this.props.data.loanDetails.terms.Transaltion.defaultValue!== 1 &&
  //               <Form
  //                 submission={{
  //                   data: this.state.financialDetails ? this.modifyDataToShow(this.state.financialDetails) : ''
  //                 }}
  //                 form={FinancialDetailsForm(this.props)}
  //                 onSubmit={this.submitFinanceData}
  //                 onCustomEvent={this.handleCustomEvent}
  //                 options = {{
  //                   language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                   languages: ['en', 'spa'],
  //                   i18n: {
  //                     en: languageEN.en.translation,
  //                     spa: languageSPA.spa.translation
  //                   }
  //                 }}
  //               />
  //             }
  //             {/* { this.props.data.loanDetails.termId !== 1 &&
  //               <Form
  //                 submission={{
  //                   data: this.state.financialDetails ? this.modifyDataToShow(this.state.financialDetails) : ''
  //                 }}
  //                 form={FinancialDetailsForm(this.props)}
  //                 onSubmit={this.submitFinanceData}
  //                 onCustomEvent={this.handleCustomEvent}
  //                 options = {{
  //                   language: localStorage.getItem('language') ? localStorage.getItem('language'): '',
  //                   languages: ['en', 'spa'],
  //                   i18n: {
  //                     en: languageEN.en.translation,
  //                     spa: languageSPA.spa.translation
  //                   }
  //                 }}
  //               />
  //             } */}
  //           </div>
  //         ) : null
  //         }
  //         </div>
  //       )}
  //     </div>
  //   )
  // }

  render() {
    const { loading } = this.state;
    const { t } = this.props;
    return (
      <section className="clearfix">
        {this.state.displayMenu ? <SubHeader /> : null}
        <input
          id="currentYear"
          className="hidden-obj"
          type="file"
          onChange={this.uploadCurrentYearReport}
        />
        <input
          id="lastYear"
          className="hidden-obj"
          type="file"
          onChange={this.uploadLastYearReport}
        />
        <input
          id="2Year"
          className="hidden-obj"
          type="file"
          onChange={this.upload2yearsAgoReport}
        />
        <input
          id="currentYear1"
          className="hidden-obj"
          type="file"
          onChange={this.uploadCurrentYearReport1}
        />
        <div className="row no-gutters align-items-center">
          {loading ? (
            <LoaderLarge />
          ) : (
            <div className={`mainbox ${this.state.displayCol}`}>
              <div className="mg-lft-100 pad-top-30 headline">
                <h3 className="head-tittle">{t("Financial Details")}</h3>
              </div>
              <div
                id="formsdata"
                className="financial-form g-pa-10 g-mx-30--xl"
              >
                {this.displayForm()}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFinance: setFinance,
      setCompany: setCompany
    },
    dispatch
  );
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(withRouter(FinancialDetails))
);
