import { numberLiteralTypeAnnotation } from "@babel/types";

const RegisterForm = props => {
  const disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];
  return {
    type: "form",
    components: [
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns4",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                inputType: "checkbox",
                tableView: true,
                label: "",
                dataGridLabel: false,
                key: "isEdit",
                defaultValue: false,
                protected: false,
                persistent: true,
                hidden: true,
                name: "",
                value: "",
                clearOnHide: true,
                validate: {
                  required: false
                },
                type: "checkbox",
                labelPosition: "right",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                hideLabel: false
              },
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns4Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns4ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "text",
                                inputMask: "",
                                label: "First Name",
                                key: "firstName",
                                placeholder: "First Name",
                                tooltip: "Please enter your First Name.",
                                prefix:
                                  '<i ref="icon" class="fa fa-user" style=""></i>',
                                suffix: "",
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: "",
                                  customPrivate: false
                                },

                                logic: disabledCondition,
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                type: "textfield",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        autofocus: false,
                        input: true,
                        tableView: true,
                        inputType: "text",
                        inputMask: "",
                        label: "Last Name",
                        key: "lastName",
                        placeholder: "Last Name",
                        tooltip: "Please enter your last name.",
                        prefix:
                          '<i ref="icon" class="fa fa-user" style=""></i>',
                        suffix: "",
                        multiple: false,
                        defaultValue: "",
                        protected: false,
                        unique: false,
                        persistent: true,
                        hidden: false,
                        clearOnHide: true,
                        spellcheck: true,
                        validate: {
                          required: true,
                          minLength: "",
                          maxLength: "",
                          pattern: "",
                          custom: "",
                          customPrivate: false
                        },
                        logic: disabledCondition,
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        type: "textfield",
                        labelPosition: "top",
                        inputFormat: "plain",
                        tags: [],
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                customClass: "res-column"
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "email",
                                label: "E-Mail",
                                key: "email",
                                placeholder: "E-Mail",
                                tooltip:
                                  "Please enter your email address. This must be valid email address as it will be used to send documentation.",
                                prefix: '<i class="fa fa-envelope"></i>',
                                suffix: "",
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                kickbox: {
                                  enabled: false
                                },
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "email",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },

                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "text",
                                label: "Username",
                                key: "username",
                                placeholder: "Username",
                                tooltip: "Please enter your username.",
                                prefix: '<i class="fa fa-phone"></i>',
                                suffix: "",
                                multiple: false,
                                protected: false,
                                unique: false,

                                persistent: true,
                                hidden: false,
                                defaultValue: "",
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "textfield",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },

      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "tel",
                                label: "Phone Number",
                                key: "contact",
                                placeholder: "Phone Number",
                                tooltip: "Please enter your phone number.",
                                prefix: '<i class="fa fa-phone"></i>',
                                suffix: "",
                                multiple: false,
                                protected: false,
                                unique: false,

                                persistent: true,
                                hidden: false,
                                defaultValue: "",
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "number",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        key: "content",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line",
        content: "",
        type: "htmlelement",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "horizontal-line"
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns3",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                label: "Continue",
                tableView: false,
                key: "submit",
                size: "md",
                leftIcon: "",
                rightIcon: "",
                block: false,
                action: "submit",
                disableOnInvalid: true,
                theme: "success",
                type: "button",
                tags: [],
                conditional: {
                  show: false,
                  when: "isEdit",
                  eq: true
                },
                properties: {}
              }
            ],
            width: 6,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Registration",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
};

export default RegisterForm;
