const LoginForm = (props) => {
  const { t } = props;
  return {
    "type": "form",
    "components": [
      {
        "type": "input",
        "persistent": true,
        "unique": false,
        "protected": false,
        "defaultValue": "",
        "suffix": "",
        "prefix": "",
        "placeholder": t("Username"),
        "key": "username",
        "lockKey": true,
        "label": "Username",
        "inputType": "text",
        "tableView": true,
        "input": true,
        "autofocus": false,
        "hidden": false,
        "clearOnHide": true,
        "validate": {
          "required": true
        },
        "kickbox": {
          "enabled": false
        },
        "labelPosition": "top",
        "inputFormat": "plain",
        "tags": [],
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {},
        "hideLabel": true
      },
      {
        "type": "password",
        "persistent": true,
        "protected": true,
        "suffix": "",
        "prefix": "",
        "placeholder": t("Password"),
        "key": "password",
        "lockKey": true,
        "label": "Password",
        "inputType": "password",
        "tableView": false,
        "input": true,
        "autofocus": false,
        "hidden": false,
        "clearOnHide": true,
        "labelPosition": "top",
        "tags": [],
        "validate": {
          "required": true
        },
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {},
        "hideLabel": true
      },
      {
        "clearOnHide": false,
        "label": "Columns",
        "input": false,
        "tableView": false,
        "key": "columns",
        "columns": [
          {
            "components": [
              {
                "type": "button",
                "theme": "primary",
                "disableOnInvalid": true,
                "action": "submit",
                "block": false,
                "rightIcon": "",
                "leftIcon": "",
                "size": "md",
                "key": "submit",
                "tableView": false,
                "label": t("Submit"),
                "input": true,
                "autofocus": false
              }
            ],
            "width": 3,
            "offset": 0,
            "push": 0,
            "pull": 0
          }
          // {
          //   "components": [
          //     {
          //       "autofocus": false,
          //       "input": true,
          //       "inputType": "checkbox",
          //       "tableView": true,
          //       "label": t("Stay signed in"),
          //       "dataGridLabel": false,
          //       "key": "staysignedin",
          //       "defaultValue": false,
          //       "protected": false,
          //       "persistent": true,
          //       "hidden": false,
          //       "name": "",
          //       "value": "",
          //       "clearOnHide": true,
          //       "validate": {
          //         "required": false
          //       },
          //       "type": "checkbox",
          //       "labelPosition": "right",
          //       "tags": [],
          //       "conditional": {
          //         "show": "",
          //         "when": null,
          //         "eq": ""
          //       },
          //       "properties": {},
          //       "hideLabel": false
          //     }
          //   ],
          //   "width": 8,
          //   "offset": 0,
          //   "push": 0,
          //   "pull": 0
          // },
          // {
          //   "components": [
          //     {
          //       "type": "button",
          //       "theme": "primary",
          //       "disableOnInvalid": true,
          //       "action": "submit",
          //       "block": false,
          //       "rightIcon": "",
          //       "leftIcon": "",
          //       "size": "md",
          //       "key": "submit",
          //       "tableView": false,
          //       "label": t("Submit"),
          //       "input": true,
          //       "autofocus": false
          //     }
          //   ],
          //   "width": 3,
          //   "offset": 0,
          //   "push": 0,
          //   "pull": 0
          // }
        ],
        "type": "columns",
        "hideLabel": true,
        "tags": [],
        "conditional": {
          "show": "",
          "when": null,
          "eq": ""
        },
        "properties": {}
      }
    ],
    "title": "User Login",
    "name": "userLogin"
  };
}

export default LoginForm;