import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import CreditReferenceFormnew from "./CreditRefenceFormNew";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { setCredit } from "../../../shared/services/actions/credit-action";
import { bindActionCreators } from "redux";
import { post, get } from "../../../shared/services/rest-service";
import register from "../../../shared/services/register-user-service";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import SubHeader from "../../../user/sub-header/sub-header";
import { ToastsStore } from "react-toasts";
import { LoaderLarge } from "../../loader";

var tradeRefFile = {};
var tradeRefFile2 = {};
var bankRefFile = {};
var personalFile = {};
var newTradeRef = {};
var CompanyCreditFile = {};

class CreditReference extends React.Component {
  companyCreditReportFile = new FormData();
  personalCreditReportFile = new FormData();
  tradeReferenceFile = new FormData();
  bankReferenceFile = new FormData();
  tradeReference2File = new FormData();
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      displayMenu: false,
      displayCol: "col-lg-9",
      bankReference: {},
      tradeReference1: {},
      tradeReference2: {},
      CreditTerm: [],
      CreditRelationship: [],
      hideBank: false,
      hideTrade1: false,
      hideTrade2: false,
      loading: true,

      creditRef: {
        tradeReference: [],
        bankReference: {}
      },
      creditRef1: {
        tradeReference: [],
        bankReference: {}
      }
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      // display menu condition
      if (this.props.location.pathname === "/credit-reference") {
        this.setState({ displayMenu: true });
        this.setState({ displayCol: "col-lg-9" });

        if (this.props.data.loanDetails.termId == 1) {
          if (
            this.props.data.loanDetails.amount >= 10000 &&
            this.props.data.loanDetails.amount <= 300001
          ) {
            //    this.props.data.loanDetails.hideBank = true
            //  this.props.data.loanDetails.hideTrade2 = true
            newTradeRef = this.props.data.tradeReference1;
            //  this.setState({tradeReference1: this.props.data.tradeReference1})
            //  this.setState({tradeReference2: this.props.data.tradeReference1})
            //  this.setState({bankReference: this.props.data.tradeReference1})
          } else if (
            this.props.data.loanDetails.amount >= 1000001 &&
            this.props.data.loanDetails.amount <= 10000000
          ) {
            // this.props.data.loanDetails.isEdit1 = true
            // this.props.data.loanDetails.isEdit = false
            this.setState({ isEdit1: true });
            newTradeRef = this.props.data.tradeReference1;

            // this.props.data.companyDetails.isEdit1 = true
            // this.props.data.companyDetails.isEdit = false
            this.setState({ tradeReference1: this.props.data.tradeReference1 });
            this.setState({ tradeReference2: this.props.data.tradeReference1 });
          } else if (
            this.props.data.loanDetails.amount >= 300001 &&
            this.props.data.loanDetails.amount <= 1000000
          ) {
            newTradeRef = this.props.data.tradeReference1;

            this.setState({
              tradeReference1: this.props.data.tradeReference1,
              hideBank: true
            });
          }
        } else if (this.props.data.loanDetails.termId != 1) {
          newTradeRef = this.props.data.tradeReference1;

          this.setState({ hideTrade1: true, hideTrade2: true });
        }
      } else {
        var tradeReference1 = {};
        debugger;

        this.setState({ displayMenu: false });
        this.setState({ displayCol: "col-lg-12" });
        //  this.props.data.hideTrade11 = this.props.tradeReference1.hideTrade11;
        this.props.creditReferenceDetails[
          "tradeReference1"
        ] = this.props.tradeReference1;
        this.props.data["tradeReference1"] = this.props.tradeReference1;
        //Object.assign(newTradeRef, this.props.tradeReference1);
        newTradeRef = this.props.tradeReference1;
        Object.assign(this.props.data, newTradeRef);

        if (this.props.location.state) {
          const getUserDetail = await get(
            "users/getUserDetails/" + this.props.location.state.user_id,
            {}
          );

          if (this.props.creditReferenceDetails) {
            if (this.props.creditReferenceDetails.creditReferenceDetails) {
              if (
                this.props.creditReferenceDetails.creditReferenceDetails
                  .tradeReference.length !== 0
              ) {
                if (
                  this.props.creditReferenceDetails.creditReferenceDetails
                    .tradeReference.length > 1
                ) {
                  newTradeRef = this.props.data.tradeReference1;

                  newTradeRef = {
                    ...newTradeRef,
                    companyReference: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .companyReference,
                    experience: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0].experience,
                    estimatedAnnualSale: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .estimatedAnnualSale,
                    highestCredit: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0].highestCredit,
                    outStandingBalance: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .outStandingBalance,
                    tradeCreditTermid: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .tradeCreditTermid,
                    companyReference1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1]
                      .companyReference,
                    experience1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1].experience,
                    estimatedAnnualSale1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1]
                      .estimatedAnnualSale,
                    highestCredit1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1].highestCredit,
                    outStandingBalance1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1]
                      .outStandingBalance,
                    tradeCreditTermid1: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[1]
                      .tradeCreditTermid
                  };

                  Object.assign(this.props.data, newTradeRef);
                } else if (
                  this.props.creditReferenceDetails.creditReferenceDetails
                    .tradeReference.length === 1
                ) {
                  debugger;
                  newTradeRef = this.props.data.tradeReference1;

                  newTradeRef = {
                    ...newTradeRef,
                    companyReference: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .companyReference,
                    experience: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0].experience,
                    estimatedAnnualSale: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .estimatedAnnualSale,
                    highestCredit: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0].highestCredit,
                    outStandingBalance: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .outStandingBalance,
                    tradeCreditTermid: this.props.creditReferenceDetails
                      .creditReferenceDetails.tradeReference[0]
                      .tradeCreditTermid
                  };
                  Object.assign(this.props.data, newTradeRef);
                }
              } else if (
                this.props.creditReferenceDetails.creditReferenceDetails
                  .bankReference
              ) {
                newTradeRef = {
                  ...newTradeRef,
                  yearsWithBank: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.yearsWithBank,
                  estimatedAnnualBalance: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference
                    .estimatedAnnualBalance,
                  creditAmount: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditAmount,
                  creditRelationId: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditRelationId,
                  creditFacility: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditFacility,
                  outStandingBalance2: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.outStandingBalance,
                  bankReference: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.bankReference
                };
                Object.assign(this.props.data, newTradeRef);
              }
              if (
                this.props.creditReferenceDetails.creditReferenceDetails
                  .bankReference
              ) {
                newTradeRef = {
                  ...newTradeRef,
                  yearsWithBank: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.yearsWithBank,
                  estimatedAnnualBalance: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference
                    .estimatedAnnualBalance,
                  creditAmount: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditAmount,
                  creditRelationId: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditRelationId,
                  creditFacility: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.creditFacility,
                  outStandingBalance2: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.outStandingBalance,
                  bankReference: this.props.creditReferenceDetails
                    .creditReferenceDetails.bankReference.bankReference
                };
              }
            }
          }

          if (
            getUserDetail.result.role !== "ROLE_USER" &&
            getUserDetail.result.role !== "ROLE_REVIEWER"
          ) {
            newTradeRef = { ...newTradeRef, isEdit: true };
          }
          if (getUserDetail.result.role == "ROLE_USER") {
            if (this.props.status == "APPROVED") {
              newTradeRef = { ...newTradeRef, isEdit: true };
            } else if (this.props.status == "SUBMITTED") {
              newTradeRef = { ...newTradeRef, isEdit: false };
            }
          }
          // this.setState({bankReference: this.props.creditReferenceDetails.creditReferenceDetails.bankReference})
          // this.setState({tradeReference1: this.props.creditReferenceDetails.creditReferenceDetails.tradeReference[0]})
          // this.setState({tradeReference2: this.props.creditReferenceDetails.creditReferenceDetails.tradeReference[1]})
        }
      }

      const getTradeReferenceCreditTerm = await get(
        "taxonomy/TRADE_CREDITTERM",
        {}
      );
      this.setState({ CreditTerm: getTradeReferenceCreditTerm });

      const getCreditRelationship = await get(
        "taxonomy/CREDIT_RELATIONSHIP",
        {}
      );
      this.setState({
        CreditRelationship: getCreditRelationship,
        loading: false
      });
    }
  }
  // yearsValidate = event => {
  //   debugger;
  //   // var a = event.data.panel2ColumnsColumnsColumns2Content;
  //   var a = event.data.yearsWithBank;

  //   if (event.data.yearsWithBank) {
  //     if (a.toString().indexOf(".")) {
  //       event.data.yearsWithBank = event.data.yearsWithBank
  //         .toString()
  //         .replace(".", "");
  //     }
  //   }
  // };
  logit = ({ data }) => {
    debugger;
    this.props.setCredit({
      data: data,
      personalReport: this.personalCreditReportFile,
      companyReport: this.companyCreditReportFile,
      tradeReport: this.tradeReferenceFile,
      tradeReference2File: this.tradeReference2File,
      bankReport: this.bankReferenceFile
    });
    this.props.history.push("/");
  };
  logup = ({ data }) => {
    this.props.setCredit({
      data: data,
      personalReport: this.personalCreditReportFile,
      companyReport: this.companyCreditReportFile,
      tradeReport: this.tradeReferenceFile,
      tradeReference2File: this.tradeReference2File,

      bankReport: this.bankReferenceFile
    });
    this.props.history.push("/");
  };

  handleCustomEvent = event => {
    debugger;
    if (event.type === "back") {
      if (this.props.location.pathname === "/credit-reference") {
        this.props.history.push("/financial-details");
      } else {
        let selectedTab = 4;
        this.props.nextTabs(selectedTab);
      }
    } else if (event.type === "continue") {
      debugger;
      this.state.creditRef.bankReference = JSON.parse(
        JSON.stringify(event.data)
      );
      if (localStorage.getItem("token")) {
        if (this.props.data.loanDetails) {
          this.validateData();
        }
      } else {
        if (this.props.data.loanDetails) {
          this.validateData();
        }
      }
    } else if (event.type === "continuetrade") {
      debugger;

      if (
        event.data.companyReference &&
        event.data.estimatedAnnualSale &&
        event.data.experience &&
        event.data.highestCredit &&
        event.data.outStandingBalance &&
        event.data.tradeCreditTermid !== ""
      ) {
        event.data.tradeNumber = 2;
        this.state.creditRef.tradeReference.push(
          JSON.parse(JSON.stringify(event.data))
        );
        if (localStorage.getItem("token")) {
          if (this.props.data.loanDetails) {
            if (
              this.props.data.loanDetails.termId === 1 &&
              this.props.data.loanDetails.amount >= 300001 &&
              this.props.data.loanDetails.amount <= 1000000
            ) {
              if (this.props.data.creditReferenceDetails) {
                this.validateData();
              }
            }
          }
        } else {
          if (this.props.data.loanDetails) {
            if (
              this.props.data.loanDetails.termId === 1 &&
              this.props.data.loanDetails.amount >= 300001 &&
              this.props.data.loanDetails.amount <= 1000000
            ) {
              this.validateData();
            }
          }
        }
      }
    } else if (event.type === "continuetrade1") {
      debugger;

      if (
        event.data.companyReference &&
        event.data.estimatedAnnualSale &&
        event.data.experience &&
        event.data.highestCredit &&
        event.data.outStandingBalance &&
        event.data.tradeCreditTermid !== ""
      ) {
        event.data.tradeNumber = 1;
        this.state.creditRef.tradeReference.push(
          JSON.parse(JSON.stringify(event.data))
        );
        if (localStorage.getItem("token")) {
          if (this.props.data.loanDetails) {
            if (
              this.props.data.loanDetails.termId === 1 &&
              this.props.data.loanDetails.amount <= 300000
            ) {
              this.validateData();
            }
          }
        } else {
          if (this.props.data.loanDetails) {
            if (
              this.props.data.loanDetails.termId === 1 &&
              this.props.data.loanDetails.amount <= 300000
            ) {
              this.validateData();
            }
          }
        }
      }
    } else if (event.type === "uploadCompanyCreditReport") {
      this.uploadCompanyCreditReport();
    } else if (event.type === "uploadPersonalCreditReport") {
      this.uploadPersonalCreditReport();
    } else if (event.type === "uploadTradeReferenceReport") {
      this.uploadTradeReferenceReport();
    } else if (event.type === "uploadTradeReferenceReport2") {
      this.uploadTradeReferenceReport2();
    } else if (event.type === "uploadBankReferenceReport") {
      this.uploadBankReferenceReport();
    }
  };

  getTrade2data = event => {
    event.data.tradeNumber = 2;
    this.state.creditRef.tradeReference.push(
      JSON.parse(JSON.stringify(event.data))
    );

    if (localStorage.getItem("token")) {
      if (this.props.data.loanDetails) {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount >= 300001 &&
          this.props.data.loanDetails.amount <= 1000000
        ) {
          this.validateData();
        }
      }
    } else {
      if (this.props.data.loanDetails) {
        if (
          this.props.data.loanDetails.termId === 1 &&
          this.props.data.loanDetails.amount >= 300001 &&
          this.props.data.loanDetails.amount <= 1000000
        ) {
          this.validateData();
        }
      }
    }
  };
  validateData = async () => {
    debugger;

    const tempData = this.state.creditRef;
    const dataTovalidate = this.props.data;

    dataTovalidate["creditReferenceDetails"] = tempData;

    if (!localStorage.getItem("token")) {
      try {
        const res = await register(dataTovalidate.personalDetails);
        localStorage.setItem("currentUser", JSON.stringify(res));

        if (res.token) {
          this.saveValidateData(dataTovalidate);
        }
      } catch (error) {
        alert("Some Error");
      }
    } else {
      debugger;
      try {
        this.setState({ loading: true });
        await post("loan/save-validate", dataTovalidate);
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(this.props.data));
          dataToSave.append("isValid", "true");
          dataToSave.append("tradeReferenceFile", tradeRefFile);
          dataToSave.append("tradeReference2File", tradeRefFile2);
          dataToSave.append("bankReferenceFile", bankRefFile);

          dataToSave.append("personalCreditReportFile", personalFile);
          dataToSave.append("tradeCompanyCreditReportFile", CompanyCreditFile);
          dataToSave.append(
            "currentYrCompanyAccFile",
            dataTovalidate.currentYrCompanyAccFile
          );
          dataToSave.append(
            "lastYrCompanyAccFile",
            dataTovalidate.lastYrCompanyAccFile
          );
          dataToSave.append(
            "twoYrAgoCompanyAccFile",
            dataTovalidate.twoYrAgoCompanyAccFile
          );

          const res = await post("loan/save", dataToSave);
          this.setState({ loading: false });

          ToastsStore.success(res);
        } catch (error) {
          alert("cant save data");
        }
        this.props.history.push("/loans");
      } catch (error) {
        console.log(error);
      }
    }
  };

  saveValidateData = async dataTovalidate => {
    try {
      const res = await post("loan/save-validate", dataTovalidate);
      if (res.error == null) {
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));

          dataToSave.append("isValid", "true");
          dataToSave.append("tradeReferenceFile", tradeRefFile);
          dataToSave.append("tradeReference2File", tradeRefFile2);
          dataToSave.append("bankReferenceFile", bankRefFile);

          dataToSave.append("personalCreditReportFile", personalFile);
          dataToSave.append("tradeCompanyCreditReportFile", CompanyCreditFile);
          dataToSave.append(
            "currentYrCompanyAccFile",
            dataTovalidate.currentYrCompanyAccFile
          );
          dataToSave.append(
            "lastYrCompanyAccFile",
            dataTovalidate.lastYrCompanyAccFile
          );
          dataToSave.append(
            "twoYrAgoCompanyAccFile",
            dataTovalidate.twoYrAgoCompanyAccFile
          );

          const res = await post("loan/save", dataToSave);
          ToastsStore.success(res);

          if (localStorage.getItem("isLoggedIn") === "true") {
            this.props.history.push("/loans");
          } else {
            this.props.history.push("/login");
          }
        } catch (error) {}
      }
    } catch (error) {
      console.log(error);
    }
  };
  uploadCompanyCreditReport = () => {
    document.getElementById("companyCreditReport").click();
  };

  submitCreditData = async ({ data }) => {
    debugger;
    var creditRef = {};
    var tradeReference = [2];
    var bankReference = {};
    if (this.props.location.pathname !== "/credit-reference") {
      debugger;
      if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount > 10000 &&
        this.props.data.loanDetails.amount <= 300000
      ) {
        if (
          this.props.creditReferenceDetails.creditReferenceDetails
            .tradeReference.length == 1
        ) {
          tradeReference[0] = {
            companyReference: data.companyReference,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .tradeReference[0].id,
            tradeNumber: 1,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          creditRef = { tradeReference };
        } else {
          tradeReference[0] = {
            companyReference: data.companyReference,
            tradeNumber: 1,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          creditRef = { tradeReference };
        }
      } else if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount >= 300001 &&
        this.props.data.loanDetails.amount <= 1000000
      ) {
        if (
          this.props.creditReferenceDetails.creditReferenceDetails
            .tradeReference.length == 2
        ) {
          tradeReference[0] = {
            companyReference: data.companyReference,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .tradeReference[0].id,
            tradeNumber: 1,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          tradeReference[1] = {
            companyReference: data.companyReference1,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .tradeReference[1].id,
            tradeNumber: 2,
            experience: data.experience1,
            estimatedAnnualSale: data.estimatedAnnualSale1,
            highestCredit: data.highestCredit1,
            outStandingBalance: data.outStandingBalance1,
            tradeCreditTermid: data.tradeCreditTermid1
          };
          creditRef = { tradeReference };
        } else {
          tradeReference[0] = {
            companyReference: data.companyReference,
            tradeNumber: 1,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          tradeReference[1] = {
            companyReference: data.companyReference1,
            tradeNumber: 2,
            experience: data.experience1,
            estimatedAnnualSale: data.estimatedAnnualSale1,
            highestCredit: data.highestCredit1,
            outStandingBalance: data.outStandingBalance1,
            tradeCreditTermid: data.tradeCreditTermid1
          };
          creditRef = { tradeReference };
        }
      } else if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount >= 1000001 &&
        this.props.data.loanDetails.amount <= 10000000
      ) {
        if (
          this.props.creditReferenceDetails.creditReferenceDetails
            .tradeReference.length == 2 &&
          this.props.creditReferenceDetails.creditReferenceDetails.bankReference
            .id
        ) {
          tradeReference[0] = {
            companyReference: data.companyReference,
            tradeNumber: 1,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .tradeReference[0].id,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          tradeReference[1] = {
            companyReference: data.companyReference1,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .tradeReference[1].id,
            tradeNumber: 2,
            experience: data.experience1,
            estimatedAnnualSale: data.estimatedAnnualSale1,
            highestCredit: data.highestCredit1,
            outStandingBalance: data.outStandingBalance1,
            tradeCreditTermid: data.tradeCreditTermid1
          };
          bankReference = {
            yearsWithBank: data.yearsWithBank,
            id: this.props.creditReferenceDetails.creditReferenceDetails
              .bankReference.id,
            estimatedAnnualBalance: data.estimatedAnnualBalance,
            creditAmount: data.creditAmount,
            creditRelationId: data.creditRelationId,
            creditFacility: data.creditFacility,
            outStandingBalance: data.outStandingBalance2,
            bankReference: data.bankReference
          };
          creditRef = { tradeReference, bankReference };
        } else {
          tradeReference[0] = {
            companyReference: data.companyReference,
            tradeNumber: 1,
            experience: data.experience,
            estimatedAnnualSale: data.estimatedAnnualSale,
            highestCredit: data.highestCredit,
            outStandingBalance: data.outStandingBalance,
            tradeCreditTermid: data.tradeCreditTermid
          };
          tradeReference[1] = {
            companyReference: data.companyReference1,
            tradeNumber: 2,
            experience: data.experience1,
            estimatedAnnualSale: data.estimatedAnnualSale1,
            highestCredit: data.highestCredit1,
            outStandingBalance: data.outStandingBalance1,
            tradeCreditTermid: data.tradeCreditTermid1
          };
          bankReference = {
            yearsWithBank: data.yearsWithBank,
            estimatedAnnualBalance: data.estimatedAnnualBalance,
            creditAmount: data.creditAmount,
            creditRelationId: data.creditRelationId,
            creditFacility: data.creditFacility,
            outStandingBalance: data.outStandingBalance2,
            bankReference: data.bankReference
          };
          creditRef = { tradeReference, bankReference };
        }
      } else if (this.props.data.loanDetails.termId != 1) {
        bankReference = {
          yearsWithBank: data.yearsWithBank,
          id: this.props.creditReferenceDetails.creditReferenceDetails
            .bankReference.id,
          estimatedAnnualBalance: data.estimatedAnnualBalance,
          creditAmount: data.creditAmount,
          creditRelationId: data.creditRelationId,
          creditFacility: data.creditFacility,
          outStandingBalance: data.outStandingBalance2,
          bankReference: data.bankReference
        };
        creditRef = { bankReference };
      }
    } else {
      if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount > 10000 &&
        this.props.data.loanDetails.amount <= 300000
      ) {
        tradeReference[0] = {
          companyReference: data.companyReference,
          tradeNumber: 1,
          experience: data.experience,
          estimatedAnnualSale: data.estimatedAnnualSale,
          highestCredit: data.highestCredit,
          outStandingBalance: data.outStandingBalance,
          tradeCreditTermid: data.tradeCreditTermid
        };
        creditRef = { tradeReference };
      } else if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount >= 300001 &&
        this.props.data.loanDetails.amount <= 1000000
      ) {
        tradeReference[0] = {
          companyReference: data.companyReference,
          tradeNumber: 1,
          experience: data.experience,
          estimatedAnnualSale: data.estimatedAnnualSale,
          highestCredit: data.highestCredit,
          outStandingBalance: data.outStandingBalance,
          tradeCreditTermid: data.tradeCreditTermid
        };
        tradeReference[1] = {
          companyReference: data.companyReference1,
          tradeNumber: 2,
          experience: data.experience1,
          estimatedAnnualSale: data.estimatedAnnualSale1,
          highestCredit: data.highestCredit1,
          outStandingBalance: data.outStandingBalance1,
          tradeCreditTermid: data.tradeCreditTermid1
        };
        creditRef = { tradeReference };
      } else if (
        this.props.data.loanDetails.termId === 1 &&
        this.props.data.loanDetails.amount >= 1000001 &&
        this.props.data.loanDetails.amount <= 10000000
      ) {
        tradeReference[0] = {
          companyReference: data.companyReference,
          tradeNumber: 1,
          experience: data.experience,
          estimatedAnnualSale: data.estimatedAnnualSale,
          highestCredit: data.highestCredit,
          outStandingBalance: data.outStandingBalance,
          tradeCreditTermid: data.tradeCreditTermid
        };
        tradeReference[1] = {
          companyReference: data.companyReference1,
          tradeNumber: 2,
          experience: data.experience1,
          estimatedAnnualSale: data.estimatedAnnualSale1,
          highestCredit: data.highestCredit1,
          outStandingBalance: data.outStandingBalance1,
          tradeCreditTermid: data.tradeCreditTermid1
        };
        bankReference = {
          yearsWithBank: data.yearsWithBank,
          estimatedAnnualBalance: data.estimatedAnnualBalance,
          creditAmount: data.creditAmount,
          creditRelationId: data.creditRelationId,
          creditFacility: data.creditFacility,
          outStandingBalance: data.outStandingBalance2,
          bankReference: data.bankReference
        };
        creditRef = { tradeReference, bankReference };
      } else if (this.props.data.loanDetails.termId !== 1) {
        bankReference = {
          yearsWithBank: data.yearsWithBank,
          estimatedAnnualBalance: data.estimatedAnnualBalance,
          creditAmount: data.creditAmount,
          creditRelationId: data.creditRelationId,
          creditFacility: data.creditFacility,
          outStandingBalance: data.outStandingBalance2,
          bankReference: data.bankReference
        };
        creditRef = { bankReference };
      }
    }

    const tempData = creditRef;
    const dataTovalidate = this.props.data;
    dataTovalidate["creditReferenceDetails"] = tempData;

    if (!localStorage.getItem("token")) {
      try {
        const res = await register(dataTovalidate.personalDetails);
        localStorage.setItem("currentUser", JSON.stringify(res));

        if (res.token) {
          this.saveValidateData(dataTovalidate);
        }
      } catch (error) {
        alert("Some Error");
      }
    } else {
      debugger;
      try {
        await post("loan/save-validate", dataTovalidate);
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          dataToSave.append("tradeReferenceFile", tradeRefFile);
          dataToSave.append("tradeReference2File", tradeRefFile2);
          dataToSave.append("bankReferenceFile", bankRefFile);

          dataToSave.append("personalCreditReportFile", personalFile);
          dataToSave.append("tradeCompanyCreditReportFile", CompanyCreditFile);
          dataToSave.append(
            "currentYrCompanyAccFile",
            dataTovalidate.currentYrCompanyAccFile
          );
          dataToSave.append(
            "lastYrCompanyAccFile",
            dataTovalidate.lastYrCompanyAccFile
          );
          dataToSave.append(
            "twoYrAgoCompanyAccFile",
            dataTovalidate.twoYrAgoCompanyAccFile
          );

          const res = await post("loan/save", dataToSave);
          ToastsStore.success(res);
        } catch (error) {
          alert("cant save data");
        }
        this.props.history.push("/loans");
      } catch (error) {
        console.log(error);
      }
    }
  };
  uploadPersonalCreditReport = () => {
    document.getElementById("personalCreditReport").click();
  };
  uploadTradeReferenceReport = () => {
    document.getElementById("tradeReferenceReport").click();
  };
  uploadTradeReferenceReport2 = () => {
    document.getElementById("tradeReferenceReport2").click();
  };
  uploadBankReferenceReport = () => {
    document.getElementById("bankReferenceReport").click();
  };

  uploadPersonalCreditReportFile = event => {
    const file = event.target.files[0];
    this.personalCreditReportFile.append("file", file);
    personalFile = file;
    const fileNameDiv = document.getElementsByClassName("personal-credit")[0];
    fileNameDiv.textContent = file.name;
  };

  uploadCompanyCreditReportFile = event => {
    const file = event.target.files[0];
    this.companyCreditReportFile.append("file", file);
    CompanyCreditFile = file;
    const fileNameDiv = document.getElementsByClassName(
      "companycredit-filename"
    )[0];
    fileNameDiv.textContent = file.name;
  };
  uploadTradeReferenceReportFile = event => {
    debugger;
    const file = event.target.files[0];
    this.tradeReferenceFile.append("file", file);
    tradeRefFile = file;
    const fileNameDiv = document.getElementsByClassName("trade-reference1")[0];
    fileNameDiv.textContent = file.name;
  };

  uploadTradeReferenceReportFile2 = event => {
    debugger;
    const file = event.target.files[0];
    this.tradeReferenceFile.append("file", file);

    this.tradeReference2File.append("file", file);
    tradeRefFile2 = file;

    const fileNameDiv = document.getElementsByClassName("trade-reference2")[0];
    fileNameDiv.textContent = file.name;
  };
  uploadBankReferenceReportFile = event => {
    const file = event.target.files[0];
    // this.bankReferenceFile.append("file", file);
    bankRefFile = file;
    const fileNameDiv = document.getElementsByClassName("bank-reference")[0];
    fileNameDiv.textContent = file.name;
    // dataToSave.append('bankReferenceFile', bankRefFile);
  };

  displayForm() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? (
          <LoaderLarge />
        ) : (
          <div className="credit-form g-pa-10 g-mx-30--xl">
            <Form
              submission={{
                data: newTradeRef ? newTradeRef : ""
              }}
              form={CreditReferenceFormnew(
                this.props,
                this.state.CreditTerm,
                this.state.CreditRelationship,
                this.props.tradeReference1
              )}
              // onChange={event => this.yearsValidate(event)}
              onSubmit={this.submitCreditData}
              onCustomEvent={this.handleCustomEvent}
              options={{
                language: localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "",
                languages: ["en", "spa"],
                i18n: {
                  en: languageEN.en.translation,
                  spa: languageSPA.spa.translation
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
  displayLoader() {
    setTimeout(
      function() {
        //Start the timer
        return <LoaderLarge />; //After 1 second, set render to true
      }.bind(this),
      3000
    );
  }
  render() {
    //window.scrollTo(0, 0);
    const { loading } = this.state;
    const { t } = this.props;

    return (
      <section className="clearfix">
        {this.state.displayMenu ? <SubHeader /> : null}
        <input
          id="companyCreditReport"
          className="hidden-obj"
          type="file"
          onChange={this.uploadCompanyCreditReportFile}
        />
        <input
          id="personalCreditReport"
          className="hidden-obj"
          type="file"
          onChange={this.uploadPersonalCreditReportFile}
        />
        <input
          id="tradeReferenceReport"
          className="hidden-obj"
          type="file"
          onChange={this.uploadTradeReferenceReportFile}
        />
        <input
          id="tradeReferenceReport2"
          className="hidden-obj"
          type="file"
          onChange={this.uploadTradeReferenceReportFile2}
        />
        <input
          id="bankReferenceReport"
          className="hidden-obj"
          type="file"
          onChange={this.uploadBankReferenceReportFile}
        />
        <div className="row no-gutters align-items-center">
          {loading ? (
            this.displayLoader()
          ) : (
            <div className={`mainbox ${this.state.displayCol}`}>
              <div className="mg-lft-100 pad-top-30 headline">
                <h3 className="head-tittle">{t("Credit Reference")}</h3>
              </div>
              {this.displayForm()}
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProops = dispatch => {
  return bindActionCreators({ setCredit: setCredit }, dispatch);
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProops
  )(withRouter(CreditReference))
);
