const CompanyDetailsFormNlogin = (
  props,
  CompanyDetails,
  companyCreditType,
  financialYear
) => {
  let { t } = props;
  let supplierCredit = t("Supplier Credit");
  let supplierCreditDes = t(
    "Supplier is the company supplying goods and/or services and may request credit for its buyers with the required information"
  );
  let buyerCredit = t("Buyer Credit");
  var currentDate = new Date();

  let buyerCreditDes = t(
    "Buyer is for a company that is purchasing goods and/or services which will be receiving that credit and information is required on the buyer"
  );
  let disabledCondition = [
    {
      name: "enableLogic",
      trigger: {
        type: "simple",
        simple: {
          show: true,
          when: "isEdit",
          eq: true
        }
      },
      actions: [
        {
          name: "enableAction",
          type: "property",
          property: {
            label: "disabled",
            value: "disabled",
            type: "string"
          },
          state: "false"
        }
      ]
    }
  ];
  return {
    type: "form",

    components: [
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns",
                columns: [
                  {
                    components: [
                      {
                        autofocus: false,
                        input: true,
                        inputType: "checkbox",
                        tableView: true,
                        label: "",
                        dataGridLabel: false,
                        key: "isEdit",
                        defaultValue: false,
                        protected: false,
                        persistent: true,
                        hidden: true,
                        name: "",
                        value: "",
                        clearOnHide: true,
                        validate: {
                          required: false
                        },
                        type: "checkbox",
                        labelPosition: "right",
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {},
                        hideLabel: false
                      },
                      {
                        key: "columnsColumnsSupplierCredit",
                        label: "Supplier Credit",
                        input: false,
                        tag: "span",
                        attrs: [
                          {
                            value: "",
                            attr: ""
                          }
                        ],
                        className: "nopad",
                        content:
                          "<div>\n<b>" +
                          supplierCredit +
                          ":</b>\n<div>" +
                          supplierCreditDes +
                          "</div>\n</div>",
                        type: "htmlelement",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {},
                        customClass: "subheading"
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              },
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns2",
                columns: [
                  {
                    components: [
                      {
                        key: "columnsColumns2BuyerCredit",
                        label: "Buyer Credit",
                        input: false,
                        tag: "span",
                        attrs: [
                          {
                            value: "",
                            attr: ""
                          }
                        ],
                        className: "nopad",
                        content:
                          "<div>\n<b>" +
                          buyerCredit +
                          ":</b>\n<div>" +
                          buyerCreditDes +
                          "</div>\n</div>",
                        type: "htmlelement",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {},
                        customClass: "subheading"
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                isNew: false,
                customClass: "subheading"
              },
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns3",
                columns: [
                  {
                    components: [
                      {
                        type: "select",
                        label: t("Credit Term"),
                        key: "creditTerm.Transaltion.defaultValue",
                        placeholder: t("Select one"),
                        data: {
                          json: companyCreditType
                        },
                        dataSrc: "json",
                        template: "<span>{{ item.label }}</span>",
                        input: true,
                        logic: disabledCondition
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columnsColumns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "text",
                                inputMask: "",
                                label: t("Company Name"),
                                key: "name",
                                placeholder: t("Company Name"),
                                tooltip:
                                  "Please enter the name of company applying for credit.",
                                prefix: '<i class="fa fa-building"></i>',
                                suffix: "",
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: "",
                                  customPrivate: false
                                },
                                logic: disabledCondition,
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                type: "textfield",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columnsColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "text",
                                inputMask: "",
                                label: t("Authorized Person"),
                                key: "authorizedPerson",
                                placeholder: t("Authorized Person"),
                                tooltip:
                                  "The authorized person must have authority to sign document.",
                                prefix: '<i class="fa fa-user"></i>',
                                suffix: "",
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: "",
                                  customPrivate: false
                                },
                                logic: disabledCondition,
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                type: "textfield",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns2",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns2Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns2ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "email",
                                label: t("Contact Email"),
                                key: "email",
                                placeholder: t("Contact Email"),
                                tooltip:
                                  "Please enter contact email. This must be a valid email as it will b used to verify details provided.",
                                prefix: '<i class="fa fa-envelope"></i>',
                                suffix: "",
                                defaultValue: "",
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                kickbox: {
                                  enabled: false
                                },
                                type: "email",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns2ColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                inputType: "tel",
                                label: t("Contact Phone"),
                                key: "phone",
                                placeholder: t("Contact Phone"),
                                tooltip:
                                  "Please enter your contact phone details.",
                                prefix: '<i class="fa fa-phone"></i>',
                                suffix: "",
                                multiple: false,
                                protected: false,
                                className: "test",
                                unique: false,

                                persistent: true,

                                hidden: false,
                                defaultValue: "",
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "number",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns5",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns5Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns5ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: t("Address"),
                                key: "address",
                                placeholder: t("Address"),
                                tooltip:
                                  "Please enter the address of the company.",
                                prefix: "",
                                suffix: "",
                                rows: 2,
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                persistent: true,
                                hidden: false,
                                wysiwyg: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: ""
                                },
                                logic: disabledCondition,
                                type: "textarea",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns4",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns4Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns4ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: t("Incorporated"),
                                key: "incorporated",
                                placeholder: "",
                                tooltip:
                                  "Date the company was started or incorporated.",
                                format: "MM-dd-yyyy",
                                enableDate: true,
                                enableTime: false,
                                defaultDate: "",
                                datepickerMode: "day",
                                datePicker: {
                                  showWeeks: true,
                                  startingDay: 0,
                                  initDate: "",
                                  minMode: "day",
                                  maxMode: "year",
                                  yearRows: 4,
                                  yearColumns: 5,
                                  minDate: null,
                                  maxDate: currentDate,
                                  datepickerMode: "day"
                                },
                                protected: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true,
                                  custom: ""
                                },
                                logic: disabledCondition,
                                type: "datetime",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  },
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns4ColumnsColumns2",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: t("Financial Year End"),
                                key: "financialYearEndId",
                                placeholder: t("Financial Year End"),
                                data: {
                                  values: financialYear,
                                  json: "",
                                  url: "",
                                  resource: "",
                                  custom: ""
                                },
                                dataSrc: "values",
                                valueProperty: "",
                                defaultValue: "",
                                refreshOn: "",
                                filter: "",
                                authenticate: false,
                                template: "<span>{{ item.label }}</span>",
                                multiple: false,
                                protected: false,
                                unique: false,
                                persistent: true,
                                hidden: false,
                                clearOnHide: true,
                                validate: {
                                  required: true
                                },
                                logic: disabledCondition,
                                type: "select",
                                labelPosition: "top",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns3",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns3Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns3ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                tableView: true,
                                label: t("General Lines Of Business"),
                                key: "lineOfBusiness",
                                placeholder: t("General Lines Of Business"),
                                tooltip:
                                  "Please enter general line of business. The more detail given the better we can assist you.",
                                prefix: "",
                                suffix: "",
                                rows: 2,
                                multiple: false,
                                defaultValue: "",
                                protected: false,
                                persistent: true,
                                hidden: false,
                                wysiwyg: false,
                                clearOnHide: true,
                                spellcheck: true,
                                validate: {
                                  required: true,
                                  minLength: "",
                                  maxLength: "",
                                  pattern: "",
                                  custom: ""
                                },
                                logic: disabledCondition,
                                type: "textarea",
                                labelPosition: "top",
                                inputFormat: "plain",
                                tags: [],
                                conditional: {
                                  show: "",
                                  when: null,
                                  eq: ""
                                },
                                properties: {}
                              }
                            ],
                            width: 12,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        hideLabel: true,
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {}
                      }
                    ],
                    width: 12,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        hideLabel: true
      },
      {
        key: "columns6Columns2Content",
        label: "Content",
        input: false,
        tag: "hr",
        attrs: [
          {
            value: "",
            attr: ""
          }
        ],
        className: "horizontal-line",
        content: "",
        type: "htmlelement",
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {},
        customClass: "horizontal-line",
        hideLabel: true
      },
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns6",
        columns: [
          {
            components: [
              {
                clearOnHide: false,
                label: "Columns",
                input: false,
                tableView: false,
                key: "columns6Columns",
                columns: [
                  {
                    components: [
                      {
                        clearOnHide: false,
                        label: "Columns",
                        input: false,
                        tableView: false,
                        key: "columns6ColumnsColumns",
                        columns: [
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: t("Back"),
                                tableView: false,
                                key: "columns6ColumnsColumnsBack",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "back",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "buttonclass"
                              }
                            ],
                            width: 2,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: " ",
                                tableView: false,
                                key: "",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "event",
                                event: "",
                                disableOnInvalid: false,
                                type: "htmlelement",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {}
                              }
                            ],
                            width: 1,
                            offset: 0,
                            push: 0,
                            pull: 0
                          },
                          {
                            components: [
                              {
                                autofocus: false,
                                input: true,
                                label: t("Continue"),
                                tableView: false,
                                key: "columns6ColumnsColumnsContinue",
                                size: "md",
                                leftIcon: "",
                                rightIcon: "",
                                block: false,
                                action: "submit",
                                disableOnInvalid: false,
                                theme: "success",
                                type: "button",
                                tags: [],
                                conditional: {
                                  show: false,
                                  when: "isEdit",
                                  eq: true
                                },
                                properties: {},
                                customClass: "btn-continue-loan"
                              }
                            ],
                            width: 4,
                            offset: 0,
                            push: 0,
                            pull: 0
                          }
                        ],
                        type: "columns",
                        tags: [],
                        conditional: {
                          show: "",
                          when: null,
                          eq: ""
                        },
                        properties: {},
                        hideLabel: true
                      }
                    ],
                    width: 6,
                    offset: 0,
                    push: 0,
                    pull: 0
                  }
                ],
                type: "columns",
                hideLabel: true,
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 12,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "CompanyDetails",
    display: "form",
    access: [
      {
        roles: [
          "5e5687396740f27a1f7e3edd",
          "5e5687396740f23f247e3ede",
          "5e5687396740f21a517e3edf"
        ],
        type: "read_all"
      }
    ]
  };
};

export default CompanyDetailsFormNlogin;
