const getReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GETLOAN':
      return state;
    case 'GETCOMPANY':
      state["companyDetails"] = action.data;
      return state;
    default:
      return state;
  }
}

export default getReducer;