import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import CompanyDeatilsFormNlogin from "./CompanyDetailsFormNlogin";
import "../../../../.././index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCompany } from "../../../shared/services/actions/company-action";
import { get, post } from "../../../shared/services/rest-service";
import languageEN from "../../../translate/languages/english";
import languageSPA from "../../../translate/languages/spanish";
import { withTranslation } from "react-i18next";
import SubHeader from "../../../user/sub-header/sub-header";
import { LoaderLarge } from "../../loader";

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      companyCreditType: [],
      financialYear: [],
      displayMenu: false,
      displayCol: "col-lg-9",
      loading: false,
      companyDetails: {},
      tempCompanyDetails: {}
    };
  }

  componentDidMount = async () => {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      if (this.props.location.pathname === "/company-details") {
        this.setState({ displayMenu: true });
        this.setState({ displayCol: "col-lg-9" });
        if (this.props.data.companyDetails) {
          this.setState({
            companyDetails: this.props.data.companyDetails
          });
        }
      } else {
        this.setState({ displayMenu: false, loading: true });
        this.setState({ displayCol: "col-lg-12" });
        if (this.props.location.state) {
          const getUserDetail = await get(
            "users/getUserDetails/" + this.props.location.state.user_id,
            {}
          );
          this.setState({
            tempCompanyDetails: this.props.companyDetails
          });
          if (
            getUserDetail.result.role !== "ROLE_USER" &&
            getUserDetail.result.role !== "ROLE_REVIEWER"
          ) {
            this.props.companyDetails.isEdit = true;
            this.setState({ companyDetails: this.props.companyDetails });
          }
          if (getUserDetail.result.role == "ROLE_USER") {
            if (this.props.status == "APPROVED") {
              this.props.companyDetails.isEdit = true;
            } else if (this.props.status == "SUBMITTED") {
              this.props.companyDetails.isEdit = false;
            }
          }
          this.setState({ companyDetails: this.props.companyDetails });
        }
        if (this.props.data.companyDetails) {
          this.setState({
            companyDetails: this.props.data.companyDetails
          });
        }
      }

      const getCompanyCreditType = await get(
        "taxonomy/COMPANY_CREDIT_TYPE",
        {}
      );
      this.setState({ companyCreditType: getCompanyCreditType });

      //financial year taxonomy
      const getFinancialYear = await get("taxonomy/MONTH_VALUE", {});
      this.setState({ financialYear: getFinancialYear, loading: false });
    }
  };

  logit = ({ data }) => {
    debugger;
    if (this.props.location.pathname === "/company-details") {
      const tempData = JSON.parse(JSON.stringify(data));
      const dataTovalidate = this.props.data;
      tempData.creditTermId =
        tempData.creditTerm.Transaltion.defaultValue.value;

      dataTovalidate["companyDetails"] = tempData;
      this.props.history.push("/loan-details");
    } else {
      this.props.setCompany(data);
      this.validateData(data);
      Object.assign(this.props.companyDetails, this.props.data.companyDetails);
      let selectedTab = 3;
      this.props.nextTabs(selectedTab);
    }
  };

  handleCustomEvent = event => {
    if (event.type === "back") {
      if (this.props.location.pathname === "/company-details") {
        localStorage.setItem("isBack", "yes");
        this.props.history.push("/");
      } else {
        localStorage.setItem("isBack", "yes");

        let selectedTab = 1;
        this.props.nextTabs(selectedTab);
      }
    } else if (event.type === "saveForLater") {
      this.validateData(event.data);
      this.props.history.push("/loan-details");
    }
  };

  validateData = async data => {
    debugger;
    const tempData = JSON.parse(JSON.stringify(data));
    const dataTovalidate = this.props.data;
    tempData.creditTermId = tempData.creditTerm.Transaltion.defaultValue.value;
    dataTovalidate["companyDetails"] = tempData;
  };
  // test = event => {
  //   debugger;
  //   var a = event.data.phone;
  //   if (event.data.phone) {
  //     if (a.toString().indexOf(".")) {
  //       event.data.phone = event.data.phone.toString().replace(".", "");
  //     }
  //   }

  // };
  saveValidateData = async dataTovalidate => {
    try {
      const res = await post("loan/save-validate", dataTovalidate);
      if (res.error === null) {
        try {
          const dataToSave = new FormData();
          dataToSave.append("data", JSON.stringify(dataTovalidate));
          dataToSave.append("isValid", "true");
          await post("loan/save", dataToSave);
        } catch (error) {
          alert("cant save data");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <section className="clearfix">
        {this.state.displayMenu ? <SubHeader /> : null}
        <div className="row no-gutters align-items-center">
          <div className={`mainbox ${this.state.displayCol}`}>
            <div className="mg-lft-100 pad-top-20 headline">
              <h3 className="head-tittle">{t("Buyer Company")}</h3>
            </div>
            {loading ? (
              <LoaderLarge />
            ) : (
              <div className="formmain g-pa-10 g-mx-30--xl">
                {this.props.location.pathname == "/company-details" ? (
                  <Form
                    submission={{
                      data: this.props.data.companyDetails
                        ? this.props.data.companyDetails
                        : ""
                    }}
                    form={CompanyDeatilsFormNlogin(
                      this.props,
                      this.state.companyDetails,
                      this.state.companyCreditType,
                      this.state.financialYear
                    )}
                    onSubmit={this.logit}
                    onCustomEvent={this.handleCustomEvent}
                    options={{
                      language: localStorage.getItem("language")
                        ? localStorage.getItem("language")
                        : "",
                      languages: ["en", "spa"],
                      i18n: {
                        en: languageEN.en.translation,
                        spa: languageSPA.spa.translation
                      }
                    }}
                  />
                ) : (
                  <Form
                    submission={{
                      data: this.props.companyDetails
                        ? this.props.companyDetails
                        : ""
                    }}
                    form={CompanyDeatilsFormNlogin(
                      this.props,
                      this.state.companyDetails,
                      this.state.companyCreditType,
                      this.state.financialYear
                    )}
                    onSubmit={this.logit}
                    onCustomEvent={this.handleCustomEvent}
                    options={{
                      language: localStorage.getItem("language")
                        ? localStorage.getItem("language")
                        : "",
                      languages: ["en", "spa"],
                      i18n: {
                        en: languageEN.en.translation,
                        spa: languageSPA.spa.translation
                      }
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.set.data,
    loggedIn: state.set.loggedIn
  };
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators({ setCompany: setCompany }, dispatch);
};

export default withTranslation()(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(withRouter(CompanyDetails))
);
