import React from 'react';
import './welcome.scss';
import { welcomeUser } from '../services/UserService';

class Welcome extends React.Component {
    constructor(prop) {
        super(prop);
        this.state = { message: '' };
        this.getWelcomeMessage();
    }

    getWelcomeMessage = async () => {
        const { message } = await welcomeUser();
        this.setState({ message: message });
    }

    render() {
        return (
            <div className="welcome">
                <h1>{this.state.message}</h1>
            </div>
        );
    }

}

export default Welcome;
