import React from "react";
import efmHeaderFooterLogo from "../../../../assets/images/efmfooter.png";
import { useTranslation } from "react-i18next";
import "./Footer.scss";
import { companyData } from "../../../../../src/assets/brandJson";

var logo = efmHeaderFooterLogo;

class Footer extends React.PureComponent {
  constructor(props) {
    debugger;
    super(props);
    let companyId;

    if (window.location.search) {
      companyId = window.location.search.slice(1).split("=")[1];
    }
    localStorage.setItem("CompanyId", companyId);
    for (var i = 0; i < companyData.length; i++) {
      if (companyData[i].id == companyId) {
        logo = companyData[i].src;
      }
    }
  }

  render() {
    // const { t } = this.props;

    return (
      <div className="footer-section">
        <footer className="g-pt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <a
                  className="d-inline-block mb-4"
                  href="unify-main/home/home-page-1.html"
                >
                  <img
                    // className="header-img"
                    style={{ height: "80px" }}
                    src={logo}
                    alt="footer log"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                2016 &copy; All Rights Reserved &nbsp;
                <span> Privacy Policy| Terms of Service</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
