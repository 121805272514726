import { uptime } from "os";

function getFilterFields(props, userRole) {
  const { t } = props;
  return {
    type: "form",
    tags: [],
    owner: "5e5687252ea8e6f750d0f9f9",
    components: [
      {
        clearOnHide: false,
        label: "Columns",
        input: false,
        tableView: false,
        key: "columns",
        columns: [
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                inputType: "text",
                inputMask: "",
                label: t("Username"),
                key: "uName",
                placeholder: "",
                prefix: "",
                suffix: "",
                multiple: false,
                defaultValue: "",
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                spellcheck: true,
                validate: {
                  required: false,
                  minLength: "",
                  maxLength: "",
                  pattern: "",
                  custom: "",
                  customPrivate: false
                },
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                type: "textfield",
                labelPosition: "top",
                inputFormat: "plain",
                tags: [],
                properties: {},
                lockKey: true
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: t("Status"),
                key: "status",
                placeholder: "",
                data: {
                  values: [
                    {
                      value: "",
                      label: "Select"
                    },
                    {
                      value: "1",
                      label: "Enabled"
                    },
                    {
                      value: "0",
                      label: "Disabled"
                    }
                  ],
                  json: "",
                  url: "",
                  resource: "",
                  custom: ""
                },
                dataSrc: "values",
                valueProperty: "",
                defaultValue: "",
                refreshOn: "",
                filter: "",
                authenticate: false,
                template: "<span>{{ item.label }}</span>",
                multiple: false,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                validate: {
                  required: false
                },
                type: "select",
                labelPosition: "top",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: t("Role"),
                key: "role",
                placeholder: "",
                data: {
                  values: userRole,
                  json: "",
                  url: "",
                  resource: "",
                  custom: ""
                },
                dataSrc: "values",
                valueProperty: "",
                defaultValue: "",
                refreshOn: "",
                filter: "",
                authenticate: false,
                template: "<span>{{ item.label }}</span>",
                multiple: false,
                protected: false,
                unique: false,
                persistent: true,
                hidden: false,
                clearOnHide: true,
                validate: {
                  required: false
                },
                type: "select",
                labelPosition: "top",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {}
              }
            ],
            width: 3,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                label: t("Filter"),
                tableView: false,
                key: "columnsFilter",
                size: "md",
                leftIcon: "",
                rightIcon: "",
                block: false,
                action: "event",
                event: "filter",
                disableOnInvalid: false,
                theme: "primary",
                type: "button",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                customClass: "mrg-top-30"
              }
            ],
            width: 1,
            offset: 0,
            push: 0,
            pull: 0
          },
          {
            components: [
              {
                autofocus: false,
                input: true,
                label: t("Create User"),
                tableView: false,
                key: "columnsFilter",
                size: "md",
                leftIcon: "",
                rightIcon: "",
                block: false,
                action: "event",
                event: "createUser",
                disableOnInvalid: false,
                theme: "primary",
                type: "button",
                tags: [],
                conditional: {
                  show: "",
                  when: null,
                  eq: ""
                },
                properties: {},
                customClass: "mrg-top-30"
              }
            ],
            width: 1,
            offset: 0,
            push: 0,
            pull: 0
          }
        ],
        type: "columns",
        hideLabel: true,
        tags: [],
        conditional: {
          show: "",
          when: null,
          eq: ""
        },
        properties: {}
      }
    ],
    controller: "",
    revisions: "",
    _vid: 0,
    title: "Loan",
    display: "form",
    access: []
  };
}

export default getFilterFields;
