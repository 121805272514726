import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { getLoans } from "../../../services/LoanServices";
import { getLoanStatus } from "../../../services/LoanServices";

import getLoanFilterFields from "./loan-filter-form";
import { getDelete, post } from "../../../../shared/services/rest-service";
import { connect } from "react-redux";
import { createPortal } from "react-dom";

import "./dialogue.scss";
import { LoaderLarge } from "../../../loader";

import { bindActionCreators } from "redux";
import { setChangeStatus } from "../../../../shared/services/actions/loan-action";
import { confirmAlert } from "react-confirm-alert";
import { Form1 } from "./changeReferrerDialouge";
import { ToastsStore } from "react-toasts";
class LoanList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      count: 0,
      pageNum: 0,
      pageSize: 0,
      filters: { data: {} },
      show: true,
      showRef: false,
      oldRef: "",
      input: "",
      showDialog: false,
      changeStatus: "select",
      changeStatusMenu: [
        { label: "Delete", value: "isDelete" },
        { label: "Archieve", value: "isArchieve" }
      ],
      userRole: "",
      currentUserID: "",
      loading: true,
      showDialog: false,
      loanStatus: {},
      selected: null
    };
  }

  async componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.props.history.push("/login");
    } else {
      localStorage.setItem("isBack", "no");
      await this.getLoanList();
      await this.loanStatus();
    }
  }
  loanStatus = async () => {
    const loanStatus = await getLoanStatus();
    this.setState({
      loanStatus: loanStatus
    });
  };
  getLoanList = async () => {
    debugger;
    const loanList = await getLoans();
    if (loanList) {
      this.setState({
        loading: false
      });
    }
    let currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ userRole: currentUserDetails.roleName });
    this.setState({ currentUserID: currentUserDetails.id });
    if (
      this.state.userRole == "ROLE_USER" ||
      this.state.userRole == "ROLE_APPROVER" ||
      this.state.userRole == "ROLE_REVIEWER"
    ) {
      this.setState({ show: false, showRef: true });
    } else this.setState({ show: true, showRef: true });

    this.setState({
      rowData: loanList.rows,
      count: loanList.count,
      pageNum: 0,
      pageSize: 10,
      filters: {
        data: {
          id: "",
          borrower: "",
          amount: "",
          companyName: "",
          product: "",
          referrer: "",
          approvalStatus: ""
        }
      }
    });
  };

  handleCustomEvent = event => {
    if (event.type === "filter") {
      this.filter(event);
    } else if (event.type === "createLoan") {
      window.open("/", "_self");
      //this.props.history.push('/');
    }
  };
  filter = async ({ data }) => {
    const id = data.id;
    const borrower = data.borrower;
    const amount = data.amount;
    const companyName = data.companyName;
    const product = data.product;
    const referrer = data.referrer;
    const approvalStatus = data.approvalStatus;
    const loanList = await getLoans(
      this.state.pageNum,
      this.state.pageSize,
      id,
      borrower,
      amount,
      companyName,
      product,
      referrer,
      approvalStatus
    );
    setTimeout(() => {
      this.setState({
        rowData: loanList.rows,
        count: loanList.count,
        pageNum: this.state.pageNum,
        pageSize: this.state.pageSize,
        filters: {
          data: {
            id: id,
            borrower: borrower,
            amount: amount,
            companyName: companyName,
            product: product,
            referrer: referrer,
            approvalStatus: approvalStatus
          }
        }
      });
    }, 100);
  };

  pageChanged = async pageNum => {
    const loanList = await getLoans(pageNum);
    this.setState({
      rowData: loanList.rows,
      count: loanList.count,
      pageNum: pageNum,
      pageSize: this.state.pageSize
    });
  };

  pageSizeChanged = async (pageSize, pageIndex) => {
    const loanList = await getLoans(pageIndex, pageSize);
    this.setState({
      rowData: loanList.rows,
      count: loanList.count,
      pageNum: pageIndex,
      pageSize: pageSize
    });
  };

  changeStatus = evt => {
    let data = {
      status: evt.target.value
    };
    this.props.setChangeStatus(data);
  };

  updateStatus = async id => {
    const status = this.props.changeStatus.changeStatus.status;
    if (status === "isDelete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure to Delete this User.",

        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await getDelete(`loan/deleteOrArchieve/${id}?${status}=true`, {});
              window.open("/loans", "_self");
            }
          },
          {
            label: "No"
          }
        ]
      });
    } else if (status === "isArchieve") {
      confirmAlert({
        title: "Confirm to Archieve",
        message: "Are you sure to Archieve this Loan.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await getDelete(`loan/deleteOrArchieve/${id}?${status}=true`, {});
              window.open("/loans", "_self");
            }
          },
          {
            label: "No"
          }
        ]
      });
    }
  };

  editLoanRow = id => {
    this.props.history.push({
      pathname: `/loan-summary/${id}`,
      state: {
        loan_id: id,
        user_id: this.state.currentUserID
      }
    });
  };
  async updateReferrer(event) {
    event.preventDefault();
    var inputVal = document.getElementById("newRef").value;
    const dataTovalidate = { referrer: inputVal };
    const res = await post(
      "loan/updateReferrerCode/" + this.state.refId,
      dataTovalidate
    );
    if (res.msg) {
      ToastsStore.success(res.msg);
    } else {
      ToastsStore.error("Something went wrong");
    }
    setTimeout(function() {
      window.open("/loans", "_self");
    }, 1000);
  }

  openChangeRefDialogue = value => {
    debugger;
    if (this.state.userRole == "ROLE_ADMIN") {
      this.setState({
        showDialog: true,
        input: "",
        refId: value.original.id,
        oldRef: value.original.referrer
      });
    }
  };
  render() {
    const { loading } = this.state;
    const { showDialog, oldRef } = this.state;
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        width: 100,
        maxWidth: 100,
        minWidth: 100,
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Borrower",
        accessor: "borrower",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Loan amount",
        accessor: "amount",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Company name",
        accessor: "companyName",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Product",
        accessor: "product",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Terms",
        accessor: "terms",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Type",
        accessor: "type",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Referrer",
        accessor: "referrer",
        // Cell: row => (
        //   <a onClick={() => this.openChangeRefDialogue(row)}>{row.value}</a>
        // ),
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Add/Edit Referrer",
        accessor: "referrer",
        show: this.state.userRole == "ROLE_ADMIN",
        Cell: row => (
          <div>
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={() => this.openChangeRefDialogue(row)}
            >
              <i className="fa fa-edit"></i>
            </button>
          </div>
        ),
        // Cell: props => {
        //   return (
        //     <div>
        //       <button
        //         type="button"
        //         className="btn btn-outline-success"
        //         onClick={() => {
        //           this.editLoanRow(props.original.id);
        //         }}
        //       >
        //         <i className="fa fa-edit"></i>
        //       </button>
        //     </div>
        //   );
        // },
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "Status",
        accessor: "approvalStatus",
        style: {
          whiteSpace: "unset"
        }
      },
      {
        Header: "View loan",
        sortable: false,
        Cell: props => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() => {
                  this.editLoanRow(props.original.id);
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
          );
        }
      },
      {
        Header: "Change status",
        sortable: false,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
        style: {
          textAlign: "start"
        },
        show: this.state.show,
        Cell: props => {
          return (
            <div id={props.original.id}>
              <select
                className="btn btn-outline-success"
                onChange={e => this.changeStatus(e)}
                defaultValue={this.state.changeStatus}
              >
                <option value="select" disabled hidden>
                  Choose option
                </option>
                {this.state.changeStatusMenu.map(function(menu, i) {
                  return (
                    <option key={i} value={menu.value}>
                      {menu.label}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        }
      },
      {
        Header: "Status update",
        sortable: false,
        width: 120,
        maxWidth: 120,
        minWidth: 120,
        style: {
          textAlign: "start"
        },
        show: this.state.show,
        Cell: props => {
          let id = props.original.id;
          return (
            <div>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() => {
                  this.updateStatus(id);
                }}
              >
                Update
              </button>
            </div>
          );
        }
      }
    ];
    return (
      <div className="mt-5">
        {loading ? (
          <LoaderLarge />
        ) : (
          <div>
            <div className="container-fluid filter-form">
              <div className="form-title">
                <h3 className="head-tittle pad-top-30">Loan list</h3>

                <div className="filter-name filter-loan">
                  <Form
                    form={getLoanFilterFields(
                      this.state.userRole,
                      this.state.loanStatus
                    )}
                    submission={this.state.filters}
                    onCustomEvent={this.handleCustomEvent}
                  />
                </div>
              </div>
            </div>
            <div
              className="container-fluid mt-3"
              style={{ marginTop: 300, paddingBottom: 100 }}
            >
              {this.state.rowData.length > 0 ? (
                <ReactTable
                  columns={columns}
                  manual
                  //defaultPageSize={10}
                  page={this.state.pageNum}
                  data={this.state.rowData}
                  pageSize={this.state.pageSize}
                  minRows={0}
                  //pageSize={5}
                  //   getTrProps={onRowClick}
                  pages={Math.ceil(this.state.count / this.state.pageSize)}
                  onPageChange={this.pageChanged}
                  onPageSizeChange={this.pageSizeChanged}
                  //noDataText={"No Records Found..."}
                />
              ) : (
                <ReactTable
                  manual
                  columns={columns}
                  page={this.state.pageNum}
                  data={this.state.rowData}
                  pageSize={3}
                  pages={Math.ceil(this.state.count / this.state.pageSize)}
                  onPageChange={this.pageChanged}
                  onPageSizeChange={this.pageSizeChanged}
                  noDataText={"No Records Found..."}
                />
              )}
            </div>
          </div>
        )}
        {showDialog === true ? (
          <DialogModal onFocus={this._onFocus}>
            <div>
              <form className="form-container">
                <h3>Update Referrer</h3>
                <i
                  onClick={e => this.setState({ showDialog: false })}
                  className="fa fa-close btn1-close"
                />
                {oldRef ? (
                  <div>
                    <label>
                      <b>Old Referrer</b>
                    </label>
                    <input type="text" value={oldRef} disabled />

                    <label>
                      <b>New Referrer</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Referrer"
                      id="newRef"
                      required
                    />
                  </div>
                ) : (
                  <div>
                    <label>
                      <b>New Referrer</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Referrer"
                      id="newRef"
                      required
                    />
                  </div>
                )}
                <button
                  onClick={event => this.updateReferrer(event)}
                  className="btn"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn cancel"
                  onClick={e => this.setState({ showDialog: false })}
                >
                  Close
                </button>
              </form>
            </div>
          </DialogModal>
        ) : null}
      </div>
    );
  }
}
class DialogModal extends React.Component {
  constructor() {
    super();
    this.body = document.getElementsByTagName("body")[0];
    this.el = document.createElement("div");
    this.el.id = "dialog-root";
  }

  componentDidMount() {
    this.body.appendChild(this.el);
    // this.props.onFocus();
  }

  componentWillUnmount() {
    this.body.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
const mapStateToProps = state => {
  return {
    changeStatus: state.set.data,
    userRole: state.set.data
  };
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators({ setChangeStatus: setChangeStatus }, dispatch);
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(LoanList));
